import React, { useState, useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { StudentClassApi } from "../../services";
import { useReactToPrint } from "react-to-print";
import { injectModels } from "../../redux/injectModels";
import { getCount } from "../../redux/models/NotificationApi/action";
import { JWT } from "../../helpers";
import ReactHtmlParser from "react-html-parser";
import { Button } from "reactstrap";
// import Pdf from "react-to-pdf";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';


import { pdfFromReact } from "generate-pdf-from-react-html";

const DownLoadReport = (props) => {  
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  const history = useHistory();
  const SubjectData = props.location.subject;
  const topicData = props.location.topic;

  // console.log("topicData", topicData);
  const maxAttempts = props.location.maxAttempts;
  const [showtdsArray, setShowtdsArray] = useState("");
  const [maxAttemptsArray, setMaxAttemptsArray] = useState("");
  const location = useLocation();
  const accessToken = JWT.getJWT();
  let urlID = window.location.hash;
  const [error, setError] = useState("");
  const [studentClassName, setStudentClassName] = useState("");
  const [loaddata, setLoadData] = useState("");

  const ref = React.createRef();
  let thisThing = "la";
  let n = props.auth.firstName;
  const options = {
    // default is `save`
    filename: n+"lpi_report.pdf",
    method: 'save',
    // default is Resolution.MEDIUM = 3, which should be enough, higher values
    // increases the image quality but also the size of the PDF, so be careful
    // using values higher than 10 when having multiple pages generated, it
    // might cause the page to crash or hang.
    resolution: Resolution.HIGH,
    page: {
       // margin is in MM, default is Margin.NONE = 0
       //margin: Margin.SMALL,
       margin: 0,
       // default is 'A4'
       format: 'letter',
       // default is 'portrait'
       orientation: 'landscape',
    },
 };

 const getTargetElement = () => document.getElementById('content-id');


  useEffect(() => {
    StudentClassApi.getAllClass()
      .then((response) => {
        const data = response.data;
        if (data.success) {
          const title = data.data;
          setStudentClassName(title[0].title);
        } else {
          setError(data.message);
        }
      })
      .catch((err) => {
        setError("Network error");
      });

    let returnHtml = "";
    let maxAttemptsArray = [];
    let topicDataToPrint = [];  
    // for (let i = 0; i < topicData; i++) {
    //   //returnHtml += "<th> Attempt " + (i + 1) + "</th>";
    //   maxAttemptsArray.push(i);
    // }

  let i = 0;
  if(topicData){
    
    for(let a of topicData){

      let bestScore = null;
      let worstScroe = null;
      let first = 0;
      let firstScore = null;

      if(a.attemptsRowData  && a.attemptsRowData.length > 0){

        for(let abc of a.attemptsRowData){          
          
          if(first == 0){            
            bestScore = abc.parsentage;
            firstScore = abc.parsentage;
            worstScroe = abc.parsentage;
            first = 1;
          }
          
          bestScore = (abc.parsentage > bestScore) ? abc.parsentage : bestScore;
          worstScroe = (abc.parsentage < worstScroe) ? abc.parsentage : worstScroe;          
        }        
      }
      
      topicDataToPrint[i] = {
        "topicTitle" : a.topicTitle,
        "firstScore" : (firstScore || firstScore == 0) ? firstScore.toFixed("2") : null ,
        "lastScore" : (a.lastIndexData && a.lastIndexData[0] ) ? a.lastIndexData[0].parsentage.toFixed("2") : null ,
        "bestScore" : (bestScore || bestScore == 0) ? bestScore.toFixed("2") : null , 
        "worstScore" : (worstScroe || worstScroe == 0) ? worstScroe.toFixed("2") : null ,
        "avarage" : (a.averageScore || a.averageScore == 0) ? a.averageScore.toFixed("2") : "Not Attempted"
      }
     

      i = i +1;
    } 
  }
    // setShowtdsArray(returnHtml);
    setMaxAttemptsArray(topicDataToPrint);
  }, []);
  const handelBack = (e) => {
    history.goBack();
  };

  return (
    <>
    <div className="pdf-row" style={{width:'790px', margin:'0 auto'}}>
        <div className="table-responsive">
      <div className="App">
        <div></div>
        <div className="element-to-print" id="content-id" ref={ref}>
          <div className="page">
            <div className="pageheader" >             
            </div>
          </div>
          <div className="patientClientDetails">
            <table>
              <tbody>
                <tr className="lpi-remove-padding">
                  <td align="center">
                    <table class="download-table-bg" bgcolor="#006c4c">
                      <tbody>
                        <tr>
                          <th class="lpi-download-logo">
                            {/* <img
                              src="/assets/img/lpi-logo.svg"
                              alt
                              class="img-fluid"
                            /> */}
                          </th>
                          <th class="lpt-tution-heading">{(props.auth.firstName)}, {SubjectData} Report</th>
                          <th class="lpi-download-logo"></th>
                        </tr>
                      </tbody>
                    </table>
                    <table class="download-table-bg">
                      <tbody>
                        <tr align="center">
                          <th class="lpi-download-report">{date}</th>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
                <tr>
                  <td class="studen-name-main-data" align="center" style={{padding:"0px"}}>
                    <table class="second-main-table">
                     
                    </table>
                  </td>
                </tr>

                <tr class="lpi-remove-padding">
                  <td align="center">
                    {maxAttemptsArray ? (
                      <table className="table mb-0">
                        <thead>
                          <tr>
                            <th scope="col y-label">Topic</th>
                            <th scope="col pup-labal">First Attempt</th>
                            <th scope="col pup-labal">Last Attempt</th>
                            <th scope="col pup-labal">Worst Score</th>
                            <th scope="col pup-labal">Best Score</th>
                            <th scope="col pup-labal">Average</th>
                          </tr>
                        </thead>
                        <tbody>
                          {maxAttemptsArray
                            ? maxAttemptsArray.map((value, index) => (
                                <tr>
                                  <th >{value.topicTitle}</th>

                                  <td  style={
                                          value.firstScore <= 50
                                            ? {
                                                color: "red",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : value.firstScore >= 50.01 &&
                                              value.firstScore <= 69
                                            ? {
                                                color: "orange",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : {
                                                color: "green",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                        }>{(!value.firstScore) ? "" : value.firstScore + "%"}</td>
                                  <td  style={
                                          value.lastScore <= 50
                                            ? {
                                                color: "red",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : value.lastScore >= 50.01 &&
                                              value.lastScore <= 69
                                            ? {
                                                color: "orange",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : {
                                                color: "green",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                        }>{(!value.lastScore)? "":
                                          value.lastScore + "%"
                                          }</td>

                                  <td  style={
                                          value.worstScore <= 50
                                            ? {
                                                color: "red",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : value.worstScore >= 50.01 &&
                                              value.worstScore <= 69
                                            ? {
                                                color: "orange",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : {
                                                color: "green",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                        }>{(!value.worstScore)? "" : value.worstScore + "%"}</td>
                                  <td  style={
                                          value.bestScore <= 50
                                            ? {
                                                color: "red",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : value.bestScore >= 50.01 &&
                                              value.bestScore <= 69
                                            ? {
                                                color: "orange",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : {
                                                color: "green",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                        }>{(!value.bestScore) ? "":value.bestScore + "%"}</td>

                                  <td  style={
                                          value.avarage <= 50
                                            ? {
                                                color: "red",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                            : 
                                            value.avarage >= 50.01 &&
                                              value.avarage <= 69
                                            ? {
                                                color: "orange",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                              :                                            
                                            {
                                                color: "green",
                                                fontWeight: "600",
                                                cursor: "pointer",
                                              }
                                        }>{ Number(value.avarage) ? value.avarage +" %" : value.avarage  }</td>                                
                                </tr>
                              ))
                            : ""}
                        </tbody>
                      </table>
                    ) : (
                      ""
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="TestDetails">       
          </div>
        </div>
        <div className='container'>
          <div className="float-right-report">
          <button style={{ borderRradius: "25px"}} className="btn btn-success" onClick={() => generatePDF(getTargetElement, options)}>Generate PDF</button>
          </div>          
          </div>        
         <div className="col-md-12 text-center mt-3 mt-xl-4 pt-3 pt-xl-4 mb-2">
          <button className="bck-btn px-2" type="button" onClick={handelBack}>
            <i className="fas fa-long-arrow-alt-left"></i>
            <span> Back </span>
          </button>
        </div>
      </div>
        </div>
      </div>     
    </>
  );
};
export default injectModels(["auth", "userApi"])(DownLoadReport);
