import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { JWT } from '../helpers';

const getAllClass = async(data) => {
	try {
		const response = await axios.get(API_ENDPOINT+`/class`,{headers: {"Content-Type": "application/json"}});
		if(response.status === 200){
            return response;
			
        }
       else {
        return Promise.reject("An api error was encountered");
       }    
    } catch (err) {
	}
}

const StudentClassApi = {
    getAllClass ,
}

export {StudentClassApi};