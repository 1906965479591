import { GET_USERS, GET_CHAT, SEND_CHAT,SAVE_IDS,SEND_CHAT_WITH_FILE, GET_CHAT_COUNTER} from "./constants";

const intialState = {
   users:null,
   chat:null,
   chatCounter: null,
   isShow:false,
   isSend:false,
   ids:[],
   sendMsg:null,
   sendMsgWithFile:null
}

export default (state=intialState, action) => {
    switch(action.type){
        case GET_USERS :
            return {
                ...state,
                users:action.payload
            }

        case GET_CHAT :
            return {
                ...state,
                chat:action.payload,
                isShow:true,
            }

            case GET_CHAT_COUNTER :
                return {
                    ...state,
                    chatCounter: action.payload,
                    isShow:true,
                }

        case SEND_CHAT :
            return{
                ...state,
                isSend:true,
                sendMsg:action.payload,
            }

            case SEND_CHAT_WITH_FILE :
                return{
                    ...state,
                    isSend:true,
                    sendMsgWithFile:action.payload,
                }    

        case SAVE_IDS :
            return{
                ...state,
                ids:action.payload,
            }
            
        default :
            return state;    
    }
}