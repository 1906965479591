import { store } from '../../Store';
import {getCount} from './action';
import {getNotification} from './action';
import {updateStatus} from './action';

const actions = {
    getCount: () => store.dispatch(getCount()),
    getNotification: () => store.dispatch(getNotification()),
    updateStatus:(params = {}) => store.dispatch(updateStatus(params)),
}

export default actions;