import React, { useState, useEffect } from "react";
import { Routes, WIRIS_PATH } from "../../constants";
import { Link, useHistory } from "react-router-dom";
import { Header, FooterInner } from "../includes";
import ReactHtmlParser from 'react-html-parser';
import { injectModels } from '../../redux/injectModels';
import ScrollArea from "react-scrollbar";

const Lesson = (props) => {
    const [topicDescription, setTopicDescription] = useState("");
    const [dynamicColor, setDynamicColor] = useState("");
    const color = props.color;
    const [chapterId, setChapterId] = useState("");
    const [topicId, setTopicId] = useState("");
    const [indexValue, setIndexValue] = useState(0);
    const [textLength, setTextLength] = useState(0);
    const [subTopicLength, setSubTopicLength] = useState(0);
    const [content, setContent] = useState("");
    const [subTopic, setSubTopic] = useState([]);
    /*const [subTopicIndex, setSubTopicIndex] = useState(0);
    const [subTopicIndexApple, setSubTopicIndexApple] = useState(0);*/
    const [subTopicIndex, setSubTopicIndex] = useState(null);
    const [subTopicIndexApple, setSubTopicIndexApple] = useState(null);

    const [subTopicName, setSubTopicName] = useState('');

    const loadScript = () => {
        const script = document.createElement("script");
        script.src = WIRIS_PATH;
        script.async = true;
        document.body.appendChild(script);
    }

    const history = useHistory();

    useEffect(() => {
        const data1 = {
            chapterId: props.chapterId,
            topicId: props.topicID,
        };
        props.topicApi.getSingleTopic(data1);

    }, []);

    useEffect(() => {
        setDynamicColor(props.color);
        document.getElementById("contentbtn").style.display = "none";
        if (!props.topicApi.singleItem) {
            const data1 = {
                chapterId: props.chapterId,
                topicId: props.topicID,
            };
            props.topicApi.getSingleTopic(data1);
        } else {
            setTopicDescription(props.topicApi.singleItem.data)
            setTextLength(props.topicApi.singleItem.data.topicText.length);
            setSubTopicLength(props.topicApi.singleItem.data.topicTitleIndexs.length);
        }
        //document.getElementById("div"+indexValue).style.display = "block";
    }, [props.topicApi.singleItem, props.topicID]);

    useEffect(() => {

    }, [content])

    const handleNext = (e) => {

        loadScript();

        let getCurrentIndex = parseInt(document.getElementById("rowcontentcustom").getAttribute("currentIndex"));

        let randomNumber = parseInt(document.getElementById("rowcontentcustom").getAttribute("randomNumber"));

        let totalIndex = parseInt(document.getElementById("rowcontentcustom").getAttribute("totalIndex"));

        let letShowIndex = getCurrentIndex + 1;


        if (getCurrentIndex != totalIndex - 1) {

            document.getElementById("showEffectsRandom_" + randomNumber + "_" + letShowIndex).classList.remove("hideinner");
            document.getElementById("showEffectsRandom_" + randomNumber + "_" + letShowIndex).classList.remove("effectshide");
            document.getElementById("showEffectsRandom_" + randomNumber + "_" + letShowIndex).classList.add("effectsshow");
            document.getElementById("rowcontentcustom").setAttribute("currentIndex", letShowIndex);
            debugger;
            // document.getElementById("rowcontentcustom").scrollTo(0, 500);
            document.getElementById("rowcontentcustom").scrollTo(0, 500);
            document.getElementById("showEffectsRandom_" + randomNumber + "_" + letShowIndex).scrollIntoView({ behavior: "smooth" });


            // objDiv.scrollTop = objDiv.scrollHeight;         

        } else {
            letShowIndex = 0;
            document.getElementById("subtopics").style.display = "block";
            document.getElementById("main-btns").style.display = "flex";
            document.getElementById("rowcontentcustom").style.display = "none";
            //document.getElementById("rowcontentcustom").parentElement.style.display = "none";
            //document.getElementsByClassName("rowcontentcustom50").classList.add("hideinner");
            document.getElementById("rowcontentcustom").parentElement.parentElement.classList.add("hideinner");
            document.getElementById("contentbtn").style.display = "none";
            document.getElementById("rowcontentcustom").setAttribute("currentIndex", letShowIndex);
            setContent('');
            setSubTopicName('');
            setSubTopicIndexApple(null);
        }

        //        document.getElementById("lessonBottom").scrollIntoView({ behavior: "smooth" });
    }

    const handleContent = (e) => {

        //alert("in")
        let newSubTopicIndex = (subTopicIndex) ? subTopicIndex : 0;
        let newContent = topicDescription.topicText[newSubTopicIndex];
        setSubTopicName(topicDescription.topicTitleIndexs[newSubTopicIndex])
        let randomNumber = Math.floor((Math.random() * 20000) + 1);
        let storePlaceHolders = [];

        let stringArray = newContent.split('class="title-content"');
        let j = 0;
        var p = 0;
        let effectClasses = ["fadein", "moveRight", "slidein"];
        let stateString = [];

        for (const s of stringArray) {

            let className = "block";
            let class2 = "effectsshow";

            let class3 = effectClasses[Math.floor(Math.random() * effectClasses.length)];

            if (j !== 1) {
                className = "hideinner";
                class2 = "effectshide"
            }

            if (j == 1) {
                stateString[j] = stringArray[0] + "id='showEffectsRandom_" + randomNumber + "_" + j + "' class='title-content effects " + class2 + " " + className + " " + class3 + "'" + s;
            } else {
                stateString[j] = "id='showEffectsRandom_" + randomNumber + "_" + j + "' class='title-content effects " + class2 + " " + className + " " + class3 + "'" + s;
            }

            j = j + 1;
        }
        stateString.shift();
        let newStr = stateString.join(" ");



        setContent(newStr);

        setSubTopicIndex(newSubTopicIndex + 1);
        setSubTopicIndexApple(newSubTopicIndex);

        document.getElementById("subtopics").style.display = "none";
        document.getElementById("main-btns").style.display = "none";
        document.getElementById("rowcontentcustom").style.display = "block";
        //document.getElementById("rowcontentcustom50").parentNode.classList.remove("hideinner");
        let parent = document.getElementById("rowcontentcustom").parentElement.parentElement;
        parent.classList.remove("hideinner");

        document.getElementById("contentbtn").style.display = "flex";
        document.getElementById("rowcontentcustom").setAttribute("currentIndex", 1);
        document.getElementById("rowcontentcustom").setAttribute("totalIndex", j);
        document.getElementById("rowcontentcustom").setAttribute("randomNumber", randomNumber);
        loadScript();


    }

    const handleLiClick = (e) => {

        setContent('');
        setTimeout(function(){ 
            let newContent = topicDescription.topicText[e.target.getAttribute("data-index")];
            setSubTopicName(topicDescription.topicTitleIndexs[e.target.getAttribute("data-index")])
            let randomNumber = Math.floor((Math.random() * 20000) + 1);

            let storePlaceHolders = [];

            var str = newContent.split('<p class="title-content">&nbsp;</p>').join('');
            //str = str.split('<p class="title-content">&nbsp;​</p>').join('');

            let stringArray = str.split('class="title-content"');

            let j = 0;
            var p = 0;
            let effectClasses = ["fadein", "moveRight", "slidein"];
            let stateString = [];

            for (const s of stringArray) {

                let className = "block";
                let class2 = "effectsshow";
                let class3 = effectClasses[Math.floor(Math.random() * effectClasses.length)];

                if (j !== 1) {
                    className = "hideinner";
                    class2 = "effectshide"
                }

                if (j == 1) {
                    stateString[j] = stringArray[0] + "id='showEffectsRandom_" + randomNumber + "_" + j + "' class='title-content effects " + class2 + " " + className + " " + class3 + "'" + s;
                } else {
                    stateString[j] = "id='showEffectsRandom_" + randomNumber + "_" + j + "' class='title-content effects " + class2 + " " + className + " " + class3 + "'" + s;
                }

                j = j + 1;
            }
            stateString.shift();
            let newStr = stateString.join(" ");




            setContent(newStr);
            //tag.length;
            const indexVal = parseInt(e.target.getAttribute("data-index")) + 1;
            setSubTopicIndex(indexVal);

            //setSubTopicIndexApple(parseInt(e.target.getAttribute("data-index")));
            setSubTopicIndexApple(parseInt(indexVal-1));

            document.getElementById("subtopics").style.display = "none";
            document.getElementById("main-btns").style.display = "none";
            document.getElementById("rowcontentcustom").style.display = "block";
            //document.getElementsByClassName("rowcontentcustom50").classList.remove("hideinner");
            //document.getElementById("rowcontentcustom").parentElement.parentElement.classList.remove("hideinner");
            let parent = document.getElementById("rowcontentcustom").parentElement.parentElement;
            parent.classList.remove("hideinner");
            document.getElementById("contentbtn").style.display = "flex";
            document.getElementById("rowcontentcustom").setAttribute("currentIndex", 1);
            document.getElementById("rowcontentcustom").setAttribute("totalIndex", j);
            document.getElementById("rowcontentcustom").setAttribute("randomNumber", randomNumber);
            loadScript();
        }, 100 );
    }

    const handleBack = e => {
        history.goBack();
    }

    const handleContentBackWithLastStage = e => {

        let getCurrentIndex = parseInt(document.getElementById("rowcontentcustom").getAttribute("currentIndex"));
        let randomNumber = parseInt(document.getElementById("rowcontentcustom").getAttribute("randomNumber"));
        let totalIndex = parseInt(document.getElementById("rowcontentcustom").getAttribute("totalIndex"));

        let letHideIndex = getCurrentIndex;

        document.getElementById("showEffectsRandom_" + randomNumber + "_" + letHideIndex).classList.add("hideinner");

        document.getElementById("rowcontentcustom").setAttribute("currentIndex", letHideIndex - 1);

        if (letHideIndex == 1) {
            handleContentBack();
        }

    }


    const handleContentBack = e => {
        document.getElementById("subtopics").style.display = "block";
        document.getElementById("main-btns").style.display = "flex";
        document.getElementById("rowcontentcustom").style.display = "none";
        setSubTopicIndexApple(null);
        setSubTopicIndex(null);
        setSubTopicName('');
        setContent('');
        let parent = document.getElementById("rowcontentcustom").parentElement.parentElement;
        parent.classList.add("hideinner");
        document.getElementById("contentbtn").style.display = "none";
    }

    return (
        <div className="topicDetail">
            <div className="tpd-heading mb-2">
                <h1>{topicDescription.topicTitle} {(subTopicName) ? " - " + subTopicName : ""}</h1>
            </div>
            <div className="tpd-content">
            <div className="apple-icon" id="showOrnageApple" onClick={handleContentBack}>
                <h6 onClick={handleContentBack} data-index={0}>{"C"}</h6>
                <img data-index="0" src="/assets/img/orange-apple.png" style={{display: "inline-block"}}></img>
            </div>

                <div className="tpd-blocks">
                    <div className="tpdb-1">
                        <div id="apple-box">
                            <ScrollArea
                                speed={0.8}
                                className="rowcontent-applebox"
                                contentClassName="content"
                                horizontal={false}
                            >
                                <ul className="subTopicLi">
                                    {topicDescription.topicTitleIndexs && topicDescription.topicTitleIndexs.length ? topicDescription.topicTitleIndexs.map((topic, index) => (

                                        <div className="apple-icon" style={{ cursor: "pointer" }}>

                                            <h6 onClick={handleLiClick} data-index={index}>{index + 1}</h6> {subTopicIndexApple == index ? <img onClick={handleLiClick} data-index={index} src="/assets/img/green-apple-icon.png" style={{ display: "inline-block" }} /> : <img onClick={handleLiClick} data-index={index} src="/assets/img/red-apple-icon.png" style={{ display: "inline-block" }} />}
                                        </div>
                                    ))
                                        :
                                        <div className="oopsImage" style={{ display: "none" }}>

                                        </div>

                                    }
                                </ul>
                            </ScrollArea>
                        </div>
                    </div>

                    <div className="tpdb-2">
                        <div id="subtopics">
                            <ScrollArea
                                speed={0.8}
                                className="rowcontentcustom5"
                                contentClassName="content"
                                horizontal={false}
                            >
                                <ul className="subTopicLi">
                                    {topicDescription.topicTitleIndexs && topicDescription.topicTitleIndexs.length ? topicDescription.topicTitleIndexs.map((topic, index) => (
                                        <div className="apple-icon" onClick={handleLiClick} data-index={index}>

                                            {subTopicIndexApple == index ?
                                                <li onClick={handleLiClick} data-index={index} className="ques-panel green-box" data-index={index}> {topic.slice(0, 80)}</li>
                                                :
                                                <li onClick={handleLiClick} data-index={index} className="white-box ques-panel" data-index={index}> {topic.slice(0, 80)}</li>
                                            }
                                        </div>
                                    ))
                                        :
                                        <div className="oopsImage">
                                            <img src="/assets/img/oops-content.svg" alt="Ooops Img" />
                                            <h1>Content Not available</h1>
                                        </div>
                                    }
                                </ul>
                            </ScrollArea>
                        </div>
                        <ScrollArea
                            speed={0.8}
                            className="rowcontentcustom5 hideinner"
                            contentClassName="content"
                            horizontal={false}
                        >
                            <div id="rowcontentcustom" className="lessonContentHolder" style={{ display: "none", marginLeft: "15px" , fontSize:"17px" }}>
                                {ReactHtmlParser(content)}
                            </div>
                            <div id="lessonBottom">
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            </div>
            <div id="main-btns">

                {subTopicLength == (subTopicIndex) ?

                    <div className="pre-next-btn-row" >
                        <button className="bck-btn px-2" type="button" onClick={handleBack}>
                            <i className="fas fa-long-arrow-alt-left"></i>
                            <span> Back </span>
                        </button>
                    </div>
                    :
                    <div className="pre-next-btn-row">
                        <button className="bck-btn px-2" type="button" onClick={handleBack}>
                            <i className="fas fa-long-arrow-alt-left"></i>
                            <span> Back </span>
                        </button>
                        <button className="nxt-btn px-2" type="button" onClick={handleContent}>
                            <span> Next </span>
                            <i className="fas fa-long-arrow-alt-right"></i>
                        </button>
                    </div>
                }

            </div>
            <div className="pre-next-btn-row" id="contentbtn">
                <button className="bck-btn px-2" type="button" onClick={handleContentBackWithLastStage}>
                    <i className="fas fa-long-arrow-alt-left"></i>
                    <span> Back </span>
                </button>

                <button className="nxt-btn px-2" type="button" onClick={handleNext}>
                    <span> Next </span>
                    <i className="fas fa-long-arrow-alt-right"></i>
                </button>
            </div>
            {/*{topicDescription.topicTitleIndexs && topicDescription.topicTitleIndexs.length ?
                ""
                : 
                <div className="pre-next-btn-row" >
                    <button className="bck-btn px-2" type="button" onClick={handleBack}>
                        <i className="fas fa-long-arrow-alt-left"></i>
                        <span> Back </span>                        
                    </button>
                </div>  
            }*/}
            <FooterInner />
        </div>
    );
}

export default injectModels(['topicApi'])(Lesson);
