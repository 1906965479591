export const GET_TOPIC = 'GET_TOPIC';
export const GET_SUBJECT_HOMEWORK = 'GET_SUBJECT_HOMEWORK';
export const GET_SUBJECT_HOMEWORK_DETAILS = 'GET_SUBJECT_HOMEWORK_DETAILS';
export const CHAPTER_ID = 'CHAPTER_ID';
export const GET_SINGLE_TOPIC = 'GET_SINGLE_TOPIC';
export const GET_QUESTIONS = 'GET_QUESTIONS';
export const GET_SINGLE_QUESTION = 'GET_SINGLE_QUESTION';
export const SUBMIT_PAPER = 'SUBMIT_PAPER';
export const GET_TEST_DETAIL = 'GET_TEST_DETAIL';
export const GET_REPORT = 'GET_REPORT';
export const GET_COMMENTS = 'GET_COMMENTS';