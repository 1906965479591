import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { JWT } from '../helpers';

const getCount = async() => {
    try{
    	const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/notifications/get-unreadnotification-count`, {"Authorization": `Bearer ${accessToken}`}, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getNotification = async() => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/notifications/get-my-notification`, {"Authorization": `Bearer ${accessToken}`}, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const updateStatus = async(data) => {
    try{
          const accessToken = JWT.getJWT();
          const response = await axios.post(`${API_ENDPOINT}/notifications/update-read-status`, JSON.stringify(data), {headers:{"Content-Type":"application/json","Authorization": `Bearer ${accessToken}`}});
          if(response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getAllHomePageData = async(data) => {
    try{
          const accessToken = JWT.getJWT();
          const response = await axios.get(`${API_ENDPOINT}/cms-page/get-homepage`, {headers:{"Content-Type":"application/json","Authorization": `Bearer ${accessToken}`}});
          if(response.status === 200) {
            return response.data;
            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getPrivacyPolicyData = async(pageSlug) => {
    try{
          const accessToken = JWT.getJWT();
          const response = await axios.post(`${API_ENDPOINT}/cms-page/get-single-page-info`,{pageSlug:pageSlug},

          {headers:
            {"Content-Type":"application/json","Authorization": `Bearer ${accessToken}`}});
          if(response.status === 200) {

            return response.data;
            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const NotificationApi = {
    getCount,
    getNotification,
    updateStatus,
    getAllHomePageData,
    getPrivacyPolicyData
}

export {NotificationApi};