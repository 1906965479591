import { GET_COUNT, GET_NOTIFICATION, UPDATE_STATUS} from "./constants";

const intialState = {
   count:null,
   notification:null,
   isLoading:true,
   updateStatus:false,
}

export default (state=intialState, action) => {
    switch(action.type){
        case GET_COUNT :
            return {
                ...state,
                count:action.payload
            }

        case GET_NOTIFICATION :
            return {
                ...state,
                notification:action.payload,
                isLoading:false,
            }

        case UPDATE_STATUS :
            return {
                ...state,
                notification:action.payload,
                isLoading:false,
            }
            
        default :
            return state;    
    }
}