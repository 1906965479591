import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { JWT } from '../helpers';

const GetSubject = async(studentClass) => {
    try{
    	const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/subjects/filter-on-class`, studentClass, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const UpdateMarker = async(data) => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/subjects/update-marker`, data, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const SubjectApi = {
    GetSubject,
    UpdateMarker,
}
export {SubjectApi};