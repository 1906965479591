import {
    GET_USER,
    UPDATE_SUCCESS,
    UPDATE_FAILED,
    CHANGE_PASSWORD,
} from './constants';

import { UserApi } from '../../../services';
import { JWT } from '../../../helpers';

export const getUser = () => async (dispatch, getState) => {
    const result = await UserApi.getUser();
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_USER, payload: result.data});
            return result;
        } else {
            dispatch({ type: GET_USER, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const updateUser = (params = {}) => async (dispatch, getState) => {
    const result = await UserApi.updateUser(params);
    if (result.data) {
        if (result.success) {
            dispatch({ type: UPDATE_SUCCESS, payload: result.data});
            return result;
        } else {
            dispatch({ type: UPDATE_FAILED, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const changePassword = (params = {}) => async (dispatch, getState) => {
    const result = await UserApi.changePassword(params);
    if (result.data) {
        if (result.success) {
            dispatch({ type: CHANGE_PASSWORD, payload: result.data});
            return result;
        } else {
            dispatch({ type: CHANGE_PASSWORD, payload:result.data});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}
