import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch, withRouter, Redirect } from "react-router-dom";
import {store }from './redux/Store';
import { Provider } from "react-redux";
import {Routes} from './constants';
import {Home, PrivecyPolicy, TermCondition} from "./pages/home";
import {About, Faq} from "./pages/home";
import {Dashboard} from "./pages/dashboard";
import {ChapterList} from "./pages/chapter";
import {Report,ManualReport,DownLoadReport} from "./pages/report";
import {Topic,TopicDetail,Result,Congratulation} from "./pages/topic";
import {HomeWorkList ,HomeWorkQuestions ,HomeWorkResult ,HomeWorkSubject} from "./pages/homework";
import {Chat} from "./pages/chat";
import {NotFound} from "./pages/includes";
import {Notification} from "./pages/notification";
import {Register,Login,ForgotPassword,ResetPassword,Verification,EditProfile,ChangePassword} from "./pages/auth";
import {AuthenticRoute} from './AuthenticRoute';
import {NonAuthenticRoute} from './NonAuthenticRoute';
import logo from './logo.svg';
import './App.css';
import {NewTopicDeatils} from "./pages/newTopic";
import ManualResult from "./pages/topic/ManualResult";


function App() {
  return (
      <Router>
        <Switch>
          <Route exact path={Routes.DEFAULT} component={Home} />
          <NonAuthenticRoute exact path={Routes.REGISTER} component={Register} /> 
          <NonAuthenticRoute exact path={Routes.LOGIN} component={Login} />
          <NonAuthenticRoute exact path={Routes.FORGOT_PASSWORD} component={ForgotPassword} />
          <NonAuthenticRoute exact path={Routes.NEW_TOPIC_DETAILS} component={NewTopicDeatils} />
          <NonAuthenticRoute exact path={Routes.RESET_PASSWORD} component={ResetPassword} />
          <NonAuthenticRoute exact path={Routes.VERIFICATION} component={Verification} />
          <NonAuthenticRoute exact path={Routes.MANUAL_REPORT} component={ManualReport} />
          <NonAuthenticRoute exact path={Routes.MANUAL_RESULT} component={ManualResult} />
          
          <NonAuthenticRoute exact path={Routes.PRIVECY_POLICY} component={PrivecyPolicy} />
          <NonAuthenticRoute exact path={Routes.TERM_CONDITION} component={TermCondition} />
          <AuthenticRoute exact path={Routes.EDIT_PROFILE} component={EditProfile} />
          <AuthenticRoute exact path={Routes.CHANGE_PASSWORD} component={ChangePassword} />
          <AuthenticRoute exact path={Routes.DASHBOARD} component={Dashboard} />
          <NonAuthenticRoute exact path={Routes.ABOUT} component={About} />
          <NonAuthenticRoute exact path={Routes.FAQ} component={Faq} />
          <AuthenticRoute exact path={Routes.CHAPTER_LIST} component={ChapterList} />
          <AuthenticRoute exact path={Routes.REPORT} component={Report} />
          <AuthenticRoute exact path={Routes.DOWNLOAD_REPORT} component={DownLoadReport} />
          <AuthenticRoute exact path={Routes.TOPIC} component={Topic} />

          <AuthenticRoute exact path={Routes.HOME_WORK_LIST} component={HomeWorkList}/>
          <AuthenticRoute exact path={Routes.HOME_WORK_QUESTION} component={HomeWorkQuestions}/>   
          <AuthenticRoute exact path={Routes.HOME_WORK_RESULT} component={HomeWorkResult}/>
          <AuthenticRoute exact path={Routes.HOME_WORK_SUBJECT} component={HomeWorkSubject}/>

          
          <AuthenticRoute exact path={Routes.TOPIC_DETAIL} component={TopicDetail} />
          <AuthenticRoute exact path={Routes.RESULT} component={Result} />
          <AuthenticRoute exact path={Routes.CONGRATULATION} component={Congratulation} />
          <AuthenticRoute exact path={Routes.CHAT} component={Chat} />
          <AuthenticRoute exact path={Routes.NOTIFICATION} component={Notification} />
       

          <Route component={NotFound} />         
        </Switch>
      </Router>
   );
}
export default App;
