import {
    LOGIN_SUCCESS,
    LOGOUT,
    LOGIN_FAILED, 
    REGISTER_SUCCESS,
    REGISTER_FAILED,   
} from './constants';
import {CLASS_ID, USER_NAME, USER_ID} from '../../../constants';
import { Auth } from '../../../services';
import { JWT } from '../../../helpers';

export const login = (params = {}) => async (dispatch, getState) => {
    const result = await Auth.Login(params);
    if (result.data) {
        if (result.success) {
            //set access token to local storage 
            JWT.setJWT(result.data.access_token);
            localStorage.setItem(CLASS_ID, result.data.classId);
            localStorage.setItem(USER_NAME, result.data.firstName);
            localStorage.setItem(USER_ID, result.data.id);
            dispatch({ type: LOGIN_SUCCESS, payload: result.data});
            return result;
        } else {
            dispatch({ type: LOGIN_FAILED, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject(result.message);
    }
   
}

export const register = (params = {}) => async (dispatch, getState) => {
    const result = await Auth.Register(params);
   
    if (result.data) {
       // const data = result.data;
        if (result.success) {
            //set access token to local storage 
            //JWT.setJWT(result.data);
            dispatch({ type: REGISTER_SUCCESS, payload: result});
            return result;
        } else {
            dispatch({ type: REGISTER_FAILED, payload:null});
            
            
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
   
}

export const forgot = (params = {}) => async (dispatch, getState) => {
    const result = await Auth.ForgotPassword(params);
    if (result.data) {
        const data = result.data;
        if (result.success) {
            //set access token to local storage 
            JWT.setJWT(result.data.access_token);
            return result;
        } else {
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
   
}

export const logout = () => async (dispatch, getState) => {
    JWT.logout();
    dispatch({ type: LOGOUT });
}