import React, { useState } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { NotFoundHeader, FooterInner } from "../includes";
const NotFound = () => {
    return(
      <div>
        <NotFoundHeader/>
        <div id="main" className="InnerClass inner-page-main">
          <div className="container">
              <div className="row justify-content-center">     
                <img className="notFoundImage" src="/assets/img/404-error-page.svg" style={{width:'45%', height:'45%'}}/>
            </div>          
          </div>
        </div>
        <FooterInner/>
      </div>
    );
}

export default NotFound;
