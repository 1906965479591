import {ACCESS_TOKEN} from '../constants';
import jwt_decode from "jwt-decode";
const setJWT = (token) => {
    removeJWT(); 
    localStorage.setItem(ACCESS_TOKEN, token);
}

const getJWT = () => {
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if(accessToken !== undefined && accessToken !== null || accessToken !== "") {
        return accessToken;
    }

    return null;
}

const removeJWT = () => {
    const accessToken = getJWT();
    if(accessToken) {
        localStorage.removeItem(ACCESS_TOKEN);
    }
}

const isLoggedIn = () => {
    const accessToken = getJWT();
    if(accessToken) {
        const decoded = jwt_decode(accessToken);
        const {exp} = decoded;
        const currentTimeStamp = parseInt((new Date()).getTime()/1000);
        if(currentTimeStamp < exp) {
            return true;
        }
           logout();
           return false;
    } else {
        return false;
    }
}
const logout = () => {
    removeJWT();
}

const getDecodedJWT = () =>{
    const accessToken = getJWT();
    if(accessToken!==null){
      let decoded = jwt_decode(accessToken);
       return decoded;
    }
}

export const JWT = {
    setJWT,
    getJWT,
    removeJWT,
    isLoggedIn,
    logout,
    getDecodedJWT,
}