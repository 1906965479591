import React, { useState, useEffect } from "react";
import { Routes } from "../../constants";
import { Link, Redirect, useHistory } from "react-router-dom";
import { Header } from "../includes";
import InputGroupAddon from 'bee-input-group-addon';
//import "/node_modules/font-awesome/css/font-awesome.css";
import { InputGroup, InputGroupText, Input, Form, Row, Col, Label, Button, FormGroup } from "reactstrap";
import { injectModels } from '../../redux/injectModels';
import { Auth, StudentClassApi } from '../../services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// const EmailRegx =  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;



const EmailRegx= /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const Register = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDOB] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [subscrptionStartDay, setSubscrptionStartDay] = useState("");

  const [parentEmail, setParentEmail] = useState("");
  const [schoolYear, setSchoolYear] = useState("");
  const [school, setSchool] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentClassArr, setStudentClassArr] = useState([]);
  const [selectDay, setSelectDay] = useState([]);
  const [username, setUsername] = useState("");
  const [studentClassTitle, setStudentClassTitle] = useState("");
  const [parentName, setParentName] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [contactError, setContactError] = useState('');
  const [dobError, setDOBError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [subscrptionStartDayError, setSubscrptionStartDayError] = useState('');

  const [parentEmailError, setParentEmailError] = useState('');
  const [schoolYearError, setSchoolYearError] = useState('');
  const [schoolError, setSchoolError] = useState('');
  const [studentClassError, setStudentClassError] = useState('');
  const [selectDayError, setSelectDayError] = useState('');
  const [parentNameError, setParentNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [days, setDays] = useState([]);
  const [success, setSuccess] = useState('');
  const [age, setAge] = useState('');
  const [ageError, setAgeError] = useState('');
  const [confirmnewpassword, setConfirmPassword] = useState('');
  const [confirmNewpasswordError, setConfirmPasswordError] = useState('');


  const [houseNoName, setHouseNoName] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');


  const [houseNoNameError, setHouseNoNameError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [cityError, setCityError] = useState('');
  const [postcodeError, setPostCodeError] = useState('');

  const [programme, setProgramme] = useState('');
  const [programmeError, setProgrammeError] = useState('');
  const [showError,setShowError] = useState('');


  const [optionalEmailAddress,setOptionalEmailAddress] = useState("");
  const [optionalEmailAddressError,setOptionalEmailAddressError] = useState("");
  const dateYear = new Date();
  // const EmailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;



  
  
  const PhnRegx = /[0-9]/;
  const history = useHistory();

  useEffect(() => {
    StudentClassApi.getAllClass()
      .then((response) => {
        const data = response.data;
        if (data.success) {
          const title = data.data;
          setStudentClassArr(title);
          // setWeek(data);       
        } else {
          setError(data.message);
        }
      }).catch((err) => {
        setError("Network error");
      })
  }, [])

  const handelBack = e => {
    history.goBack();
  }
  const handelProgramme = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setProgrammeError("Package is required");
    } else {
      setProgrammeError("");
    }
    setProgramme(inputValue.trimStart());
  }


  const handelHouseNoName = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setHouseNoNameError("House name/number is required");

    } else {
      setHouseNoNameError("");
    }
    setHouseNoName(inputValue.trimStart());
  }
  const handelStreet = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setStreetError("Street is required");

    } else {
      setStreetError("");
    }
    setStreet(inputValue.trimStart());
  }
  const handelCity = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setCityError("City is required");

    } else {
      setCityError("");
    }
    setCity(inputValue.trimStart());
  }
  const handelPostcode = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setPostCodeError("Postcode is required");

    } else {
      setPostCodeError("");
    }
    setPostCode(inputValue.trimStart());
  }
  const handelFirstName = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setFirstNameError("Full Name is required");
    } else if (inputValue.length < 3) {
      setFirstNameError("Full Name not be less than 3 character");
    } else {
      setFirstNameError("");
    }
    setFirstName(inputValue.trimStart());
  }

  const handleParentName = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setParentNameError("Parent Name is required");
    } else if (inputValue.length < 3) {
      setParentNameError("Parent Name not be less than 3 character");
    } else {
      setParentNameError("");
    }
    setParentName(inputValue.trimStart());
  }

  //  const handelLastName = e =>{ 
  //   e.preventDefault();
  //    let inputValue = e.target.value;
  //    if (inputValue==="") {
  //     setLastNameError("Last name is required");
  //    }else if(inputValue.length<3){
  //       setLastNameError("Last Name not be less than 3 character");
  //    }else{
  //     setLastNameError("");
  //    }
  //    setLastName(inputValue.trimStart());
  //  }

  const handleEmail = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setEmailError("Email is required");
    } else if (EmailRegx.test(email) === false) {
      setEmailError("Enter a valid email");
    } else if (inputValue) {
      const data1 = {
        email: e.target.value,
      }
      Auth.checkEmail(data1)
        .then((response) => {
          if(response.data == 0) {
            setEmailError("");
          } else {
            setEmailError("Email address has already registered.");
          }
        }).catch((err) => {
          setEmailError("Network error");
        })
    } else {
      setEmailError("");
    }
    setEmail(inputValue.trimStart());
  }
  const handlePassword = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setPasswordError("Password is required");
    } else if (inputValue.length < 6 || inputValue.length > 13) {
      setPasswordError("Password not be less than 6 character and greater than 13");
    } else {
      setPasswordError("");
    }
    setPassword(inputValue.trimStart());
  }

  const handelConfirmPassword = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setConfirmPasswordError("Confirm password is required");
    } else if (inputValue !== password) {
      setConfirmPasswordError("Password not matched");
    } else {
      setConfirmPasswordError("");
    }
    setConfirmPassword(inputValue.trimStart());
  }


  const handleContactNumber = e => {
    e.preventDefault();
    const contactRegx = /^\d{11}$/;
    let inputValue = e.target.value;
    if (inputValue === "") {
      setContactError("Contact Number is required");
    } else if (contactRegx.test(inputValue) === false) {
      setContactError("Allows 11 digit contact number.");
    } else {
      setContactError("");
    }
    setContactNumber(inputValue.trimStart());
  }

  const handelStudentClass = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    let studentClass = e.target[e.target.selectedIndex].getAttribute('title');
    if (inputValue === "") {
      setStudentClassError("Student Class is required");
      setDays("");
      setStudentClassTitle("");
    } else {
      let days = e.target[e.target.selectedIndex].getAttribute('data-days');
      var temp = new Array();
      temp = days.split(",");
      setDays(temp);
      setStudentClassError("");
      setStudentClassTitle(studentClass);
    }
    setStudentClass(inputValue.trimStart());
  }

  const handleUsername = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setUsernameError("Username is required");
    } else if (inputValue) {
      const data1 = {
        username: e.target.value,
      }
      Auth.checkEmail(data1)
        .then((response) => {
          if (response.data == 0) {
            setUsernameError("");
          } else {
            setUsernameError("Username address has already registered.");
          }
        }).catch((err) => {
          setUsernameError("Network error");
        })
    } else {
      setUsernameError("");
    }
    setUsername(inputValue.trimStart());
  }

  const handleDob = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setDOBError("DOB is required");
    } else {
      setDOBError("");
    }
    setDOB(inputValue.trimStart());
  }

  // const handleSubscrptionDay = e => {
  //   e.preventDefault();
  //   let inputValue = e.target.value;
  //   if (inputValue < dob) {
  //     setSubscrptionStartDayError("Subscrption Day is required");
  //   } else {
  //     setSubscrptionStartDayError("");
  //   }
  //   setSubscrptionStartDay(inputValue.trimStart());
  // }

  const handleParentEmail = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setParentEmailError("Parent Email is required");
    } else {
      setParentEmailError("");
    }
    setParentEmail(inputValue.trimStart());
  }


  const handleOptionalEmailAddress = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setOptionalEmailAddressError("Parent optional Email is required");
    } else if (EmailRegx.test(inputValue) === false) {
			setOptionalEmailAddressError("Enter a valid email");
		}else {
      setOptionalEmailAddressError("");
    }
    setOptionalEmailAddress(inputValue.trimStart());
  }

  const handleSchoolYear = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setSchoolYearError("Year is required");
    } else {
      setSchoolYearError("");
    }
    setSchoolYear(inputValue.trimStart());
  }

  const handleSchool = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setSchoolError("School is required");
    } else {
      setSchoolError("");
    }
    setSchool(inputValue.trimStart());
  }

  const handleAddress = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setAddressError("Address is required");
    } else {
      setAddressError("");
    }
    setAddress(inputValue.trimStart());
  }

  // const handelSelectDay = e => {
  //   e.preventDefault();
  //   let inputValue = e.target.value;
  //   if (inputValue === "") {
  //     setSelectDayError("Day is required");
  //   } else {
  //     setSelectDayError("");
  //   }
  //   setSelectDay(inputValue.trimStart());
  // }

  const handleAge = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setAgeError("is required");
    } else {
      setAgeError("");
    }
    setAge(inputValue.trimStart());
  }

  const handleDate = () => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();
    var maxDate = year + '-' + month + '-' + day;
    return maxDate;
  }

  const handleSubmit = e => {
    e.preventDefault();
    let error = false;
    const contactRegx = /^\d{11}$/;
    if (firstName === "") {
      error = true;
      setFirstNameError("Full Name is required");
    } else {
      setFirstNameError("")
    }
    //else if(lastName === ""){
    //     error = true;
    //     setLastNameError("Last Name is required");
    // }
    if (email === "") {
      error = true;
      setEmailError("Email is required");
    } else if (emailError != "") {
      error = true;
      setEmailError(emailError);
    } else {
      setEmailError("");
    }

    // if (username === "") {
    //   error = true;
    //   setUsernameError("Username is required");
    // } else if (usernameError != "") {
    //   error = true;
    //   setUsernameError(usernameError);

    // } else {
    //   setUsernameError("");
    // }

    if (password === "") {
      error = true;
      setPasswordError("Password is required");
    } else if (passwordError != "") {
      error = true;
      setPasswordError(passwordError);
    } else {
      setPasswordError("");
    }

    if (contactNumber === "") {
      error = true;
      setContactError("Contact Number is required");
    } else if (contactRegx.test(contactNumber) === false) {
      error = true;
      setContactError("Allows 11 digit only.");
    } else {
      setContactError("");
    }

    if (studentClass === "") {
      error = true;
      setStudentClassError("Please select student class");
    } else {
      setStudentClassError("");
    }

    if (parentName === "") {
      error = true;
      setParentNameError("Please enter parent name");
    } else {
      setParentNameError("");
    }

    if (parentEmail === "") {
      error = true;
      setParentEmailError("Please enter parent email");
    } else {
      setParentEmailError("");
    }


    if (optionalEmailAddress === "" && EmailRegx.test(optionalEmailAddress) === false) {
      setOptionalEmailAddressError("Please enter parent email");
    } else {
      setOptionalEmailAddressError("");
    }


    if (schoolYear === "") {
      error = true;
      setSchoolYearError("Please select school year");
    } else {
      setSchoolYearError("");
    }

    if (school === "") {
      error = true;
      setSchoolError("Please enter school name");
    } else {
      setSchoolError("");
    }

    if (houseNoName === "") {
      error = true;
      setHouseNoNameError("House No/Name is required");
    } else {
      setHouseNoNameError("");
    }

    if (street === "") {
      error = true;
      setStreetError("Street is required");
    } else {
      setStreetError("");
    }

    if (city === "") {
      error = true;
      setCityError("City is required");
    } else {
      setCityError("");
    }

    if (postCode === "") {
      error = true;
      setPostCodeError("PostCode is required");
    } else {
      setPostCodeError("");
    }

    if (dob === "") {
      error = true;
      setDOBError("DOB is required");
    } else {
      setDOBError("");
    }

    // if (subscrptionStartDay === "") {
    //   error = true;
    //   setSubscrptionStartDayError("Subscrption Start Day is required");
    // } else if (subscrptionStartDay < dob) {
    //   error = true
    //   setSubscrptionStartDayError("Please enter a valid Subscrption");
    // } else {
    //   setSubscrptionStartDayError("");
    // }

    // if (selectDay == "") {
    //   error = true;
    //   setSelectDayError("Please select day");
    // } else {
    //   setSelectDayError("");
    // }

    if (!document.getElementById("data-Checked").checked) {
      error = true;
      setAgeError("Please confirm that your are 18+");
    } else {
      setAgeError("");
    }

    if (confirmnewpassword === "") {
      error = true;
      setConfirmPasswordError("Confirm Password is required");
    } else if (password !== confirmnewpassword) {
      error = true;
      setConfirmPasswordError("Password not matched");
    } else {
      setConfirmPasswordError("");
    }

    if (programme === "") {
      setProgrammeError("Package is required");
    } else {
      setProgrammeError("");
    }
    if(showError === ""){
      setShowError("Please complete all fields in the form")
    }else{
      setShowError("");
    }

    if (error) {
      return false;
    } else {
      const data = {
        firstName: firstName,
        lastName: "",
        email: email,
        password: password,
        dob: dob,
        contactNumber: contactNumber,
        subscrptionStartDay: "",
        parentName: parentName,
        parentEmail: parentEmail,
        schoolYear: schoolYear,
        schoolName: school,
        studentClass: studentClass,
        studentClassTitle: studentClassTitle,
        username: "",
        classDay: "",
        address: '',
        confirmnewpassword: confirmnewpassword,
        houseNoName: houseNoName,
        street: street,
        city: city,
        postcode: postCode,
        programme: programme,
        parentAddEmail:optionalEmailAddress
      };

    
      Auth.Register(data)
        .then((response) => {
          if (response.success) {
            setSuccess(response.message);
            props.history.push({
              pathname: '/auth/login',
              state: { showToast: true }
            });
          } else {
            setSuccess('');
            setError(response.message);
          }
        }).catch((error) => {
          setError("Enter Correct Data");
          setSuccess('');
        });
    }
  }

  return (
    <main id="" className="login-page">
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-start pt-4 pb-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9 col-md-10 d-flex flex-column align-items-center justify-content-center">
              <div className="d-flex justify-content-center py-3">
                <Link to="/" className="register-logo d-flex align-items-center w-auto">
                  <img src="/assets/img/lpi-logo.svg" alt="" className="img-fluid" />
                </Link>
              </div>{/*End Logo*/}

              <div className="section-title2 mb-0 pb-0">
                <h2 className="text-white">Register Your Account</h2>
              </div>
              <div className="card mb-4 login-form">
                <div className="card-body pt-3">
                  <div className="col-12 mb-3">
                    
                  </div>
                  <form className="row g-3 needs-validation" onSubmit={handleSubmit} id="registrationForm">

                    <h3 style={{ textAlign: 'center' }}> Parent Details</h3> {" "}
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Parent Full Name <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                        <input className="form-control" type="text" id="parentName" pattern="^[( )a-zA-Z]+$" name="parentName" autoComplete="off" onChange={handleParentName} />
                        {parentNameError && <div className="invalid-feedback">{parentNameError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Parent Email Address <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                        <input className="form-control" type="email" id="parentEmail" name="parentEmail" autoComplete="off" onChange={handleParentEmail} />
                        {parentEmailError && <div className="invalid-feedback">{parentEmailError}</div>}
                      </div>
                    </div>


                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Additional Parent Email Address</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                        <input className="form-control" type="email" id="parentEmail" name="parentEmail" autoComplete="off" onChange={handleOptionalEmailAddress} />
                      </div>
                    </div>
                    
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Contact Number <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-phone-alt"></i></span>
                        <input type="number" className="form-control" id="contactNumber" name="contactNumber" onChange={handleContactNumber} min="0" />
                        {contactError && <div className="invalid-feedback">{contactError}</div>}
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">House No/Name <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-map-marker"></i></span>
                        <input className="form-control" type="text" id="houseNoName" name="house No/ Name" autoComplete="off" onChange={handelHouseNoName} />
                        {houseNoNameError && <div className="invalid-feedback">{houseNoNameError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Street <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-map-marker"></i></span>
                        <input className="form-control" type="street" id="street" name="street" autoComplete="off" onChange={handelStreet} />
                        {streetError && <div className="invalid-feedback">{streetError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">City <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">


                        <span className="input-group-text"><i class="fas fa-map-marker"></i></span>
                        <input className="form-control" type="text" id="city"   pattern="^[( )a-zA-Z]+$" name="city" autoComplete="off" onChange={handelCity} />
                        {cityError && <div className="invalid-feedback">{cityError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Post Code <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-map-marker"></i></span>
                        <input type="text" className="form-control" id="postCode" name="postCode" onChange={handelPostcode} />
                        {postcodeError && <div className="invalid-feedback">{postcodeError}</div>}
                      </div>
                    </div>

                    <h3 style={{ textAlign: 'center' }}> Student Details</h3> {" "}
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Student Full Name <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                        <input className="form-control" type="text" id="firstname"    pattern="^[( )a-zA-Z]+$" name="firstname" onChange={handelFirstName} />
                        {firstNameError && <div className="invalid-feedback">{firstNameError}</div>}
                      </div>
                    </div>
                  
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Student Email Address (Used for login) <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                        <input className="form-control" type="email" id="email" name="email"  onKeyUp={handleEmail} onChange={handleEmail} autoComplete="off" />
                        {emailError && <div className="invalid-feedback">{emailError}</div>}
                      </div>
                    </div>
                  
                    {/* <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Account Username <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                        <input type="text" className="form-control" id="userName"  pattern="^[( )a-zA-Z]+$" name="userName" onChange={handleUsername} autoComplete="off" />
                        {usernameError && <div className="invalid-feedback">{usernameError}</div>}
                      </div>
                    </div> */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Student Date of Birth <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-calendar"></i></span>
                        <input type="date" className="form-control" id="dob" name="dob" max={handleDate()} onChange={handleDob} min="1990-01-01" />
                        {dobError && <div className="invalid-feedback">{dobError}</div>}
                      </div>
                    </div>
                    
                    <div className="col-12 col-md-6">
                      <label htmlFor="yourPassword" className="form-label">Account Password <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-lock"></i></span>
                        <input type="password" className="form-control" id="password" name="password" onChange={handlePassword} autoComplete="off" />
                        {passwordError && <div className="invalid-feedback">{passwordError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="yourPassword" className="form-label">Confirm Account Password <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-lock"></i></span>
                        <input type="password" className="form-control" id="password" name="password" onChange={handelConfirmPassword} autoComplete="off" />
                        {confirmNewpasswordError && <div className="invalid-feedback">{confirmNewpasswordError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">School <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-school"></i></span>
                        <input type="text" className="form-control" id="school"  pattern="^[( )a-zA-Z]+$" name="school" onChange={handleSchool} />
                        {schoolError && <div className="invalid-feedback">{schoolError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">School Year <span style={{ color: "red" }}>*</span></label>
                      <select id="schoolYear" name="lebel" className="form-control" value={schoolYear} onChange={handleSchoolYear} autoComplete="off"  >
                        <option value="">Select School Year</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                      </select>
                      {schoolYearError && <div className="invalid-feedback">{schoolYearError}</div>}
                    </div>

                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Select Tuition Class <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-school"></i></span>
                        <select id="studentClass" name="studentClass" className="form-control" onChange={handelStudentClass}>
                          <option value="">Select Class</option>
                          {studentClassArr.map(studentClassArr => (
                            <option key={studentClassArr._id}
                              value={studentClassArr._id}
                              data-days={studentClassArr.workingDays}
                              title={studentClassArr.title}
                            >
                              {studentClassArr.title}
                            </option>
                          ))}
                        </select>
                      </div>
                      {studentClassError && <div className="invalid-feedback">{studentClassError}</div>}
                    </div>

                    {/* <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Select Tuition Day <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i class="fas fa-calendar-day"></i></span>
                        <select id="selectDay" name="selectDay" className="form-control" onChange={handelSelectDay}>
                          <option value="">Select Day</option>
                          {days ? days.map((day) => (
                            <option key={day} value={day}>
                              {day}
                            </option>
                          )) : ""}
                        </select>
                      </div>
                      {selectDayError && <div className="invalid-feedback">{selectDayError}</div>}
                    </div> */}

                    {/* <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Subscription Date <span style={{ color: "red" }}>*</span></label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-calendar-alt"></i></span>
                        <input className="form-control" type="date" id="subscrptionStartDay" name="subscrptionStartDay" max={handleDate()} onChange={handleSubscrptionDay} />
                        {subscrptionStartDayError && <div className="invalid-feedback">{subscrptionStartDayError}</div>}
                      </div>
                    </div> */}

                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Package <span style={{ color: "red" }}>*</span></label>
                      <select id="programme" name="lebel" className="form-control" value={programme} onChange={handelProgramme} autoComplete="off"  >
                        <option value="">Select Package</option>
                        <option value="online system">Online System</option>
                        <option value="face to face">Face to Face</option>
                      </select>
                      {programmeError && <div className="invalid-feedback">{programmeError}</div>}
                    </div>
                    <div className="text-start btn-custom-action" style={{ display: "flex" }}>

                      <input type='checkbox' id="data-Checked" style={{ zoom: "2" }} />  {" "}
                      <span><h5 style={{ font: "400", marginLeft: "10px", fontSize: "16px" }}>Tick box to confirm you are the parent/guardian and are over the age of 18</h5>  </span> {" "}
                    </div>
                    {ageError && <div className="invalid-feedback">{ageError}</div>}

                    <div className="col-12">
                      <div className="row justify-content-center">
                        <div className="pre-next-btn-row  justify-content-center">
                          <button className="org-btn rounded-pill px-4 pr-2 pl-2 mr-2 ml-2" type="button" onClick={handelBack}>
                            <i className="fas fa-long-arrow-alt-left"></i>
                            <span> Back </span>
                          </button>
                          <button className="success-btn rounded-pill px-4" type="submit" style={{ marginLeft: "10px" }}>
                            <span> Submit </span>
                          </button>
                        </div>
                        {showError && <div className="invalid-feedback">{showError}</div>}
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <p className=" mb-0">Do you have an account? <Link to="/auth/login">Login Now</Link></p>
                    </div>
                  </form>
                </div>
              </div>
              <div className="text-center">
                <p className="text-white small">&copy; Copyright <span>LPI Tuition Ltd © {dateYear.getFullYear()}</span>. All Rights Reserved </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
export default injectModels(['auth'])(Register);
