import { TailSpin } from "react-loader-spinner";
import React  from 'react';

export const data = [
  {
    Component: TailSpin,
    props: {
      color: "#0ead69",
      height: 100,
      width: 110
    },
    name: "TailSpin"
  },
];

const Loader = () => {
    return(
		<>
    	 <div className="container mt-5 text-center">
	      <div className="row justify-content-center">
	        {data.map((loader, index) => (
	          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-2 p-5">
	            <div
	              data-tip={loader.name}
	              data-for="happyFace"
	              key={loader.name + index}
	              className="loaderBox"
	            >
	              <loader.Component {...loader.props} />
	            </div>
	          </div>
	        ))}
	      </div>
	      
	    </div>
		</>
    );
}
export default Loader;

