import React, {useState,useEffect} from "react";
import Chat from "./ChatListItem";
import { Nav } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import {injectModels} from '../../redux/injectModels';

const ChatList = (props) => {
  const [userId, setUserId] = useState("");
  const [name, setName] = useState("");
  return (
    <Nav className="list-unstyled d-block mailbox listing-over">
      <div className="message-center" style={{ height: "calc(100vh - 274px)" }}>
          <Chat
            key="testttt1111"
            thumb="test1"
            status="test2"
            contactName="test3"
            chatExcerpt="test4"
          />
      </div>
    </Nav>
  );
};

export default injectModels(['chatApi'])(ChatList);

