import { LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT, REGISTER_SUCCESS, REGISTER_FAILED,} from "./constants";
import { JWT } from '../../../helpers';
import { CLASS_ID, USER_NAME, USER_ID } from '../../../constants';

const initialState = {
    accessToken: JWT.getJWT(),
    isLoggedIn: JWT.isLoggedIn(),
    classId:localStorage.getItem(CLASS_ID),
    firstName : localStorage.getItem(USER_NAME),
    id:localStorage.getItem(USER_ID),
}

export default (state=initialState, action) => {
    switch(action.type) {
        case LOGIN_SUCCESS :
            return {
                ...state,
                accessToken: action.payload.access_token,
                isLoggedIn: true,
                classId: action.payload.classId,
                firstName: action.payload.firstName,
                id:action.payload.id,
            }
           
            case LOGIN_FAILED :
            {
                return {
                    ...state,
                    accessToken: null,
                    isLoggedIn: false,
                }  
            }
                 
        case REGISTER_SUCCESS : 
            return {
                ...state,
                accessToken:null,
                isLoggedIn:false
            }

        case REGISTER_FAILED : 
            return {
                ...state,
                accessToken:null,
                isLoggedIn:false
            }
      
        default:
            return state;          
    }
}