import { GET_SUBJECT } from "./constants";

const intialState = {
    items:[],
    total:0,
    isLoading:true,
}

export default (state=intialState, action) => {
    switch(action.type){
        case GET_SUBJECT :
            return {
                ...state,
                items:action.payload,
                isLoading:false,
            }
        default :
            return state;    
    }
}