import React, { useState, useEffect } from "react";
import { Routes, WIRIS_PATH } from "../../constants";
import { Link, useHistory, useParams } from "react-router-dom";
import { Header, Loader } from "../includes";
import moment from "moment";
import "moment-duration-format";
import { injectModels } from "../../redux/injectModels";
import ReactHtmlParser from "react-html-parser";
import Swal from "sweetalert2";
import { practiceQuestionData } from "../../constants/data";
import {
  InputGroup,
  InputGroupText,
  Input,
  Form,
  Row,
  Col,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import CKEditor1 from "../include/EditorAnswer.component";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const NewPracticeQuestion = (props) => {
  const [dynamicColor, setDynamicColor] = useState("");
  const [nextPage, setNextPage] = useState(0);
  const [timer, setTimer] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [singleQuestions, setSingleQuestions] = useState([]);
  const [questionId, setQuestionId] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [isClick, setIsClick] = useState(false);
  const [text, setText] = useState(0);
  const [questionLength, setQuestionLength] = useState(0);
  const [questionIdWithAnswer, setQuestionIdWithAnswer] = useState([]);
  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState("");
  const [currentQuestionType, setCurrentQuestionType] = useState("");
  const [multiValue, setMultiValue] = useState([]);
  const [paperId, setPaperId] = useState("");
  const [rightAnswer, setRightAnswer] = useState([]);
  const [marks, setMarks] = useState("");
  const [paperType, setPaperType] = useState("");
  const [questionTitle, setQuestionTitle] = useState([]);
  const [descriptiveValue, setDescriptiveValue] = useState("");
  const [descriptiveId, setDescriptiveId] = useState("");
  const [finalData, setFinalData] = useState([]);
  const [valuesArray, setValuesArray] = useState([]);
  const [xValuesArray, setXValuesArray] = useState([]);
  const [yValuesArray, setYValuesArray] = useState([]);
  const [imageData, setImageData] = useState([]);
  const [imageInputValue, setImageInputValue] = useState([]);
  const [attemptedAnswerStatus, setAttemptedAnswerStatus] = useState([]);
  const [attemptedFillBoxValues, setAttemptedFillBoxValues] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [totalMark, setTotalMark] = useState("");
  const [matchedValue, setMatchedValue] = useState([]);
  const [imageWidth, setImageWidth] = useState("");
  const [imageAttemptedInputValue, setImageAttemptedInputValue] = useState([]);

  let history = useHistory();
  let setDate = [];
  const color = props.color;
  var inputAnswer = [];
  let { chapterId, topicId } = useParams();
  const loadScript = () => {
    const script = document.createElement("script");
    script.src = WIRIS_PATH;
    script.async = true;
    document.body.appendChild(script);
  };

  useEffect(() => {
    setAttemptedAnswerStatus([]);
    setIsLoading(true);
    const resultApi = props.demo.getAllDemoData();
    resultApi.then((result) => {
      if (result) {
        if (result.success == true) {
         
          setIsLoading(false);
          loadScript();
          setQuestions(result.data.questionListPractice);
          setQuestionLength(result.data.questionListPractice.length);
          setPaperId(result.data._id);
          setMarks(result.data.totalMarks);
          setPaperType(result.data.type);
          let sum = 0;
          for (const questionMarks of result.data.questionListPractice) {
            sum = parseInt(questionMarks.questionMarks) + sum;
            setTotalMark(sum);
          }
          let questionIndex = 0;
          let newImageData = [];
          let newQuestionTitle = [];
          let valueArray = [];
          let xValueArray = [];
          let yValueArray = [];
          let resultQuestions = [];

          for (const que of result.data.questionListPractice) {
            resultQuestions[questionIndex] = que;

            if (que.questionType == "fill") {
              //let abc = '<p>&nbsp;</p><p>hello my name is | sharma and i belgongs to jaipur with | &nbsp;</p>';

              let storePlaceHolders = [];
              let stringArray = que.questionTitle.split(" ");
              let stringArrayToShow = que.questionTitle.split(" ");

              // let stringArray = abc.split(" ");
              // let stringArrayToShow = abc.split(" ");

              let j = 0;
              let k = 0;
              let stateString = [];

              for (const s of stringArray) {
                if (s == "|") {
                  stateString.push(j);
                  stringArray[j] = storePlaceHolders[k]
                    ? storePlaceHolders[k]
                    : "_____";
                  stringArrayToShow[j] = storePlaceHolders[k]
                    ? "<span class='downArrow'>" +
                      storePlaceHolders[k] +
                      "</span>"
                    : "_____";
                  k = k + 1;
                }
                j = j + 1;
              }

              let order = que.placeHolders;
              //let order = ['<math xmlns="http://www.w3.org/1998/Math/MathML"><mn>4</mn><mfrac bevelled="true"><mn>4</mn><mn>4</mn></mfrac></math>', "<span>B</span>"];

              resultQuestions[questionIndex].placeHolders = order.sort(
                () => 0.5 - Math.random()
              );
              newQuestionTitle[questionIndex] = stringArrayToShow;
              resultQuestions[questionIndex].questionTitle =
                stringArrayToShow.join(" ");
            }

            if (que.questionType == "image") {
              let a = que.answerOptions[0]
                ? que.answerOptions[0].split(",")
                : [];
              que.answerOptions = a;
            }

            if (que.questionType == "match") {
              // let a = que.answerOptions
              // que.answerOptions[Math.floor(Math.random()*que.answerOptions)];
              let abArray = []
              for(const a of que.answerOptions){
                abArray.push(a.fieldss);
              }

              resultQuestions[questionIndex].answerOptionForDrop =  abArray.sort(() => 0.5 - Math.random());

            }


            questionIndex = questionIndex + 1;
          }
          setQuestions(resultQuestions);
          setImageData(newImageData);
          setQuestionTitle(newQuestionTitle);
          setValuesArray(valueArray);
          setXValuesArray(xValueArray);
          setYValuesArray(yValueArray);
        } else {
          // setQuestions(result);
        }
      }
    });
  }, []);

  function getAllDemoData(queId) {
    setSingleQuestions([]);
    const singleData = {
      questionId: queId,
    };
    const resultApi = props.demo.getAllDemoData();
    resultApi.then((result) => {
      if (result.success == true) {
        const questionData = result.data.questionListPractice;
        setIsLoading(false);
        setSingleQuestions(questionData);
        setCurrentQuestionType(practiceQuestionData.questionType);
        setRightAnswer(practiceQuestionData.rightAnawer);
        for (const que of result.data.questionListPractice) {
          if (que.questionType == "fill") {
            let storePlaceHolders = [];
            let stringArray = que.questionTitle.split(" ");
            let stringArrayToShow = que.questionTitle.split(" ");
            let j = 0;
            let k = 0;
            let stateString = [];

            for (const s of stringArray) {
              if (s == "|") {
                stateString.push(j);
                stringArray[j] = storePlaceHolders[k]
                  ? storePlaceHolders[k]
                  : "_____";
                stringArrayToShow[j] = storePlaceHolders[k]
                  ? "<span class='downArrow'>" +
                    storePlaceHolders[k] +
                    "</span>"
                  : "_____";
                k = k + 1;
              }

              j = j + 1;
            }

            setQuestionTitle(stringArrayToShow);
          }

          if (que.questionType == "image") {
          }
        }
      } else {
      }
    });
  }

  const handleBack = (e) => {
    history.goBack();
  };

  const onConfirm = (confirm) => {
    let submitData = {
      userTestId: paperId,
      paperType: paperType,
      topicId: topicId,
      chapterId: chapterId,
    };

    let storeData = [];
    let i = 0;

    for (const val of questionIdWithAnswer) {
      storeData[i] = val;
      i = i + 1;
    }
    submitData.attemptData = storeData;

    if (submitData) {
      const resultApi = props.demo.submitPaper(submitData);
      resultApi.then((result) => {
        if (result.success == true) {
          setAttemptedAnswerStatus(practiceQuestionData);

          history.push(`/result/${paperId}`);
        } else {
          setQuestions(result);
        }
      });
    }
  };
  const onCancel = (cancle) => {
    setIsConfirm(false);
    return false;
  };

  function storeCurrentToArray(id, inputValue, type, marks, indexValue) {
    let values = questionIdWithAnswer;

    if (values != "") {
      const index = values.findIndex((object) => {
        return object.questionId === id;
      });
      if (index != -1) {
        if (index !== -1) {
          values[index].questionId = id;
          values[index].attemptedAnswer = inputValue;
          values[index].type = type;
          values[index].marks = marks;
          values[index].index = indexValue;
        }
      } else {
        values.push({
          questionId: id,
          attemptedAnswer: inputValue,
          type: type,
          marks: marks,
          index: indexValue,
        });
      }
      setQuestionIdWithAnswer(values);
    } else {
      if (id != "") {
        values.push({
          questionId: id,
          attemptedAnswer: inputValue,
          type: type,
          marks: marks,
          index: indexValue,
        });
        setQuestionIdWithAnswer(values);
      }
    }
  }

  const handleFinish = (e) => {
    e.preventDefault();

    if (questionIdWithAnswer.length > 0) {
      Swal.fire({
        title: "Are you sure?",
        text: "You want to submit your test!?",
        icon: "warning",
        dangerMode: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
      }).then((willDelete) => {
        if (willDelete.isConfirmed) {
          let submitData = {
            completeTime: "20",
            totalMarks: totalMark,
            attemptData: "0",
            questions: questions,
          };

          let storeData = [];
          let i = 0;

          for (const val of questionIdWithAnswer) {
            if (val.type == "fill") {
              if (attemptedFillBoxValues[val.questionId]) {
                let attmpetedBox = attemptedFillBoxValues[val.questionId];

                let ab = [];

                let datas = document.getElementsByClassName(
                  "handelFillBox_" + val.questionId
                );

                for (const a of attmpetedBox) {
                  let index = a.dropIndex;
                  ab[index] = a.placeHolder;
                }

                let abc = [];
                let ii = 0;
                for (const a of ab) {
                  if (a) {
                    abc[ii] = a;
                    ii = ii + 1;
                  }
                }

                let quesIndex = attmpetedBox
                  ? attemptedFillBoxValues[val.questionId][0].indexValue
                  : 0;
                let realPlaceHolders = questions[quesIndex].basePlaceHolders;
                let fillBoxCounter = questions[quesIndex].fillBoxCounter;
                let breakOverLength = realPlaceHolders.slice(0, fillBoxCounter);
                let ij = 0;
                let manageType = true;
                for (const a of breakOverLength) {
                  if (abc[ij] != a) {
                    manageType = false;
                    break;
                  }
                  ij = ij + 1;
                }
                val.attemptedAnswer = manageType;
                // show answers with questions
                val.attemptedAnswerWithIndex = datas[0].innerHTML;
              }
              storeData[i] = val;
            } else {
              storeData[i] = val;
            }
            i = i + 1;
          }
          submitData.attemptData = storeData;

          if (submitData) {
            const resultApi = props.demo.submitDemoPaper(submitData);
            resultApi.then((result) => {
              Swal.fire("Test Submitted", "", "success");
              if (result.success == true) {
                setAttemptedAnswerStatus(practiceQuestionData);
                history.push({
                  pathname: `/manual-result`,
                  state: {
                    isDateShow: false,
                    submitData: result.data,
                    isPractice: true,
                  },
                });
              } else {
                setQuestions(result);
              }
            });
          }
        } else {
          return false;
        }
      });
    } else {
      Swal.fire("Please attempt questions.");
      return false;
    }
  };

  const setMatchTheFollwing = (event) => {
    let questionId = event.target.getAttribute("data-questionId");
    document.getElementById("inputresetbns_" + questionId).style.display =
      "block";
    let leftIndex = parseInt(event.target.getAttribute("data-optionId"));
    let marks = parseInt(event.target.getAttribute("data-marks"));
    let questionIndex = parseInt(event.target.getAttribute("data-index1"));
    let selectedValue = event.target.value;
    let matchedValues = matchedValue;
    let questionIdArray = matchedValues[questionIndex]
      ? matchedValues[questionIndex]
      : [];
    questionIdArray[leftIndex] = selectedValue;

    matchedValues[questionIndex] = matchedValues[questionIndex]
      ? matchedValues[questionIndex]
      : questionIdArray;
    setMatchedValue(matchedValues);
    storeCurrentToArray(
      questionId,
      questionIdArray,
      "match",
      marks,
      questionIndex
    );
  };

  const resetMatch = (event) => {
    let questionId = event.target.getAttribute("data-questionId");
    let dropDown = document.getElementsByClassName("reset-match-" + questionId);
    for (let i = 0; dropDown.length > i; i++) {
      dropDown[i].selectedIndex = false;
    }
    let matchedValues = matchedValue;
    matchedValues[questionId] = false;
    setMatchedValue(matchedValues);
  };

  const callQuestionInfo = (e) => {
    e.preventDefault();
    setIsLoading(true);
    setIsClick(true);
    setQuestionId(e.target.getAttribute("data-value"));
    setText(parseInt(e.target.innerText - 1));
    if (e.target.getAttribute("data-value")) {
      getAllDemoData(e.target.getAttribute("data-value"));
    }
  };

  const dragForMatch = (e) => {
    e.preventDefault();
  };

  const handleInputValue = (e) => {
    setCurrentQuestionAnswer("");
    let values = questionIdWithAnswer;
    let handleques = currentQuestionAnswer;
    let id = e.target.getAttribute("question-id");
    let type = e.target.getAttribute("queType");

    if (type == "descriptive") {
      let id = e.target.getAttribute("question-id");
      let element = document.getElementsByClassName("dynamic_value_" + id);

      let inputValue = e.target.value;

      let indexValue = e.target.getAttribute("index-value");
      let marks = e.target.getAttribute("marks");
      if (inputValue != "") {
        setCurrentQuestionAnswer({
          id: id,
          answer: inputValue.trim(),
          type: type,
        });
        storeCurrentToArray(
          id,
          inputValue.trim().toLowerCase(),
          type,
          marks,
          indexValue
        );
      }
    } else if (type == "optional") {
      let id = e.target.getAttribute("question-id");
      let element = document.getElementsByClassName("dynamic_value_" + id);
      let inputValue = e.target.value;
      let indexValue = e.target.getAttribute("index-value");

      let marks = e.target.getAttribute("marks");
      if (inputValue != "") {
        setCurrentQuestionAnswer({ id: id, answer: inputValue, type: type });
        storeCurrentToArray(id, inputValue, type, marks, indexValue);
      }
    } else if (type == "mcq") {
      let id = e.target.getAttribute("question-id");
      let indexValue = e.target.getAttribute("index-value");
      var inputValue = [];
      var field = document.getElementById("multi" + indexValue);
      var chks = field.getElementsByTagName("INPUT");

      let marks = e.target.getAttribute("marks");
      for (var i = 0; i < chks.length; i++) {
        if (chks[i].checked) {
          inputValue.push(chks[i].value);
        }
      }
      if (inputValue != "") {
        setCurrentQuestionAnswer({ id: id, answer: inputValue, type: type });
        storeCurrentToArray(id, inputValue, type, marks, indexValue);
      }
    } else if (type == "match") {
      let indexValue = e.target.getAttribute("index-value");
      var inputValue = [];
      var field = document.getElementById("match" + indexValue);
      let marks = e.target.getAttribute("marks");
      if (inputValue != "") {
        setCurrentQuestionAnswer({ id: id, answer: inputValue, type: type });
        storeCurrentToArray(id, inputValue, type, marks, indexValue);
      }
    } else if (type == "fill") {
      let id = e.target.getAttribute("question-id");
    } else {
      let questionId = e.target.getAttribute("question-id");
      let inputId = parseInt(e.target.getAttribute("data-id"));
      let questionIndex = e.target.getAttribute("index-value");
      let fillValue = e.target.value;
      let marks = e.target.getAttribute("marks");
      let attemptedFillBoxValue = attemptedFillBoxValues;
      let oldValueOfQuestion = attemptedFillBoxValue[questionId]
        ? attemptedFillBoxValue[questionId]
        : [];
      oldValueOfQuestion.pushedValues = oldValueOfQuestion.pushedValues
        ? oldValueOfQuestion.pushedValues
        : [];
      oldValueOfQuestion.pushedValues[inputId] = fillValue.trim().toLowerCase();
      attemptedFillBoxValue[questionId] = oldValueOfQuestion;
      setAttemptedFillBoxValues(attemptedFillBoxValue);
      storeCurrentToArray(
        questionId,
        oldValueOfQuestion.pushedValues,
        "image",
        marks,
        questionIndex
      );
    }
  };

  const writeOptions = (array) => {
    let htmlBox = "";
    for (let index = 0; index < array.length; index++) {
      htmlBox = htmlBox + "<option> " + array[index].fieldss + "</option>";
    }

    return htmlBox;
  };

  const handleQuestionTitle = (title = null) => {
    let storePlaceHolders = [];
    let stringArray = title.split(" ");
    let stringArrayToShow = title.split(" ");
    let j = 0;
    let k = 0;
    let stateString = [];

    for (const s of stringArray) {
      if (s == "|") {
        stateString.push(j);
        stringArray[j] = storePlaceHolders[k] ? storePlaceHolders[k] : "_____";
        stringArrayToShow[j] = storePlaceHolders[k]
          ? "<span class='downArrow'>" + storePlaceHolders[k] + "</span>"
          : "_____";
        k = k + 1;
      }

      j = j + 1;
    }
    setQuestionTitle(stringArrayToShow);
  };

  function allowDrop(ev) {
    ev.preventDefault();
  }

  function drag(ev) {
    ev.dataTransfer.setData("Text", ev.target.id);
    ev.dataTransfer.setData("questionId", ev.target.getAttribute("questionId"));
    ev.dataTransfer.setData("type", ev.target.getAttribute("queType"));
    ev.dataTransfer.setData(
      "indexValue",
      ev.target.getAttribute("index-value")
    );
    ev.dataTransfer.setData("marks", ev.target.getAttribute("marks"));
    ev.dataTransfer.setData(
      "placeHolder",
      ev.target.getAttribute("data-placeHolder")
    );
    ev.dataTransfer.setData(
      "dragIndex",
      ev.target.getAttribute("data-dragIndex")
    );
    ev.dataTransfer.setData(
      "dataTarget",
      ev.target.getAttribute("data-target")
    );
  }

  function drop(ev) {
    ev.preventDefault();
    var dataDrop = ev.target.getAttribute("data-accept");
    var dataTarget = ev.dataTransfer.getData("dataTarget");

    if (dataDrop == dataTarget) {
      var data = ev.dataTransfer.getData("Text");
      var data1 = ev.target.parentNode.replaceChild(
        document.getElementById(data),
        ev.target
      );
      const indexValue = ev.dataTransfer.getData("indexValue");
      var data2 = (document.getElementById(data).className = "testing aabox");

      const questionId = ev.dataTransfer.getData("questionId");
      document.getElementsByClassName(
        "inputresetbns_" + questionId
      )[0].style.display = "inline-block";

      let dropIndex = parseInt(ev.target.getAttribute("data-dropIndex"));
      let dragIndex = parseInt(ev.dataTransfer.getData("dragIndex"));

      let placeHolder = ev.dataTransfer.getData("placeHolder");

      let attemptedFillBoxValue = attemptedFillBoxValues;
      let conBox = document.getElementsByClassName(
        "handelFillBox_" + questionId
      )[0];

      let oldValue = attemptedFillBoxValue[questionId]
        ? attemptedFillBoxValue[questionId]
        : [];

      oldValue.push({
        placeHolder: placeHolder,
        dropIndex: dropIndex,
        dragIndex: dragIndex,
        indexValue: indexValue,
      });
      attemptedFillBoxValue[questionId] = oldValue;
      setAttemptedFillBoxValues(attemptedFillBoxValue);
      if (data2) {
        const id = ev.dataTransfer.getData("Text");
        const type = ev.dataTransfer.getData("type");
        const marks = ev.dataTransfer.getData("marks");
        const inputValue = document.getElementById(id).parentNode.innerText;
        if (questionId && type && inputValue) {
          storeCurrentToArray(questionId, inputValue, type, marks, indexValue);
        }
      }
    }
  }

  const resetFill = (e) => {
    let resetQuestionId = e.target.getAttribute("data-questionId");
    let resetQuestionPlaceHolders = e.target.getAttribute("data-placeholder");
    let fillBoxCounter = e.target.getAttribute("data-fillBoxCounter");
    let resetQuestionTitle = e.target.getAttribute("data-questiontitle");
    let resetQuestionMarks = e.target.getAttribute("data-questionmarks");
    let resetQuestionIndex = e.target.getAttribute("data-resetquestionindex");
    let elements = document.getElementById("fillBox_" + resetQuestionId);
    let stringToAppend = "";
    let i = 0;

    elements.getElementsByClassName("drg-content")[0].innerHTML = "";

    let attemptedFillBoxValue = attemptedFillBoxValues;
    attemptedFillBoxValue[resetQuestionId] = null;
    setAttemptedFillBoxValues(attemptedFillBoxValue);

    let filterObj = resetQuestionPlaceHolders.split(",");

    for (const p of filterObj.sort(() => 0.5 - Math.random())) {
      var span = document.createElement("span");
      span.setAttribute("id", "drag" + i + resetQuestionId + "");
      span.draggable = true;
      span.setAttribute("class", "draggable");
      span.setAttribute("data-dragindex", i);
      span.setAttribute("quetype", "fill");
      span.setAttribute("marks", resetQuestionMarks);
      span.setAttribute("questionId", resetQuestionId);
      span.setAttribute("index-value", resetQuestionIndex);
      span.setAttribute("data-placeHolder", p);
      span.setAttribute("data-target", "setDrop" + resetQuestionId);
      span.ondragstart = drag;
      span.textContent = p;
      document
        .getElementById("placeHoldersBox_" + resetQuestionId)
        .append(span);
      i = i + 1;
    }

    document.getElementsByClassName(
      "inputresetbns_" + resetQuestionId
    )[0].style.display = "none";
    elements.getElementsByClassName("fill-question")[0].innerHTML =
      elements.getElementsByClassName("fill-question-hide")[0].innerHTML;

    var nodes = elements.getElementsByClassName("fill-question")[0].childNodes;

    for (let i = 0; i < nodes.length; i++) {
      if (nodes[i].nodeName.toLowerCase() == "div") {
        nodes[i].ondrop = drop;
        nodes[i].ondragover = allowDrop;
        nodes[i].setAttribute("data-tags", "5");
        nodes[i].setAttribute("question.fillBoxCounter", fillBoxCounter);
        nodes[i].setAttribute("data-accept", "setDrop" + resetQuestionId);
      }
    }
  };

  return (
    <div>
      <div className="clipboard">
        <div className="cb-paper wada">
          <div className="topicDetail" style={{ position: "relative" }}>
            {isConfirm ? (
              <div className="rp-2 rp-small">
                <div className="report-card mb-5">
                  <div className="ls-heading mb-3">
                    <h2>Are you sure!</h2>
                  </div>
                  <div className="confirm-dialog">
                    <p>Are you sure to submit paper ?</p>
                    <div className="confirm-dialog__footer">
                      <div className="btn" onClick={onConfirm}>
                        Yes
                      </div>
                      <div className="btn" onClick={onCancel}>
                        Cancel
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="tpd-heading">
              <h1>Practice Questions</h1>
            </div>

            <form>
              {isLoading && questions && questions ? (
                <Loader />
              ) : questions && questions && questions.length ? (
                questions.map((question, index1) => (
                  <div>
                    {question.questionType == "descriptive" ? (
                      <div className="exam-paper">
                        <div className="mb-3">
                          <div className="q-heading">
                            <h5>
                              <div className="me-2">{index1 + 1})</div>
                              <div className="question-title">
                                {ReactHtmlParser(question.questionTitle)}
                              </div>
                            </h5>

                            {question.questionMarks == "1" ? (
                              <strong> ({question.questionMarks} mark) </strong>
                            ) : (
                              <strong>
                                {" "}
                                ({question.questionMarks} marks){" "}
                              </strong>
                            )}
                          </div>
                          <div className="q-list">
                            <input
                              type="text"
                              id={question._id}
                              name={question._id}
                              placeholder="Write Answer"
                              data-id={index1 + 1}
                              className={"dynamic_value_" + question._id}
                              index-value={index1}
                              question-id={question._id}
                              queType={question.questionType}
                              marks={question.questionMarks}
                              onChange={handleInputValue}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {question.questionType == "optional" ? (
                      <div className="exam-paper">
                        <div className="mb-3">
                          <div className="q-heading">
                            <h5>
                              <div className="me-2">{index1 + 1})</div>
                              <div className="question-title">
                                {ReactHtmlParser(question.questionTitle)}
                              </div>
                            </h5>
                            {question.questionMarks == "1" ? (
                              <strong>({question.questionMarks} mark)</strong>
                            ) : (
                              <strong>({question.questionMarks} marks)</strong>
                            )}
                          </div>
                          {question && question.answerOptions.length ? (
                            <div className="q-list">
                              {question &&
                              question.answerOptions &&
                              question.answerOptions.length
                                ? question.answerOptions.map(
                                    (answerOption, index) => (
                                      <div className="radiobox-row">
                                        <input
                                          type="radio"
                                          id={"option" + index + question._id}
                                          name={question._id}
                                          value={index}
                                          className="css-radiobox"
                                          question-id={question._id}
                                          queType={question.questionType}
                                          onChange={handleInputValue}
                                          marks={question.questionMarks}
                                          index-value={index1}
                                        />
                                        <label
                                          htmlFor={
                                            "option" + index + question._id
                                          }
                                          name={question._id}
                                          className="css-label lite-green-check"
                                        >
                                          {ReactHtmlParser(answerOption)}
                                        </label>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {question.questionType == "mcq" ? (
                      <div className="exam-paper">
                        <div className="mb-3">
                          <div className="q-heading">
                            <h5>
                              <div className="me-2">{index1 + 1})</div>
                              <div className="question-title">
                                {ReactHtmlParser(question.questionTitle)}
                              </div>
                            </h5>
                            {question.questionMarks == "1" ? (
                              <strong> ({question.questionMarks} mark) </strong>
                            ) : (
                              <strong>
                                {" "}
                                ({question.questionMarks} marks){" "}
                              </strong>
                            )}
                          </div>
                          {question && question.answerOptions.length ? (
                            <div className="q-list" id={"multi" + index1}>
                              {question &&
                              question.answerOptions &&
                              question.answerOptions.length
                                ? question.answerOptions.map(
                                    (answerOption, index) => (
                                      <div className="checkbox-row">
                                        <input
                                          type="checkbox"
                                          className={
                                            "css-checkbox dynamic_value_" +
                                            index
                                          }
                                          queType={question.questionType}
                                          id={"mcq" + index + question._id}
                                          name={question._id}
                                          value={index}
                                          question-id={question._id}
                                          onChange={handleInputValue}
                                          marks={question.questionMarks}
                                          index-value={index1}
                                        />
                                        <label
                                          htmlFor={"mcq" + index + question._id}
                                          className="css-label lite-green-check"
                                        >
                                          {ReactHtmlParser(answerOption)}
                                        </label>
                                      </div>
                                    )
                                  )
                                : null}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {question.questionType == "match" ? (
                      <div className="exam-paper">
                        <div className="mb-3">
                          <div className="q-heading">
                            <h5>
                              <div className="me-2">{index1 + 1})</div>
                              <div className="question-title">
                                {ReactHtmlParser(question.questionTitle)}
                              </div>
                            </h5>
                            {question.questionMarks == "1" ? (
                              <strong> ({question.questionMarks} mark) </strong>
                            ) : (
                              <strong>
                                {" "}
                                ({question.questionMarks} marks){" "}
                              </strong>
                            )}
                          </div>
                          {question && question.answerOptions.length ? (
                            <div
                              className="q-list match-list row"
                              id={"match" + index1}
                            >
                              {question &&
                              question.answerOptions &&
                              question.answerOptions.length
                                ? question.answerOptions.map(
                                    (answerOption, index) => (
                                      <>
                                        <div className="checkbox-col-md-6 col-md-6 mb-2 d-flex row-rope-1">
                                          <input
                                            class="pin pin-up"
                                            queType={question.questionType}
                                            id={"match" + index + question._id}
                                            name={question._id}
                                            question-id={question._id}
                                            onChange={handleInputValue}
                                            marks={question.questionMarks}
                                            data-index={index}
                                            htmlFor={
                                              "match" + index + question._id
                                            }
                                            defaultValue={ReactHtmlParser(
                                              answerOption.fields
                                            )}
                                            readOnly
                                          />
                                        </div>

                                        <div className="checkbox-col-md-6 col-md-6 mb-2 d-flex row-rope-2">
                                          <select
                                            class={
                                              "pin pin-up reset-match-" +
                                              question._id
                                            }
                                            onChange={setMatchTheFollwing}
                                            data-optionId={index}
                                            data-marks={question.questionMarks}
                                            data-index1={index1}
                                            data-questionId={question._id}
                                          >
                                            <option value={null}>
                                              Select Correct Option
                                            </option>
                                            {question &&
                                            question.answerOptionForDrop &&
                                            question.answerOptionForDrop.length
                                              ? question.answerOptionForDrop.map(
                                                  (answerOption, index5) => (
                                                    <>
                                                      <option>
                                                        {ReactHtmlParser(answerOption)}
                                                      </option>
                                                    </>
                                                  )
                                                )
                                              :
                                              ""
                                              } 
                                          </select>
                                        </div>
                                      </>
                                    )
                                  )
                                : null}
                              <div className="lineBox"></div>
                              <div className="restmatch">
                                <input
                                  type="button"
                                  id={"inputresetbns_" + question._id}
                                  className={
                                    "pl-2 btn-sm btn btn-dark inputresetbns_" +
                                    question._id
                                  }
                                  style={{ display: "none", margin: "5px" }}
                                  value="Reset"
                                  data-questionId={question._id}
                                  onClick={resetMatch}
                                  data-fillBoxCounter={question.fillBoxCounter}
                                  data-placeholder={question.placeHolders}
                                  data-questionmarks={question.questionMarks}
                                  data-resetQuestionIndex={index1}
                                  data-questionTitle={questionTitle[index1]}
                                ></input>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}

                    {question.questionType == "fill" ? (
                      <div
                        className="exam-paper"
                        id={"fillBox_" + question._id}
                      >
                        <div className="mb-5">
                          <div className="q-heading">
                            <h5>
                              <div className="me-2">{index1 + 1})</div>
                              <div className="question-title">
                                Fill in the blanks
                              </div>
                            </h5>
                            {question.questionMarks == "1" ? (
                              <strong> ({question.questionMarks} mark) </strong>
                            ) : (
                              <strong>
                                {" "}
                                ({question.questionMarks} marks){" "}
                              </strong>
                            )}
                          </div>

                          <div className="d-flex align-items-start">
                            <div
                              className="drg-content"
                              id={"placeHoldersBox_" + question._id}
                            >
                              {question &&
                              question.placeHolders &&
                              question.placeHolders.length
                                ? question.placeHolders.map(
                                    (placeHolder, index) => (
                                      <span
                                        className="draggable"
                                        id={"drag" + index + question._id}
                                        draggable="true"
                                        onDragStart={drag}
                                        data-target={"setDrop" + question._id}
                                        questionId={question._id}
                                        queType={question.questionType}
                                        marks={question.questionMarks}
                                        data-dragIndex={index}
                                        index-value={index1}
                                        data-placeHolder={placeHolder}
                                      >
                                        {ReactHtmlParser(placeHolder)}
                                      </span>
                                    )
                                  )
                                : null}
                            </div>

                            <input
                              type="button"
                              className={
                                "pl-2 btn-sm btn btn-dark inputresetbns_" +
                                question._id
                              }
                              style={{ display: "none", margin: "5px" }}
                              value="Reset"
                              data-questionId={question._id}
                              onClick={resetFill}
                              data-fillBoxCounter={question.fillBoxCounter}
                              data-placeholder={question.placeHolders}
                              data-questionmarks={question.questionMarks}
                              data-resetQuestionIndex={index1}
                              data-questionTitle={questionTitle[index1]}
                            ></input>
                          </div>

                          <div
                            id="fill"
                            className={
                              "fill-question handelFillBox_" + question._id
                            }
                          >
                            {questionTitle[index1] &&
                            questionTitle[index1].length
                              ? questionTitle[index1].map((quesTitle, index) =>
                                  quesTitle == "_____" ? (
                                    <div
                                      data-dropIndex={index}
                                      id={"div" + parseInt(index + 3)}
                                      className="pl-2 pr-2 create-fill-box aabox"                                      
                                      onDrop={drop}
                                      data-accept={"setDrop" + question._id}
                                      title="put it here"
                                      data-fillBoxCounter={
                                        question.fillBoxCounter
                                      }
                                      onDragOver={allowDrop}
                                      data-indexToManage={index1 + 1}
                                      question-id={question._id}
                                      queType={quesTitle.questionType}
                                      onChange={handleInputValue}
                                    ></div>
                                  ) : (
                                    " " + quesTitle + " "
                                  )
                                )
                              : null}
                          </div>

                          <div
                            className="fill-question-hide"
                            style={{ display: "none" }}
                          >
                            {questionTitle[index1] &&
                            questionTitle[index1].length
                              ? questionTitle[index1].map((question, index) =>
                                  question == "_____" ? (
                                    <div
                                      data-dropIndex={index}
                                      id={"div" + parseInt(index + 3)}
                                      data-questionId={question._id}
                                      className={
                                        "pl-2 pr-2 create-fill-box aabox"
                                      }
                                      onDrop={drop}
                                      title="put it here"
                                      data-fillBoxCounter={
                                        question.fillBoxCounter
                                      }
                                      data-indexToManage={index1 + 1}
                                      onDragOver={allowDrop}
                                      question-id={question._id}
                                      queType={question.questionType}
                                      onChange={handleInputValue}
                                    ></div>
                                  ) : (
                                    " " + question + " "
                                  )
                                )
                              : null}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {question.questionType == "image" ? (
                      <div className="exam-paper">
                        <div className="q-heading">
                          <h5>
                            <div className="me-2">{index1 + 1}</div>
                            <div className="question-title">
                              {ReactHtmlParser(question.questionTitle)}
                            </div>
                          </h5>
                          {question.questionMarks == "1" ? (
                            <strong> ({question.questionMarks} mark) </strong>
                          ) : (
                            <strong> ({question.questionMarks} marks) </strong>
                          )}
                        </div>
                        <div id="div0" className="hide"></div>
                        <div
                          className="pic-indicate"
                          style={{ textALign: "center" }}
                        >
                          <div id="handleaddedBox">
                            <div id={"imageHandler_" + question._id}>
                              <img
                                data-count="0"
                                src={question.imagePath}
                                id={"imageId_" + question._id}
                                className="image img-fluid"
                                index-value={index1}
                              />
                            </div>

                            {question && question.answerOptions.length ? (
                              <div
                                className="q-list match-list row"
                                id={"image" + index1}
                              >
                                <div
                                  className="q-list deafult-input row"
                                  data-marks={question.questionMarks}
                                  data-index1={index1}
                                  data-questionId={question._id}
                                  queType={question.questionType}
                                >
                                  {question &&
                                  question.answerOptions &&
                                  question.answerOptions.length
                                    ? question.answerOptions.map((inv, imv) => (
                                        <>
                                          <input
                                            type="text"
                                            id={question._id}
                                            name={question._id}
                                            placeholder={
                                              "Write Point Value " +
                                              (parseInt(imv) + 1)
                                            }
                                            data-id={imv}
                                            className={
                                              "col-md-4 dynamic_value_img_" +
                                              question._id
                                            }
                                            index-value={index1}
                                            question-id={question._id}
                                            queType={question.questionType}
                                            marks={question.questionMarks}
                                            onChange={handleInputValue}
                                          />
                                        </>
                                      ))
                                    : null}
                                </div>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ))
              ) : (
                <div className="oopsImage">
                  <img src="/assets/img/oops-content.svg" alt="Ooop Img" />
                  <h1>Practice Question not available</h1>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {questions && questions && questions.length ? (
        <div className="pre-next-btn-row">
          <button className="bck-btn" type="button" onClick={handleBack}>
            <i className="fas fa-long-arrow-alt-left"></i>
            <span> Back </span>
          </button>
          <button className="nxt-btn" type="button" onClick={handleFinish}>
            <span> Finish </span>
            <i className="fas fa-long-arrow-alt-right"></i>
          </button>
        </div>
      ) : (
        <div className="pre-next-btn-row">
          <button className="bck-btn" type="button" onClick={handleBack}>
            <i className="fas fa-long-arrow-alt-left"></i>
            <span> Back </span>
          </button>
        </div>
      )}
    </div>
  );
};

export default injectModels(["demo"])(NewPracticeQuestion);
