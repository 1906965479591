import React, { useState, Fragment } from "react";
import { Routes } from "../../constants";
import { Link, useHistory } from "react-router-dom";
import { Header, FooterInner } from "../includes";
import { ChatContent, ChatSearch, ChatListItem, ChatMsgForm} from "./";
import {InputGroup, InputGroupText, Input,Form,Row,Col,Label,Button,Modal,ModalHeader, ModalFooter} from "reactstrap";

const Chat = () => {
    const history = useHistory();
    const handelBack = e => {
      history.goBack();
    }
    return (
      <div>
        <Header/>
         <div>
          <div id="main" className="InnerClass inner-page-main">
            <div className="container">
              <div className="row justify-content-center ">
               <div className="app-drawer-content col-xl-10">
                  <div className="card bx-shadow">
                    <div className="chat-sec">
                  {/*  <span className="bg-primary show-left-part text-white d-block d-lg-none ">
                      <i className= "fas fa-chevron-left"></i>
                    </span> */}
                    <ChatContent />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 text-center mt-2 mt-xl-3">
                    <button className="bck-btn" type="button" onClick={handelBack}>
                      <i className="fas fa-long-arrow-alt-left"></i>
                      <span> Back </span>                        
                    </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterInner />
      </div>
    )
}

export default Chat;
