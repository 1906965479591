import React from 'react';

const ManualReport = () => {
    return (
        <div>
        <h1>Hello</h1>
                    </div>
    );
};

export default ManualReport;