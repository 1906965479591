import React, { useState } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { Header } from "../includes";
const Congratulation = () => {
    return (
      <div>
        <Header/>
         <div>
          <div id="main" className="InnerClass inner-page-main">
            <div className="container">
              <div className="row justify-content-center ">
                <div className = " col-lg-10 col-md-10 "> 
                    <div className = "notify-row card p-3 bx-shadow mb-3">
                      <div className="sub-content ">
                        <div class="checkmark-circle">
                          <div class="background"></div>
                          <div class="checkmark draw"></div>
                        </div>
                        <h1>Congratulations!</h1>
                        <p>You are all set. Well done!</p>
                      </div>
                   </div> 
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Congratulation;
