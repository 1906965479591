import React, { useState } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { Header } from "../includes";

const Faq = () => {

    return <div className="">
        <Header/>
        <div className="auth-wrapper d-flex no-block justify-content-center align-items-center">
            <div className="auth-box on-sidebar">
                <div id="loginform">
                    <div className="logo">
                        <h5 className="font-medium mb-3">Faq Page</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default Faq;
