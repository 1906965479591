import {
    GET_COUNT,
    GET_NOTIFICATION,
    UPDATE_STATUS,
} from './constants';

import { NotificationApi } from '../../../services';
import { JWT } from '../../../helpers';

export const getCount = () => async (dispatch, getState) => {
    const result = await NotificationApi.getCount();
   
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_COUNT, payload: result.data});
            return result;
        } else {
            dispatch({ type: GET_COUNT, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getNotification = () => async (dispatch, getState) => {
    const result = await NotificationApi.getNotification();
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_NOTIFICATION, payload: result.data});
            return result;
        } else {
            dispatch({ type: GET_NOTIFICATION, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const updateStatus = (params = {}) => async (dispatch, getState) => {
    const result = await NotificationApi.updateStatus();
   
    if (result.data) {
        if (result.success) {
            dispatch({ type: UPDATE_STATUS, payload: result.data});
            return result;
        } else {
            dispatch({ type: UPDATE_STATUS, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

