import { store } from '../../Store';
import {login, logout, register, forgot} from './action';

const AuthObj = {
    login: (params={}) => store.dispatch(login(params)),
    logout: () => store.dispatch(logout()),
    register: (params={}) => store.dispatch(register(params)),
    forgot:(params={}) => store.dispatch(forgot(params)),
}

export default AuthObj;
