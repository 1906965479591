import {
    GET_USERS,
    GET_CHAT,
    GET_CHAT_COUNTER,
    SEND_CHAT,
    SAVE_IDS,
    SEND_CHAT_WITH_FILE
} from './constants';

import { ChatApi } from '../../../services';
import { JWT } from '../../../helpers';

export const getUsers = () => async (dispatch, getState) => {
    const result = await ChatApi.getUsers();
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_USERS, payload: result.data});
            return result;
        } else {
            dispatch({ type: GET_USERS, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const getChat = (params = {}) => async (dispatch, getState) => {
    const result = await ChatApi.getChat(params);
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_CHAT, payload: result.data});
            return result;
        } else {
            dispatch({ type: GET_CHAT, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}


export const getChatCounter = (params = {}) => async (dispatch, getState) => {
    const result = await ChatApi.getChatCounter(params);
    if (result.data) {
        if (result.success) {            
            dispatch({ type: GET_CHAT_COUNTER, payload: (result.data[0].count) ? result.data[0].count : 0});
            return result;
        } else {
            dispatch({ type: GET_CHAT_COUNTER, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}

//

export const sendChat = (params = {}) => async (dispatch, getState) => {
    const result = await ChatApi.sendChat(params);
    if (result.data) {
        if (result.success) {
            dispatch({ type: SEND_CHAT, payload: result.data});
            return result;
        } else {
            dispatch({ type: SEND_CHAT, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}
export const sendChatWithFile = (params = {}) => async (dispatch, getState) => {
    const result = await ChatApi.sendChatWithFile(params);
    if (result.data) {
        if (result.success) {
            dispatch({ type: SEND_CHAT_WITH_FILE, payload: result.data});
            return result;
        } else {
            dispatch({ type: SEND_CHAT_WITH_FILE, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}




export const saveIds = (data) => async(dispatch, getState) => {

    if(data){
        dispatch({type:SAVE_IDS,payload:data});
        return data;
    }else{
        return Promise.reject("Api error");
    }
    
} 
