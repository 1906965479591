import React, { Component } from "react";

import { PATHS, API_PATHS } from '../../constants';
import {SERVER_PATH} from '../../constants'

type Props = {};

class Editor extends Component<Props> {

  constructor(props) {    
    super(props);
    let name = props.name;
    let value = props.value;

      this.state = {
        value: props.value,
        data: props.data,
      }

    this.componentDidMount = this.componentDidMount.bind(this);
  }

  render() {
    return (
      <textarea name={this.props.name} id={this.props.name}  className={this.props.className} cols="100" rows="6" defaultValue={this.state.data} config={this.props.config} onInit={this.props.onInit} >{this.props.value}</textarea>
    )
  }

  componentDidMount() {

    const name = this.props.name;

    const inputHandler = (event, editor) => {
        
        console.log("test test ",editor.getData());
       
        // Define your onSubmit function here
        // ...
        // for example, setData() here

    };
    

    let ED = window.CKEDITOR;

    let mathElements = [
          'math',
          'maction',
          'maligngroup',
          'malignmark',
          'menclose',
          'merror',
          'mfenced',
          'mfrac',
          'mglyph',
          'mi',
          'mlabeledtr',
          'mlongdiv',
          'mmultiscripts',
          'mn',
          'mo',
          'mover',
          'mpadded',
          'mphantom',
          'mroot',
          'mrow',
          'ms',
          'mscarries',
          'mscarry',
          'msgroup',
          'msline',
          'mspace',
          'msqrt',
          'msrow',
          'mstack',
          'mstyle',
          'msub',
          'msup',
          'msubsup',
          'mtable',
          'mtd',
          'mtext',
          'mtr',
          'munder',
          'munderover',
          'semantics',
          'annotation',
          'annotation-xml'
      ];

    // ED.plugins.addExternal( 'ckeditor_wiris',  'plugins/ckeditor_wiris/', 'plugin.js' );
    // ED.plugins.addExternal( 'ckeditor_wiris',  'http://localhost/mathType_ckEditor/ckeditor/plugins/ckeditor_wiris/', 'plugin.js' );
    ED.plugins.addExternal('ckeditor_wiris', 'https://www.wiris.net/demo/plugins/ckeditor/', 'plugin.js');  
    //      extraPlugins: 'colorbutton,colordialog'

    ED.replace(name, {
        extraPlugins: 'ckeditor_wiris',
        // For now, MathType is incompatible with CKEditor file upload plugins.
        // Update the ACF configuration with MathML syntax.
        //removePlugins: 'uploadimage,uploadwidget,uploadfile,filetools,filebrowser',
        height: 320,
        extraAllowedContent: mathElements.join(' ') + '(*)[*]{*};img[data-mathml,data-custom-editor,role](Wirisformula)'
    } );

      ED.config.uploadUrl = "https://api.lpi-tuition.com/api/v1/uploadfile";      
      ED.config.filebrowserUploadUrl = "https://api.lpi-tuition.com/api/v1/uploadfile";      
      ED.config.autoParagraph = false;
      
      ED.config.removeButtons =  'Form,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,BidiLtr,BidiRtl,Language,Styles,Font,Smiley,CreateDiv,Checkbox,Scayt,NewPage,About,Image,Table,Flash,InsertHorizontalLine,InsertSpecialCharacter,IFrame,Maximize';
      ED.config.toolbarCanCollapse = true;


      ED.config.format_tags = 'p;h1;h2;h3;div;pre';
      
      ED.config.format_h1 = {
        element: 'h1',
        attributes: {
          'class': 'title-content'
        }
      };

      ED.config.format_h2 = {
        element: 'h2',
        attributes: {
          'class': 'title-content'
        }
      };

      ED.config.format_h3 = {
        element: 'h3',
        attributes: {
          'class': 'title-content'
        }
      };
      ED.config.format_div = {
        element: 'div',
        attributes: {
          'class': 'title-content'
        }
      };


      ED.config.format_pre = {
        element: 'pre',
        attributes: {
          'class': 'title-content'
        }
      };

      /*ckfinder: {
        uploadUrl: "https://lpi-tuition.projectstatus.in/api/v1/uploadfile",
      }, filebrowserUploadUrl:"https://lpi-tuition.projectstatus.in/api/v1/uploadfile" */

    /*ED.config({
      uploadUrl: API_PATHS.IMAGE_UPLOAD,
      filebrowserUploadUrl: API_PATHS.IMAGE_UPLOAD
    })*/
   

   ED.onChange={inputHandler}

  }
}

export default Editor;
