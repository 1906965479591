import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link, useLocation, useParams  } from "react-router-dom";
import { Redirect } from 'react-router';
import {injectModels} from '../../redux/injectModels';
import { getCount } from "../../redux/models/NotificationApi/action";
import { JWT } from '../../helpers';

const NotFoundHeader = (props) => {
    const location = useLocation();
    const [count, setCount] = useState("");
    const accessToken = JWT.getJWT();
    let urlID = window.location.hash;
  
    useEffect(() => {
          if(props.auth.isLoggedIn){
            if(!props.notificationApi.count){
              props.notificationApi.getCount();
            }else{
              props.notificationApi.getCount();
              setCount(props.notificationApi.count)
            }
          }
        }, [props.auth.isLoggedIn, props.notificationApi.count]);
    return(
        <header id="header" className={(props.auth.isLoggedIn && location.pathname === "/") || props.auth.isLoggedIn == false ? '' : 'inner-page-header'}>
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <div className={(props.auth.isLoggedIn && location.pathname === "/") || props.auth.isLoggedIn == false ? 'logo' : 'logo small-logo'}>        
                 <Link to="/" className="pos-rel">
                  <img src="/assets/img/lpi-logo.svg" alt="" className="img-fluid"/>          
                </Link>         
              </div>
              
              {/*<div>*/}
                {/*<div className="tp-bar mb-0" >
                    {props.auth.isLoggedIn ?
                     <div className="profile-sec">
                      <span className="me-2">Welcome {props.auth.firstName}</span>
                      <a href="/notification" className="notify-pointer"><i className="fas fa-bell"></i><div id="notification-count">{count ? count : 0}</div></a>
                      <div className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fas fa-user"></i>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
                          <li><Link className="dropdown-item" to={Routes.EDIT_PROFILE}>Edit Profile</Link></li>
                          <li><Link className="dropdown-item" to={Routes.CHANGE_PASSWORD}>Change Password</Link></li>
                        </ul>

                      </div>
                      {props.auth.isLoggedIn ? <Link to={Routes.REGISTER} className="register-btn me-1 ms-2">Dashboard</Link>: ""}
                    </div> :
                    ""}

                    
                    {props.auth.isLoggedIn ? <a href="/" className="login-btn me-0 ms-2" onClick={(e)=>props.auth.logout()}>Logout</a> : <Link to={Routes.LOGIN} className="login-btn me-0 ms-2">Login</Link>}
                    {props.auth.isLoggedIn ? "" : <Link to={Routes.REGISTER} className="register-btn me-1 ms-2">Register</Link>}                    
                    <button className="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>*/}
               {/* <div className="collapse navbar-collapse" id="navbarNavDropdown">
                  <ul className="navbar-nav" id="navbar">
                    {(props.auth.isLoggedIn && location.pathname === "/") || props.auth.isLoggedIn == false ? <li className="nav-item"><a className={urlID === "" || urlID === "#home" ? 'scrollto active' : 'scrollto'} href="#home">Home</a></li> : ""}
                    {(props.auth.isLoggedIn && location.pathname === "/") || props.auth.isLoggedIn == false ? <li className="nav-item"><a className={urlID === "#about" ? 'scrollto active' : 'scrollto'} href="#about">About Us</a></li> : ""}           
                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item"><a className={urlID === "#plus11" ? 'scrollto active' : 'scrollto'} href="#plus11">11+</a></li>: ""} 
                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item"><a className={urlID === "#ks3" ? 'scrollto active' : 'scrollto'} href="#ks3">KS3</a></li>: ""} 
                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item"><a className={urlID === "#gcse" ? 'scrollto active' : 'scrollto'} href="#gcse">GCSE</a></li>: ""} 
                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item"><a className={urlID === "#workshops" ? 'scrollto active' : 'scrollto'} href="#workshops">Workshops</a></li>: ""}             
                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item"><a className={urlID === "#pricing" ? 'scrollto active' : 'scrollto'} href="#pricing">Pricing</a></li> : ""}            
                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item"><a className={urlID === "#contact" ? 'scrollto active' : 'scrollto'} href="#contact">Contact Us</a></li>: ""} 
                  </ul>
                </div>*/}
                
             {/* </div>*/}
              
            </div>
          </nav>
        </header>
    );
};

export default injectModels(['auth', 'userApi', 'notificationApi'])(NotFoundHeader);

