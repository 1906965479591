import React, { useState ,useRef,useEffect } from "react";
import { Form, Input } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { injectModels } from '../../redux/injectModels';
import { CKEditor } from 'ckeditor4-react';
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CKEditor1 from '../include';
import { SERVER_PATH } from "../../constants";

const ChatMsgForm = (props) => {
    const containerRef = useRef(null);
    const [message, setMessage] = useState("");
    const [messageError, setMessageError] = useState("");
    //
    const [currentFile, setCurrentFile] = useState("");
    const [progress, setProgress] = useState(0);
    const [previewImage, setPreviewImage] = useState([]);
    const [errorMessageImg, setErrorMessageImg] = useState("");
    const [messageWithFile, setMessageWithFile] = useState(false);
    const  messageEndRef = useRef(null);
    const myRef = useRef(null)
  
const scrollBottom = ()=>{
    return messageEndRef.current ?.scrollIntoView()
}

    const handleMsg = e => {
        e.preventDefault();
        // const data = {
        //     message: message,
        //     imagePath:currentFile
        // }
        
        if (message == "" && currentFile == "") {
            // error message show
            setErrorMessageImg("Please select file")


        } else if ((currentFile != "" && message != "") || message != "") {
            setCurrentFile("");


            // send file with massge
            const formdataposted = new FormData();
            formdataposted.append('message', message);
            formdataposted.append('imagePath', currentFile);

           // let isForm = true;
            
            if (messageWithFile == 1) {                
                props.chatApi.sendChatWithFile(formdataposted)
                setPreviewImage('');
                setMessageWithFile("");
                setCurrentFile("");
                setMessage("");
            } else {
                const data = {
                    message: message,
                    //imagePath: ""
                }
                props.chatApi.sendChat(data)
                setMessage("");
                //setMessageWithFile("");
                setTimeout(() => {
                    props.chatApi.getChat();
                }, 1000);

                setTimeout(() => {                    
                    document.getElementById('lastKey').scrollIntoView({
                        behavior: 'smooth'
                      });
                     
                }, 2000);
            }
        } else {
            setMessageError("Please enter message");
        }
    }

    const handleChange = (e)=> {
        let sendData = e.target.value;        
        setMessage(sendData);
        setMessageError("");
    }

    const selectFile = (event) => {
        event.preventDefault();
        setCurrentFile(event.target.files[0]);
        setErrorMessageImg("");
        let questionObjs = { 'imagefile': event.target.files[0], "xvalues": [], "yvalues": [], "optionvalues": [] };

        setPreviewImage(URL.createObjectURL(event.target.files[0]));
        img = new Image();
        img.src = URL.createObjectURL(event.target.files[0]);

        img.onload = function () {
            let widht = this.width;
            let height = this.height;
            if (widht >= 512 || height > 512) {
                setPreviewImage("");
                //setErrorMessageImg("Please select a proper image image is not greater than 512*512px");
            }
        };
        setProgress(0);
        setMessageWithFile(1)
        var img = document.getElementById('imageid');
    }

    return (
        <div className="cps-2">
            <Form className="card-body border-top h-100" id="chatForm">
                {/* <div className="row">
                    <div className="col-8">
                        <label className="btn btn-default p-0">
                            Attached file
                        </label>
                    </div>
                    <div className="col-8">
                        <input type='file' multiple accept="image/*,.jpg,.jpeg.,.gif,.png,.mov,.mp4" onChange={selectFile}
                        />
                    </div>
                </div> */}

                {/* {currentFile && (
                    <div className="progress my-3">
                        <div
                            className="progress-bar progress-bar-info progress-bar-striped"
                            role="progressbar"
                            aria-valuenow={progress}
                            aria-valuemin="0"
                            aria-valuemax="100"
                            style={{ width: progress + "%" }}
                        >
                            {progress}%
                        </div>
                    </div>
                )}
                {previewImage && (
                    <>
                        <div>
                            <img data-count="0" src={previewImage} alt="" />
                        </div><div id="lessonBottom"></div>
                    </>

                )}
                {errorMessageImg && <span className="error">{errorMessageImg}</span>} */}

                <div className="cf-editor">
                    <Input
                        type="text"
                        className="form-control mr-2"
                        placeholder="Type your message"
                        onChange={handleChange}
                        value={message}
                        required
                    />
                </div>

                {messageError && <span className="error">{messageError}</span>}

                <div className="mt-3 d-flex justify-content-end">
                    <button
                        className="success-btn ps-5 pe-5 handeleevent"
                        type="submit"
                       
                        onClick={handleMsg}
                    >
                        Send
                    </button>
                    
                </div>

                <div ref={messageEndRef}></div>

            </Form>
            
        </div>
    );
}

export default injectModels(['chatApi', 'auth'])(ChatMsgForm);


