import { store } from '../../Store';
import {getUsers,getChat,sendChat,saveIds,sendChatWithFile,getChatCounter } from './action';

const actions = {
    getUsers: () => store.dispatch(getUsers()),
    getChat: (params={}) => store.dispatch(getChat(params)),
    getChatCounter: () => store.dispatch(getChatCounter()),
    sendChat:(params={}) => store.dispatch(sendChat(params)),
    sendChatWithFile:(params={}) => store.dispatch(sendChatWithFile(params)),
    saveIds:(data) => store.dispatch(saveIds(data)),
}

export default actions;