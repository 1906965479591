import React, { useState } from "react";
import { Routes } from "../../constants";
import { Link, useHistory } from "react-router-dom";
import { Header } from "../includes";
import InputGroupAddon from 'bee-input-group-addon';
//import "/node_modules/font-awesome/css/font-awesome.css";
import {InputGroup, InputGroupText, Input,Form,Row,Col,Label,Button,} from "reactstrap";
import {injectModels} from '../../redux/injectModels';
import {Auth} from '../../services';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const EmailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ForgotPassword = (props) => {
 
 const [email, setEmail]=useState('');
 const [emailError,setEmailError]=useState(''); 
 const [success, setSuccess] = useState('');
 const [error, setError] = useState('');
 const [successMessage, setSuccessMessage] = useState('');
 const history = useHistory();

const handleEmail = e => {
e.preventDefault();
let inputValue = e.target.value;
  if(inputValue === "") {
     setEmailError("Email is Required");      
  } else if( EmailRegx.test(email) === false){
    setEmailError("Enter a valid email");
  }else{
     setEmailError("");
  }
   setEmail(inputValue.trimStart());
}

const handelBack = e => {
        history.goBack();
    }
  
const handleSubmit = (e) =>{

  e.preventDefault();
  let error =  false;

  if(email === "") {
    error = true;
    setEmailError("Please enter Email");
   } else if( EmailRegx.test(email) === false){
    error = true;
    setEmailError("Enter a valid email");
  }
   else{
    setEmailError("");
 }
 if(error){
   return false;
 }

        let data = {email:email};
        Auth.ForgotPassword(data)
           .then((response)=>{
            
        if(response.success) {
         
      setError("");
      toast.success(response.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setEmail('')
         setEmailError('');
          } else {
             setSuccess("");
              setError(response.message);
              toast.error(response.message, {
                position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
              });
                 }
             }).catch((error)=>{
          setError(error);
      setSuccess("");
  });  
}

  return (
    <main id="" className="login-page">  
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-start pt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-3">
                <Link to="/" className="login-logo d-flex align-items-center w-auto">
                  <img src="/assets/img/lpi-logo.svg" alt="" className="img-fluid"/>
                </Link>
              </div>{/* End Logo */}

              <div className="section-title2 mb-0 pb-0">
                <h2 className="text-white">Forgot Password</h2>              
              </div>
              <ToastContainer />
              <div className="card mb-4 login-form">

                <div className="card-body">              

                  <form className="row g-3 needs-validation" onSubmit ={handleSubmit} id="loginform" action="/dashbaord">
                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">Email</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend"><i className="fas fa-user"></i></span>
                        <input type="email" name="email" className="form-control" id="email" value={email} onChange={handleEmail}/>
                      </div>
                      <div>{emailError && <span className="invalid-feedback">{emailError}</span>}</div>

                    </div>

                    <div className="col-12">
                      <button className="btn green-btn w-100" type="submit">Reset Password</button>
                    </div>
                  </form>

                </div>
              </div>

              <div className="col-12">
                <div className="pre-next-btn-row  justify-content-center">
                  <button className="org-btn rounded-pill px-4 pr-2 pl-2 mr-2 ml-2" type="button" onClick={handelBack}>
                      <i className="fas fa-long-arrow-alt-left"></i>
                      <span> Back </span>                        
                  </button>   
                </div>           
              </div>

              <div className="text-center" style={{marginTop:"10px"}}>
                <p className="text-white small">© Copyright LPI Tuition Ltd. All Rights Reserved</p>
              </div>


             
            </div>
          </div>
        </div>

      </section>
    </main>
    
  );
};

export default injectModels(['auth'])(ForgotPassword);
