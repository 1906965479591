import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { JWT } from '../helpers';

const GetChapter = async(subjectId) => {
    try{
    	const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/subjects/get-all-chapter`, subjectId, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;
            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const ChapterApi = {
    GetChapter,
}

export {ChapterApi};