import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { JWT } from '../helpers';

const getTopic = async(chapterId) => {
    try{
    	const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/subjects/get-all-topics`, chapterId, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {             
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getSubjectHomeWork = async(data) => {
    try{
    	const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/get-regular-homework`, data,
        {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});    
        if(response.status === 200) {
            return response.data;            
        } else {             
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}



const getSubjectHomeWorkDetails = async(testId ) => {
    try{
    	const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/get-homework-details`, {testId },
        {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});    
        if(response.status === 200) {
            return response.data;            
        } else {             
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getSingleTopic = async(topicId) => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/subjects/get-single-topic`, topicId, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {             
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getQuestion = async(topicId) => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/get-topic-test`, topicId, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}
// const getQuestion = async(topicId) => {
//   
//     try{
//         const accessToken = JWT.getJWT();
//         const response = await axios.post(`${API_ENDPOINT}/papers/get-paper-details`, topicId, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
//        
//         if(response.status === 200) {
//             return response.data;
            
//         } else {
//              
//             return Promise.reject("An api error was encountered");
//         }
//     } catch(error) {
//         return Promise.reject(error);
//     }  
// }

const getSingleQuestion = async(topicId) => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/questions/getone`, topicId, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const submitPaper = async(data) => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/update-user-test`, data, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getTestDetail = async(data) => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/get-test-details`, data, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getReport = async(subjectId) => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/get-subject-result`, subjectId, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {             
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getComments = async() => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/get-student-comments`,{"Authorization": `Bearer ${accessToken}`},{headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;           
        } else {             
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getAllDemoData = async() => {
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.get(`${API_ENDPOINT}/cms-page/get-demopage`,{"Authorization": `Bearer ${accessToken}`},{headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {             
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}


const submitDemoPaper = async(data) => {   
    try{
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/update-demo-test`,data,{"Authorization": `Bearer ${accessToken}`},{headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;         
        } else {
            return Promise.reject("An api error was encountered");
        }      
    } catch(error) {
        return Promise.reject(error);
    }  
}

const getClassResult = async (data) => {
    try {
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/usertests/get-all-results  `,data,
            { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` } });
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch (error) {
        return Promise.reject(error);
    }
}
const TopicApi = {
    getTopic,
    getSingleTopic,
    getQuestion,
    getSingleQuestion,
    submitPaper,
    getTestDetail,
    getReport,
    getComments,
    getAllDemoData,
    getClassResult,
    submitDemoPaper,
    getSubjectHomeWork,
    getSubjectHomeWorkDetails
}
export {TopicApi};