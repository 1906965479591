import React, { useState, useEffect, useRef } from "react";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { Routes } from "../../constants";
import { Link, useHistory, useParams } from "react-router-dom";
import InputGroupAddon from 'bee-input-group-addon';
import { InputGroup, InputGroupText, Input, Form, Row, Col, Label, Button, } from "reactstrap";
import { Header, Loader, FooterInner } from "../includes";
import { injectModels } from '../../redux/injectModels';
import { getSubject } from "../../redux/models/SubjectApi/action";
import { getUser } from "../../redux/models/UserApi/action";
import { getReport } from "../../redux/models/TopicApi/action";
import { TopicApi } from "../../services";
import moment, { max } from 'moment';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const Report = (props) => {
    const studentId = localStorage.getItem("studentId");
    //alert(props.location.state.selectedSubject);
    
    const [dynamicColor, setDynamicColor] = useState("");
    const [color, setColor] = useState("");
    const [subjects, setSubjects] = useState("");
    const [classId, setClassId] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [idLi, setIdLi] = useState("");
    const [error, setError] = useState([]);
    const [comments, setComments] = useState("");
    const [selectedSubject, setSelectedSubject] = useState("");
    const [selectedSubjectColor, setSelectedSubjectColor] = useState("");
    const [attempts , setAttempts] = useState('');
    const [showCompleteResult,setShowCompleteResult] = useState("");
    const [subjectListData,setSubjectListData] = useState("");
    const [subjectNamesToTable, setSubjectNamesToTable] = useState("");

    let { id } = useParams();

    useEffect(() => {       
        if (!props.subjectApi.items.data) {
            const data = { studentClass: id };
            props.subjectApi.getSubject(data);
        } else {
            setDynamicColor(localStorage.getItem(dynamicColor));
            setSubjects(props.subjectApi.items.data);

            let subjectListArray =[]
            let subjectStr = '';          
            let i = 1;
         for(const subject of props.subjectApi.items.data){
            subjectListArray.push(subject._id);
            subjectStr += (props.subjectApi.items.data.length != i ) ?  subject.subjectName.trim().toLowerCase().replace(" ", "_")+"," :
             subject.subjectName.trim().toLowerCase().replace(" ", "_");
            i = i + 1;
          }
              setSubjectNamesToTable(subjectStr);
              setSubjectListData(subjectListArray);             
                TopicApi.getClassResult({
                    subjectIds: subjectListArray ,
                    selectedUserId :studentId,         
                })
                .then((response) => {      
                      const data = response.data;    
                      if (response.success) {
                        setShowCompleteResult(response.data)                              
                      } else {
                        setError(data.message);
                      }
                    }).catch((err) => {
                      setError("Network error");
                    })
                
            if (props.subjectApi.items.data && props.subjectApi.items.data.length >= 0) {
                setIdLi(props.subjectApi.items.data[0]._id);
                setColor(props.subjectApi.items.data[0].subjectColour);
                const data = {
                    subjectId: props.subjectApi.items.data[0]._id,
                };
                document.getElementById("reportTitle").style.display = "inline-flex";
                setSelectedSubjectColor(props.subjectApi.items.data[0].subjectColour)
                setSelectedSubject(props.subjectApi.items.data[0].subjectName);
                props.topicApi.getReport(data);
            }
        }

        if (!props.topicApi.comments) {
            props.topicApi.getComments();
        } else {
            setComments(props.topicApi.comments);
        }
    }, [props.subjectApi.items.data, props.topicApi.comments]);


    const handelBack = e => {
        props.history.push("/dashboard");
    }

    const handleClick = e => {
        setIdLi(e.target.getAttribute("id"));
        setColor(e.target.getAttribute("color"));
        setIsActive(true);
        const data = {
            subjectId: e.target.getAttribute("id"),
        };
        let subjectColor = e.target.getAttribute("data-subjectColor");
        setSelectedSubjectColor(subjectColor)
        let subjectName = e.target.textContent;
        setSelectedSubject(subjectName);
        props.topicApi.getReport(data);
    }

    const handleShowResult = (e) => {
        let manualPercantage = e.target.getAttribute("data-percantage")
        let manualDate = e.target.getAttribute("data-date")
        let paperId = e.target.getAttribute("data-id")
        let reportType = e.target.getAttribute("data-reportType");
        let topicTitle = e.target.getAttribute("data-topicTitle");

        if (reportType == "manually") {
            props.history.push({
                pathname: `/manual-result`,
                state: {
                    isDateShow: true,
                    percantage: manualPercantage,
                    manualDate: manualDate,
                    topicTitle: topicTitle,
                    selectedSubject: selectedSubject,
                }
            });
        } else {
            props.history.push({
                pathname: `/result/${paperId}`,
                state: { isDateShow: true, selectedSubject: selectedSubject, }
            });
        }
    }

    const updateSubjectColor = (color, subjectId) => {
        let subjectClassElement = document.getElementsByClassName("li-subject_" + subjectId);
        if (subjectClassElement.length > 0) {
            subjectClassElement[0].style.setProperty('--borderLeft', color);
        }
        return "ss manish"
    }

   

    const handleDownLoadReport = () => {                
        let maxData = "";
        let maxAttempts = 0;
        let attempt = props.topicApi.report.data.map((val,key)=>{
            if(maxAttempts <  val.attempts){
                maxAttempts = val.attempts;
            }
        })
        let indexs = 0;
        let resultArrays = props.topicApi.report.data;
        for(const a of resultArrays){
            if(a.attemptsRowData)
            {
            let lengths = a.attemptsRowData.length;
                while(lengths < maxAttempts){      
                    // resultArrays[indexs].attemptsRowData[lengths] = {
                    //     _id: null
                    // }
                    lengths = lengths+1;
                }            
                indexs = indexs + 1;
            }
        }
        props.history.push({
            pathname: `/downLoad-report/${id}`,
            subject: selectedSubject,
            topic: resultArrays,
            maxAttempts : maxAttempts
        });
    }

    return (
        <div>
            <Header />
            <div id="main" className="InnerClass inner-page-main">
                <div className="container">
                <ReactHTMLTableToExcel
                          id="test-table-xls-button"
                          className="btn btn-success rounded-pill download-all-result-btn"
                          table="table-to-xlss"
                          filename="Complete Report"
                          sheet="Complete Report"                          
                          buttonText="Export Complete Report"
                        />  
                  <div style={{display : "none"}}>
                  <div className='col-md-12'>
                  <div className="card p-2 rp-2 brdr-none " >
                    <div className='table-responsive'> 
                    <table id="table-to-xlss"> 
                               <tr>
                                 <td>                                    
                               <div className='table-responsive'>                                
                                 <table className="table table-striped tableColor mb-0 bg-white h-tbl">                                   
                             <thead>
                               <tr className='showstudent' style={{cursor:"pointer"}}>
                                 <th scope="col"></th>
                                 <th scope="col">Percentage</th>
                               </tr>
                             </thead>
                             <tbody>
                               {
                                showCompleteResult && showCompleteResult.results && showCompleteResult.results.length ? showCompleteResult.results.map((subject) => (
                                   <>
                                     <td><h3>{subject.subjectName}</h3>
                                     </td>
                                     {subject.resultsData && subject.resultsData.length ? subject.resultsData.map((topic, index) =>(
                                       <>
                                       <tr>
                                       <td>{topic.topicTitle}</td>
                                       {topic.results && topic.results.length ? topic.results.map((score)=>(
                                         <>
                                           <td style= {(score.averageScore <= 50)
                                              ?
                                              { color: "red", fontWeight: "800", cursor: "pointer" }
                                              : (score.averageScore >= 50.01 && score.averageScore <= 69)
                                                ? { color: "#f1d50a", fontWeight: "800", cursor: "pointer" }
                                                : { color: "green", fontWeight: "800", cursor: "pointer" }}>
                                                {score.averageScore ? score.averageScore + "%" : ""}</td>
                                         </>
                                     )):""}
                                       </tr>                                  
                                       </>
                                     )) : ""} 
                                   </>
                                 )) : null
                               } 
                             </tbody>
                           </table>                                                       
                               </div>
                               </td>
                               </tr>     
                               
    {comments && comments.data && comments.data.length ?
    <div className="rp-section">       
        <div className="rp-2 rp-small">
            <div className="report-card p-3 p-md-4 mb-2 mb-md-5">
                <table  class="table mb-0 gen-td">                
                <h2>Teacher's Comments</h2>
                    <thead>
                        <tr className="table-header-report" >                            
                                <th scope="col">Topic</th>
                            <th scope="col" className="date">Date</th>
                            <th scope="col">Comment</th>
                        </tr>
                    </thead>
                    <tbody>
                        {comments ? comments.data.map((comment, index) => (
                            comment.topicTitle == "General" ?
                                <tr className="table-header-report black-border" data-type={comment.topicTitle} >
                            <td className="com">{comment.topicTitle}</td>
                                    <td className="date">{moment(comment.created).format('DD-MM-YYYY')}
                                    </td>
                                    <td className="comment">{ReactHtmlParser(comment.comment)}</td>
                                </tr>
                                : <tr className="table-header-report black-border" data-type={comment.topicTitle} >
                                    <td className="com">{comment.topicTitle}</td> 
                                    <td className="date">{moment(comment.created).format('DD-MM-YYYY')}
                                    </td>
                                    <td className="comment">{ReactHtmlParser(comment.comment)}</td>
                                </tr>
                        )) : ""}
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    :
    null}
    </table>
    </div>
    </div>
    </div>                             
                  </div>
                    <div className="row justify-content-center">
                        <div className="col col-xl-12 mt-2 mt-md-5">
                            <div className="rp-section">
                                <ul className="testStatus report-ul rp-1">
                                    {!subjects ? <Loader /> : subjects && subjects.length ? subjects.map((subject) => (
                                        <li className={subject._id == idLi ? "activeLi li-subject_" + subject._id : "li-subject_" + subject._id} data-updateSubjectColor={updateSubjectColor(subject.subjectColour, subject._id)} key={subject._id} id={subject._id} data-subjectColor={subject.subjectColour} style={{ backgroundColor: subject.subjectColour }} onClick={handleClick}>{subject.subjectName}</li>
                                    ))
                                        : null}
                                </ul>
                                <div className="rp-2 rp-small">
                                    <div className="report-card p-3 p-md-4 mb-2 mb-md-5">
                                        <div className="download-report">
                                            <button type="button" title="View Complete Report" onClick={handleDownLoadReport} class="btn btn-success" >
                                                <i class="fas fa-download"></i></button>
                                        </div>                                        
                                        <div className="ls-heading mb-3">
                                            <h2 id="reportTitle" title={selectedSubject + " Report"} style={{ backgroundColor: selectedSubjectColor, display: "none" }}>{selectedSubject + " Report"} </h2>
                                        </div>
                                        <div className="table-responsive">
                                            {!props.topicApi.report ? <Loader /> :
                                                <table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col y-label">Topic</th>
                                                            <th scope="col pnk-label">Attempt 1</th>
                                                            <th scope="col blu-label">Attempt 2</th>
                                                            <th scope="col org-labal">Attempt 3</th>
                                                            <th scope="col pup-labal">Last Attempt</th>
                                                            <th scope="col pup-labal">Average</th>
                                                        </tr>
                                                    </thead>                                                    
                                                    <tbody>
                                                        {props.topicApi && props.topicApi.report && props.topicApi.report.data.length ? props.topicApi.report.data.map((value, index) => (
                                                            <tr>
                                                                <th>{value.topicTitle}</th>
                                                                {(value.lastIndexData) ? 
                                                                <>
                                                                {value && value.attemptsRowData && value.attemptsRowData.length &&  value.attemptsRowData.length  ? value.attemptsRowData.map((val, ind) => (
                                                                  ind <= "2" ?   
                                                                 <td data-id={val.userTestId}
                                                                 data-index={val.attemptsRowData}
                                                                  data-reportType={val.reportType}
                                                                   data-percantage={val.parsentage}
                                                                    data-date={val.created}
                                                                    data-topicTitle={value.topicTitle}
                                                                     onClick={handleShowResult}
                                                                        style={(val.parsentage <= 50) ? { color: "red", fontWeight: "800", cursor: "pointer" } : (val.parsentage >= 50.01 && val.parsentage <= 69) ? { color: "#f1d50a", fontWeight: "800", cursor: "pointer" } : { color: "green", fontWeight: "800", cursor: "pointer" }}>
                                                                        {(val.parsentage && (val.parsentage)) ? val.parsentage.toFixed(2) : "0"}%</td>
                                                                        :""
                                                                )) :
                                                                    ""}
                                                                {value && value.attemptsRowData && value.attemptsRowData.length == 1 ? <>                                                                
                                                                    <td></td>
                                                                    <td></td>
                                                                </>
                                                                    : ""}
                                                                {value && value.attemptsRowData && value.attemptsRowData.length == 2 ?
                                                                    <>
                                                                        <td></td>
                                                                    </>
                                                                    : ""}                                                                    
                                                                </>
                                                                : 
                                                                <>
                                                                    <td>  </td>
                                                                    <td>  </td>
                                                                    <td>  </td>
                                                                </>
                                                                }                                                                
                                                                {(value.lastIndexData) ? 
                                                                <>
                                                                <td data-topicTitle={value.topicTitle} data-id={value.lastIndexData[0].userTestId} data-reportType={value.lastIndexData[0].reportType}
                                                                    data-percantage={value.lastIndexData[0].parsentage} data-date={value.lastIndexData[0].created} onClick={handleShowResult}
                                                                    style={(value.lastIndexData[0].parsentage <= 50) ? { color: "red", fontWeight: "800", cursor: "pointer" } : (value.lastIndexData[0].parsentage >= 50.01 && value.lastIndexData[0].parsentage <= 69) ? { color: "#f1d50a", fontWeight: "800", cursor: "pointer" } : { color: "green", fontWeight: "800", cursor: "pointer" }}>
                                                                        {(
                                                                        parseFloat(value.lastIndexData[0].parsentage.toFixed(2))) ? value.lastIndexData[0].parsentage.toFixed(2) : "0"}%
                                                                </td>
                                                                <td
                                                                 style={(value.averageScore <= 50) ? 
                                                                 { color: "red", fontWeight: "800", cursor: "pointer" } 
                                                                 : (value.averageScore >= 50.01 && value.averageScore <= 69) ? { color: "#f1d50a", fontWeight: "800", cursor: "pointer" } : { color: "green", fontWeight: "800", cursor: "pointer" }}>
                                                               {(parseFloat(value.averageScore)) ? value.averageScore.toFixed(2) : "0"}%
                                                                 </td>
                                                                </>
                                                                :  
                                                                <>
                                                                    <td>  </td>
                                                                    <td>Not Attempted </td>
                                                                    </>
                                                                }
                                                            </tr>
                                                        )) : ""}
                                                    </tbody>
                                                </table>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {comments && comments.data && comments.data.length ?
                            <div className="rp-section">
                                <ul className="testStatus report-ul rp-1">
                                </ul>
                                <div className="rp-2 rp-small">
                                    <div className="report-card p-3 p-md-4 mb-2 mb-md-5">
                                        <div className="ls-heading mb-3">
                                            <h2>Teacher's Comments</h2>
                                        </div>
                                        <div className="cmt" >
                                         <ReactHTMLTableToExcel
                                            id="test-table-xls-button"
                                            className="download-table-xls-button btn btn-success mb-3"
                                            table="table-to-xls"
                                            filename="comment"
                                            sheet="Comment's"                          
                                            buttonText="Export Comment's"
                                            />
                                            </div>

                                        <table id="table-to-xls" class="table mb-0 gen-td">
                                            <thead>
                                                <tr className="table-header-report" >
                                                    {/* <th scope="col">Subject</th> */}
                                                        <th scope="col">Topic</th>
                                                    <th scope="col" className="date-col">Date</th>
                                                    <th scope="col">Comment</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {comments ? comments.data.map((comment, index) => (
                                                    comment.topicTitle == "General" ?
                                                        <tr className="table-header-report black-border" data-type={comment.topicTitle} >
                                                            {/* <td >{comment.subjectTitle}</td> */}
                                                    <td className="com">{comment.topicTitle}</td>

                                                            <td className="date-col com">{moment(comment.created).format('DD-MM-YYYY')}
                                                            </td>
                                                            <td className="comment-col">{ReactHtmlParser(comment.comment)}</td>
                                                        </tr>
                                                        : <tr className="table-header-report black-border" data-type={comment.topicTitle} >
                                                            {/* <td >{comment.subjectTitle}</td>*/}
                                                            <td className="com">{comment.topicTitle}</td> 

                                                            <td className="date-col com">{moment(comment.created).format('DD-MM-YYYY')}
                                                            </td>
                                                            <td className="comment-col">{ReactHtmlParser(comment.comment)}</td>
                                                        </tr>
                                                )) : ""}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            :
                            null}
                        <div className="col-md-12 text-center mt-3 mt-xl-4 pt-3 pt-xl-4 mb-2">
                            <button className="bck-btn px-2" type="button" onClick={handelBack}>
                                <i className="fas fa-long-arrow-alt-left"></i>
                                <span> Back </span>
                            </button>                            
                        </div>
                    </div>
                </div>                
            </div>
            <FooterInner />
        </div>
    );
}
export default injectModels(['auth', 'subjectApi', 'userApi', 'topicApi'])(Report);
