import React, { useState, useEffect, useCallback } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { Header,Loader,Paginations ,FooterInner } from "../includes";
import {injectModels} from '../../redux/injectModels';
import ReactHtmlParser from 'react-html-parser';
import { getCount, getNotification } from "../../redux/models/NotificationApi/action";
import moment from 'moment';

const Notification = (props) => {
    const [notification, setNotification] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [notificationIdArray, setNotificationIdArray] = useState([]);
    const notificationArray = [];
    let NUM_OF_RECORDS = notification.length;
    let LIMIT = 5;
    
    useEffect(() => {
      if(!props.notificationApi.notification){
          props.notificationApi.getNotification();
      } else {
        setNotification(props.notificationApi.notification)
      }
    }, [props.notificationApi.notification]);

    const currentData = notification.slice(
      (currentPage - 1) * LIMIT,
      (currentPage - 1) * LIMIT + LIMIT
    );

    const onPageChanged = useCallback((event, page) => {
      event.preventDefault();
        setCurrentPage(page);
      },[setCurrentPage]);

    return (
      <div>
        <Header/>
         <div>
          <div id="main" className="InnerClass inner-page-main">
            <div className="container">
              <div className="row justify-content-center ">
                <div className = " col-lg-10 col-md-10 ">
                  {props.notificationApi.isLoading && !notification  ? <Loader/> :
                   currentData && currentData.length ? currentData.map((notify,index) => (
                    <div className = "notify-row card p-3 bx-shadow mb-3 border-left-warning" key={notify._id}>
                     <div className="not-box" key={notify._id} >
                        <div className = "sub-letter">{ReactHtmlParser(notify.title.toUpperCase().charAt(0))}</div>
                        <div className="sub-content">
                          <div className = "sc-heading">{ReactHtmlParser(notify.title)}</div>
                          <div className = "sc-text">{ReactHtmlParser(notify.content)}</div>
                          <div className = "sc-created">{moment(notify.created).format('DD-MM-YYYY')}</div>
                        </div>
                     </div>
                  </div> )): null
                  }

                   { notification && notification.length> LIMIT ?
                   <div className="pagination-wrapper lpi-pagging">
                    <Paginations
                      totalRecords={NUM_OF_RECORDS}                      
                      pageLimit={LIMIT}
                      pageNeighbours={2}
                      onPageChanged={onPageChanged}
                      currentPage={currentPage}
                    />
                  </div>:
                  null
                }
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterInner />
      </div>
    )
}
export default injectModels(['auth', 'userApi', 'notificationApi'])(Notification);
