import { GET_ALL_DEMO_DATA} from './constant';

const intialState = {
    items:[],
    singleItem:null,
    total:0,
    id:null,
    isLoading:true,
    question:null,
    singleQuestion:null,
    report:null,
    comment:null,
}

export default (state=intialState, action) => {
    switch(action.type){
        case GET_ALL_DEMO_DATA :
            return {
                ...state,
                items:action.payload,
                isLoading:false,
            }
        default :
            return state;    
    }
}