import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { Routes } from './constants';
import { JWT } from './helpers';

const AuthenticRoute = ({component:Component, ...rest}) => {
     return <Route {...rest} render={(routeProps)=>{
         return JWT.isLoggedIn() ? <Component {...routeProps} /> : <Redirect to={Routes.LOGIN}/>
     }} />
     
}


export {AuthenticRoute}