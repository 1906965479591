import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link,useHistory, useParams } from "react-router-dom";
import InputGroupAddon from 'bee-input-group-addon';
import {InputGroup, InputGroupText, Input,Form,Row,Col,Label,Button,} from "reactstrap";
import {ChapterApi, SubjectApi, TopicApi} from '../../services';
import { getTopic } from "../../redux/models/TopicApi/action";
import { Header,Loader ,FooterInner } from "../includes";
import {injectModels} from '../../redux/injectModels';
import ScrollArea from "react-scrollbar";
import ReactHtmlParser from 'react-html-parser';
import HtmlParser from "react-html-parser";

const Topic = (props) => {
    //console.log("props",props)

    const [dynamicColor, setDynamicColor] = useState("");
    const [topics, setTopics] = useState("");


    let { id } = useParams();
    const history = useHistory();
    const data = {chapterId:id};

    const dispatch = useDispatch();

    // useEffect(() => {
    //   setDynamicColor(localStorage.getItem(dynamicColor));
    //   dispatch(getTopic(data));
    // }, [dispatch]);

    // const topics = useSelector((state) => state.topicApi.items.data);
    useEffect(() => {

        setDynamicColor(localStorage.getItem(dynamicColor));
        const resultApi = props.topicApi.getTopic(data);
       // console.log("data",data)
        resultApi.then((result)=>{            
            if(result){
                if(result.success == true){                      
                   setTopics(result.data);
                }else{
                    setTopics(result.data);
                }
            }
        });
    }, []);
    
    if(topics && topics.length === 0){
        let hidebox = document.getElementsByClassName("oopsImage");
        if(hidebox.length > 0){
            document.getElementsByClassName("oopsImage")[0].style.display = "flex";
        }
    }

    const handelBack = e => {
        history.goBack();
    }

    const handleTopic = e =>{
        props.history.push({
            pathname: `/topic-detail/${id}/${ e.target.getAttribute("value")}`,
            chapterId: id,
        });
      //  props.history.push(`/topic/${ e.target.id}`);
    }

    function setTime(seconds =""){
        // Hours, minutes and seconds
        var hrs = ~~(seconds / 3600);
        var mins = ~~((seconds % 3600) / 60);
        var secs = ~~seconds % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        var ret = "";

        if (hrs > 0) {
            ret += "" + hrs + " hour " + (mins < 10 ? "0" : "");
        }
        
        if(mins > 0){
            ret += "" + mins + " min " + (secs < 10 ? "0" : "");
        }

        if(secs > 0){
            ret += "" + secs+ " sec";
        }
        return ret;
        
    }

    
    // function setTime(seconds = ""){
    //      var h = Math.floor(seconds / 3600),
    //          m = Math.floor(seconds / 60) % 60,
    //          s = seconds % 60;
    //          if (h < 10) h = "0" + h;
    //         if (m < 10) m = "0" + m;
    //         if (s < 10) s = "0" + s;
    //         return + h +":" + m + ":" + s; 
    // }    
    // function addScroll(){
   
    // }


    const getReportTime = (reportData) => {

        if((reportData && reportData.length > 0) &&  reportData[0].reportType =="manually"){
            return "<span > Manual Type </span>";
        }else if((reportData && reportData.length > 0) &&  reportData[0].reportType !== "manually"){
            
            return '<img src="../assets/img/sand-clock.png" style="width:"20px"" />'+(setTime(reportData[0].averageCompleteTime));
        }else{
            
            return "00:00";
        }
    }

// console.log("props.location.state.subject",props.location.state.chapterName)

    return (
        <div>
            <Header/>
            <div id="main" className="InnerClass inner-page-main2">
                <div className="container">
                    <div className="row justify-content-center">
                     <div className="col col-xl-12 mt-5">
                     <div className="rp-2 rp-small"> 
                        <div className="report-card p-3 p-xxl-4">
                        <div className="ls-heading mb-3">
                        <h2 style={{ backgroundColor:dynamicColor}}>{props.location.state.subject} - {props.location.state.chapterName}</h2>
                          {/* <h2>{props.location.state.subject} - {props.location.state.chapterName}</h2> */}
                          

                        </div>
                        
                        { !topics  ? <Loader/> :
                                topics && topics.length > 0 ? 
                                <ScrollArea
                                    speed={0.8}
                                    className="scroll-div"
                                    contentClassName="content"
                                    horizontal={false}
                                >
                                <div id="scroll-div" className="table-responsive">
                                <table className= {`table mb-0 reportTable tbl-${props.location.state.subject}`} >
                                <thead>
                                    <tr>
                                          <th scope="col" style={{textAlign:'center'}}>Completed</th>
                                          <th scope="col">Topic</th>
                                          <th scope="col">Avg. Result</th>
                                          <th scope="col">Time</th>
                                    </tr>             
                                </thead> 
                               <tbody>

                                
                                {!topics  ? <Loader/> :
                                    
                                    topics && topics.length > 0  ? topics.map((topic,index) => (
                                        <>
                                    <tr key={topic._id} className="cursorPointer">
                                      <td style={{textAlign:'center'}}>{topic.result && topic.result.length ? <img src="../assets/img/green-tick.jpg" className="small-img" /> : <img src="../assets/img/red-tick.jpg" className="small-img" />}</td>
                                      <td onClick={handleTopic} value={topic._id}>{topic.topicTitle}</td>
                                      <td>
                                        {topic && topic.result && topic.result.length
                                        ? 
                                            topic.result.map((res, index) => 
                                                (parseFloat(res.averageScore).toFixed(2)) ? (parseFloat(res.averageScore).toFixed(2)) : 0
                                            )

                                        : 0}%

                                       </td>

                                        <td>
                                            { ReactHtmlParser(getReportTime(topic.result))}
                                        </td>
                                       
                                    </tr>
                                    </>
                                )) : ""
                                }
                               
                               </tbody>
                                </table></div>
                                  </ScrollArea>
                                   :
                                    <div className="oopsImage" style={{display:"flex"}}>
                                    <img src="/assets/img/oops-content.svg" alt="Ooops Img"/>
                                    <h1>Topic not found</h1>
                                </div>                                
                                }
                         
                            </div>

                           {/* <div className="cstm-table">
                                {topics && topics.length > 0 ?

                                <table className= {`reportTable tbl-${props.location.state.subject}`}>
                                   <tbody>
                                    <tr style={{textAlign:'center',backgroundColor: dynamicColor}}>
                                      <th style={{textAlign:'center'}}>Completed</th>
                                      <th>Topic</th>
                                      <th>Avg. Result</th>
                                      <th>Time</th>
                                    </tr>
                                    {topics && topics.length > 0 ? topics.map((topic,index) => (
                                        <tr key={topic._id} className="cursorPointer" >
                                          <td style={{textAlign:'center'}}>{topic.result && topic.result.length ? <img src="../assets/img/green-tick.jpg" style={{width:'25px'}} /> : <img src="../assets/img/red-tick.jpg" style={{width:'25px'}} />}</td>
                                          <td onClick={handleTopic} value={topic._id}>{topic.topicTitle}</td>
                                          <td>{topic && topic.result && topic.result.length
                                           ? topic.result.map((res, index) => (res.averageScore.toFixed(2)
                                            )) : 0}%</td>
                                          <td><img src="../assets/img/sand-clock.png" style={{width:'20px'}} />{topic && topic.result && topic.result.length ? topic.result.map((res, index) => (setTime(res.averageCompleteTime.toFixed(0)))) : "00:00:00"}</td>
                                        </tr>
                                    )) : ""
                                    }
                                   </tbody>
                                </table> : <div className="oopsImage">
                                    <img src="/assets/img/oops-content.svg" alt="Ooops Img"/>
                                    <h1>Topic not found</h1>
                                </div>}
                            </div>*/}

                        </div>
                        </div>
                        <div className="col-md-12 text-center mt-2 mt-xl-3">
                            <button className="bck-btn px-2 bac-btn" type="button" onClick={handelBack}>
                                <i className="fas fa-long-arrow-alt-left"></i>
                                <span> Back </span>                        
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <FooterInner />
        </div>
    );
}

export default injectModels(['chapterApi', 'subjectApi', 'topicApi'])(Topic);