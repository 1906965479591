import React, { useState, useEffect } from "react";
import { Routes } from "../../constants";
import { Link, useParams, useHistory } from "react-router-dom";
import { Header, FooterInner } from "../includes";
import { TopicDetail, PracticeQuestion, HomeWork, Lesson } from "../topic";
import InputGroupAddon from 'bee-input-group-addon';
import {injectModels} from '../../redux/injectModels';
import {InputGroup, InputGroupText, Input,Form,Row,Col,Label,Button,Modal,ModalHeader, ModalFooter} from "reactstrap";

const Topic = (props) => {
    const [change, setChange] = useState(true); 
    const [title, setTitle] = useState("Lesson"); 
    const [dynamicColor, setDynamicColor] = useState("");
    const history = useHistory();
    let { chapterId, topicId } = useParams();

    useEffect(() => {      
      setDynamicColor(localStorage.getItem(dynamicColor));
    }, []);

    const handleLesson = (e) => {
        setChange(true)
        setTitle(e.target.innerText)
    }

    const handlePracticeQuestion = (e) => {
        setChange(true)
        setTitle(e.target.innerText)
    }

    const handleHomeWork = (e) => {
        setChange(true)
        setTitle(e.target.innerText)
    }

    const handelBack = e => {
        history.goBack();
    }

    return (
         <div>
            <Header/>
            <div id="main" className="InnerClass inner-page-main">
                <div className="container">
                    <Row>
                        <div className="col col-xl-12 mt-0">
                            <div className="rp-section topic-tabs">
                                <ul className="testStatus rp-1">
                                    <li className={title == "Lesson" ? "activeLi testStatusGood m1 yy-pencil" : "testStatusGood m1 yy-pencil"} id={topicId} onClick ={handleLesson}>Lesson</li>
                                    <li className={title == "Practice Questions" ? "activeLi testStatusGood m2 newClass1 sg-pencil" : "testStatusGood m2 newClass1 sg-pencil"} id={topicId} onClick ={handlePracticeQuestion}>Practice Questions</li>
                                    <li className={title == "Homework" ? "activeLi testStatusGood m2 newClass1 org-pencil" : "testStatusGood m2 newClass1 org-pencil"} id={topicId} onClick ={handleHomeWork}>Homework</li>
                                </ul>
                            

                            
                                <div className="rp-2 tpd-m-h">
                                    {change && title == 'Lesson'
                                    ? 
                                    <Lesson topicID={topicId} chapterId={chapterId} topicTitle={title} color={dynamicColor}></Lesson>
                                    : 
                                    ""}

                                    {change && title == 'Practice Questions'
                                    ? 
                                   <PracticeQuestion topicID={topicId} type="practisequestion" color={dynamicColor}/>
                                    : 
                                    ""}

                                    {change && title == 'Homework'
                                    ? 
                                    <HomeWork topicID={topicId} type="homework" color={dynamicColor}/>
                                    : 
                                    ""}
                                </div>
                            </div>
                        </div>
                    </Row>
                    {/*<div className="col-md-12 text-center mt-3 mt-xl-4 pt-3 pt-xl-4">
                        <Button
                          className="org-btn rounded-pill px-4" onClick={handelBack}
                        >
                          <i className="fas fa-long-arrow-alt-left"></i><span className="ms-2">Back</span>
                        </Button>      

                    </div> */}                
                    </div>
            </div>
            <FooterInner />
        </div>
    );
}

export default injectModels(['chapterApi', 'subjectApi', 'topicApi'])(Topic);
