import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import { Routes } from './constants';
import { JWT } from './helpers';

const NonAuthenticRoute = ({component:Component, ...rest}) => {
     return <Route {...rest} render={(routeProps)=>{
         return JWT.isLoggedIn() ? <Redirect to={Routes.DASHBOARD}/> : <Component {...routeProps} />
     }} />
}


export {NonAuthenticRoute}