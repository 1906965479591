import {
    GET_SUBJECT,
    UPDATE_MARKER
} from './constants';

import { SubjectApi } from '../../../services';
import { JWT } from '../../../helpers';

export const getSubject = (item = {}) => async (dispatch, getState) => {
    const result = await SubjectApi.GetSubject(item);
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_SUBJECT, payload: result});
            return result;
        } else {
            dispatch({ type: GET_SUBJECT, payload:null});
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}

export const updateMarker = (item = {}) => async (dispatch, getState) => {
    const result = await SubjectApi.UpdateMarker(item);
    
    if (result.data) {
        if (result.success) {
            dispatch({ type: UPDATE_MARKER, payload: result});
            return result;
        } else {
            dispatch({ type: UPDATE_MARKER, payload:null});
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}