
import Auth from './Auth';
import SubjectApi from './SubjectApi';
import ChapterApi from './ChapterApi';
import TopicApi from './TopicApi';
import UserApi from './UserApi';
import ChatApi from './ChatApi';
import NotificationApi from './NotificationApi';
import Demo from './Demo'

const models = {
  
    auth:Auth,
    subjectApi:SubjectApi,
    chapterApi:ChapterApi,
    topicApi:TopicApi,
    userApi:UserApi,
    chatApi:ChatApi,
    notificationApi:NotificationApi,
    demo:Demo
   
}

export default models;