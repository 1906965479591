import React, { useState } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
const FooterInner = () => {
    return(
        <div className="dash-footer">
          

        </div>

    );
}

export default FooterInner;
