import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Header,Footer, FooterInner } from "../includes";
import InputGroupAddon from 'bee-input-group-addon';
import {Auth} from '../../services';
import {injectModels} from '../../redux/injectModels';
//import "/node_modules/font-awesome/css/font-awesome.css";
import {InputGroup,InputGroupText,Input,Form,Row,Col,Label,Button} from "reactstrap";
import axios from 'axios';
import { API_ENDPOINT } from '../../constants';
import {UserApi,StudentClassApi} from '../../services';
import { getUser} from "../../redux/models/UserApi/action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
const ChangePassword = (props) => {
    const [userId, setUserId] = useState("");
    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [currentPasswordError, setCurrentPasswordError] = useState('');
    const [newPasswordError,setNewPasswordError] = useState('');
    const [confirmPasswordError,setConfirmPasswordError] = useState('');
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if(!props.userApi.user){
            props.userApi.getUser();
        } else {
            const {user} = props.userApi;
            setUserId(user._id);
        }

    }, [props.userApi.user]);

    const handleCurrentPassword = e =>{ 
        e.preventDefault();
        let inputValue = e.target.value;
        if (inputValue === "") {
          setCurrentPasswordError("Current Password is required");
        }else{
          setCurrentPasswordError("");
        }
        setCurrentPassword(inputValue.trimStart());
    }

    const handelBack = e => {
      props.history.push(Routes.DASHBOARD);
    }

    const handleNewPassword = e =>{ 
        e.preventDefault();
        let inputValue = e.target.value;
        if (inputValue === "") {
          setNewPasswordError("New Password is required");
        }else if(inputValue.length<6){
            setNewPasswordError("Password not be less than 6 character");
        }else if(confirmPassword && confirmPassword.length && inputValue != confirmPassword){
            setNewPasswordError("New password is not equal to confirm password");
        } else{
          setNewPasswordError("");
        }
        setNewPassword(inputValue.trimStart());
    }

    const handleConfirmPassword = e =>{ 
        e.preventDefault();
        let inputValue = e.target.value;
        if (inputValue === "") {
          setConfirmPasswordError("Confirm Password is required");
        }else if(inputValue.length<6){
            setConfirmPasswordError("Confirm Password not be less than 6 character");
        }else if(inputValue != newPassword){
            setConfirmPasswordError("Confirm password is not equal to new password");
        } else{
          setConfirmPasswordError("");
        }
         setConfirmPassword(inputValue.trimStart());
    }

    const handleSubmit = e =>{
    e.preventDefault();
    let error = false;

    if(currentPassword === ""){
        error = true;
        setCurrentPasswordError("Current Password is required");
    }else if(newPassword === ""){
        error = true;
        setNewPasswordError("New Password is required");
    }else if(newPassword.length<6){
            error = true;
            setNewPasswordError("Password not be less than 6 character");
    }else if(confirmPassword === ""){
        error = true;
        setConfirmPasswordError("Confirm Password is required");
    }else if(confirmPassword.length<6){
            error = true;
            setConfirmPasswordError("Confirm Password not be less than 6 character");
    }else if(newPassword != confirmPassword){
        error = true;
        setConfirmPasswordError("Confirm password is not equal to new password");
    }else {
        setNewPasswordError("");
        setCurrentPasswordError("");
        setConfirmPasswordError("");
    }
    if(error){
       return false;
    }else{
      const data = {
          current_password:currentPassword,
          new_password:newPassword,
      };
      UserApi.changePassword(data)
        .then((response)=>{
        if(response.success) {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          document.getElementById("ChangePasswordForm").reset();

        } else {
          toast.error(response.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          document.getElementById("ChangePasswordForm").reset();
        }
        }).catch((error)=>{
          setError("Enter Correct Data");
          setSuccess('');
        }); 
      }
    }

    return (
     <div>
      <Header/>  
        <div id="main" className="InnerClass inner-page-main">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column align-items-center justify-content-center">
                <ToastContainer />
                <div className="section-title2 mb-0 pb-0">
                  <h2 className="">Change Password</h2>              
                </div>

                <div className="card mb-4 login-form">
                  <div className="card-body ">
                    <form className="row g-3 needs-validation" onSubmit = {handleSubmit} id="ChangePasswordForm">
                     
                    <div className="col-12">
                     <label htmlFor="yourPassword" className="form-label">Current Password</label>
                     <div className="input-group has-validation">
                       <span className="input-group-text"><i className="fas fa-lock"></i></span>
                       <input type="password" className="form-control" id="current_password" name="current_password" onChange={handleCurrentPassword}autoComplete="off"/>
                       {currentPasswordError && <div className="invalid-feedback">{currentPasswordError}</div>}
                     </div>
                    </div>

                    <div className="col-12">
                     <label htmlFor="yourPassword" className="form-label">New Password</label>
                     <div className="input-group has-validation">
                       <span className="input-group-text"><i className="fas fa-lock"></i></span>
                       <input type="password" className="form-control" id="new_password" name="new_password" onChange={handleNewPassword} autoComplete="off"/>
                       {newPasswordError && <div className="invalid-feedback">{newPasswordError}</div>}
                     </div>
                    </div>

                    <div className="col-12">
                     <label htmlFor="yourPassword" className="form-label">Confirm Password</label>
                     <div className="input-group has-validation">
                       <span className="input-group-text"><i className="fas fa-lock"></i></span>
                       <input type="password" className="form-control" id="confirm_password" name="confirm_password" onChange={handleConfirmPassword} autoComplete="off"/>
                       {confirmPasswordError && <div className="invalid-feedback">{confirmPasswordError}</div>}
                     </div>
                    </div>
                     
                      <div className="col-12">
                        <div className="row justify-content-center">
                          <div className="col-12 col-md-6">
                            <button className="btn green-btn w-100" type="submit">Change Password</button>
                          </div>
                        </div>                    
                      </div>
                    </form>
                  </div>
                </div>

                <div className="pre-next-btn-row justify-content-center" >
                    <button className="bck-btn px-2" type="button" onClick={handelBack}>
                        <i className="fas fa-long-arrow-alt-left"></i>
                        <span> Back </span>                        
                    </button>
                </div>  

              </div>
            </div>
          </div>

        </div>
        <FooterInner/>
      </div>
  );
}

export default injectModels(['auth','userApi'])(ChangePassword);
