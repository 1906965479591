import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Header,Footer } from "../includes";
import InputGroupAddon from 'bee-input-group-addon';
import {Auth, UserApi} from '../../services';
import { getUser} from "../../redux/models/UserApi/action";
import {injectModels} from '../../redux/injectModels';
//import "/node_modules/font-awesome/css/font-awesome.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getCount } from "../../redux/models/NotificationApi/action";
import {
  InputGroup,
  // InputGroupAddon,
  InputGroupText,
  Input,
  Form,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";

const EmailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState('');

  const dispatch = useDispatch();
  const dateYear = new Date();
  useEffect(() => {
      if(props.location.state && props.location.state.showToast){ 
            toast.success("Your account registered successfully, please verify your email address.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
      })
      }
    }, []);
  const handleEmail = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if(inputValue === "") {
      setEmailError("Email is required");      
    } else if( EmailRegx.test(email) === false){
      setEmailError("");
    } else{
      setEmailError("");
    }
      setEmail(inputValue.trimStart());
    }


    const handlePassword = e => {
      e.preventDefault();
      let inputValue = e.target.value;
      if(inputValue === "") {
        setPasswordError("Password is Required");      
      }else{
        setPasswordError("");
      }
      setPassword(inputValue.trimStart());
    }

    const handleSubmit = e => {
      e.preventDefault();
      let error = false;
      if(email === "") {
        error = true;
         setEmailError("Email is required");
      } else if( EmailRegx.test(email) === false){
        error = true;
        setEmailError("Enter a valid email");
      }else{
        setEmailError("");
      }
      if(password === "") {
        error = true;
      setPasswordError("Password is required");
      } else {
        setPasswordError("");
     } 
     if(error){
      return false;
     }
     
     const data = {email:email, password:password};
     const resultApi = props.auth.login(data);
           resultApi.then((result)=>{
            localStorage.setItem("studentId", result.data.id);
               props.notificationApi.getCount();
               props.history.push(Routes.DASHBOARD);
             }).catch((error)=>{
               setError(error); 
           });
     }
     const resetForm = () => {
      setError("");
      setEmail(""); 
      setEmailError("");
      setPassword("");
      setPasswordError("");
    }
        
    return (
    <main id="" className="login-page">  
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-start pt-4 pb-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-3">
                <Link to="/" className="login-logo d-flex align-items-center w-auto">
                    <img src="/assets/img/lpi-logo.svg" alt="" className="img-fluid"/>
                  </Link>
              </div>{/* End Logo */}
              <ToastContainer />
              <div className="section-title2 mb-0 pb-0">
                <h2 className="text-white">Login to Your Account</h2>              
              </div>

              <div className="card mb-4 login-form">

                <div className="card-body">              

                  <form className="row g-3 needs-validation" onSubmit ={handleSubmit} id="loginform">
                    <div className="col-12">
                      <label htmlFor="yourUsername" className="form-label">Email</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend"><i className="fas fa-user"></i></span>
                        <input type="email" name="email" className="form-control" id="email" value={email} onChange={handleEmail}/>
                      </div>
                      <div>{emailError && <span className="invalid-feedback">{emailError}</span>}</div>

                    </div>

                    <div className="col-12">
                      <label htmlFor="yourPassword" className="form-label">Password</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend"><i className="fas fa-lock"></i></span>
                        <input type="password" name="password" className="form-control" id="password" value={password} onChange={handlePassword}/>
                      </div>
                      <div>{passwordError && <span className="invalid-feedback">{passwordError}</span>}</div>
                      <div>{error && <span className="invalid-feedback">{error}</span>}</div>
                      
                    </div>

                    <div className="col-12">
                      <div className="d-flex justify-content-between align-items-center">
                        <Link to={Routes.FORGOT_PASSWORD} className="forgot-psw">Forgot Password</Link>
                      </div>                    
                    </div>
                    <div className="col-12">
                      <button className="btn green-btn w-100" type="submit">Login</button>
                    </div>
                    <div className="col-12 text-center">
                      <p className=" mb-0">Don't have an account? <Link to="/auth/register">Create an account</Link></p>
                    </div>
                  </form>

                </div>
              </div>

              <div className="text-center">
                <p className="text-white small">&copy; Copyright <span>LPI Tuition Ltd © {dateYear.getFullYear()}</span>. All Rights Reserved </p>
              </div>

             
            </div>
          </div>
        </div>

      </section>
    </main>
    );
};

export default injectModels(['auth', 'notificationApi'])(Login);
