import React, { useState, useEffect } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Header,Footer } from "../includes";
import InputGroupAddon from 'bee-input-group-addon';
import {Auth} from '../../services';
import {injectModels} from '../../redux/injectModels';
//import "/node_modules/font-awesome/css/font-awesome.css";
import {InputGroup,InputGroupText,Input,Form,Row,Col,Label,Button} from "reactstrap";
import axios from 'axios';
import { API_ENDPOINT } from '../../constants';

const Verification = (props) => {
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [status, setStatus] = useState('');
  const token = props.match.params.token;  
  const dateYear = new Date();
  useEffect(() => {
     const data = {
        token:token,
      };
    result(data);
  }, [])
  const result = async(data) => {
    
    try{
        const response = await axios.post(`${API_ENDPOINT}/auth/verify-email`,data, {headers:{"Content-Type":"application/json"}});
        if(response.status === 200) {
          setStatus(response.data.success);
          setSuccess(response.data.message);
          return true;
          
      } else {
        setStatus(response.data.success);
        setError(response.data.message);
          return false;
      }
    } catch(error) {
    
        return Promise.reject(error);
    }  
  }

  return (
    <main id="" className="login-page">  
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-start pt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-3">
                <Link to="/" className="login-logo d-flex align-items-center w-auto">
                  <img src="/assets/img/lpi-logo.svg" alt="" className="img-fluid"/>
                </Link>
              </div>{/* End Logo */}

              <div className="section-title2 mb-0 pb-0">
                <h2 className="text-white">Email Verification</h2>              
              </div>

              <div className="card mb-4 login-form">

                <div className="card-body">   
                {status == true ? 
                  <div className="col-12">
                    <h5>Your account has been verified. Please login to website by clicking on the given link <Link to={Routes.LOGIN} className="forgot-psw">Login</Link> </h5>
                  </div> :
                    <div className="col-12">
                      <h5>{success}</h5>
                    </div>
                }           

                </div>
              </div>

              <div className="text-center">
                <p className="text-white small">&copy; Copyright <span>LPI Tuition Ltd © {dateYear.getFullYear()}</span>. All Rights Reserved </p>
              </div>

             
            </div>
          </div>
        </div>

      </section>
    </main>
  );
};

export default Verification;