import {
    GET_CHAPTER
} from './constants';

import { ChapterApi } from '../../../services';
import { JWT } from '../../../helpers';

export const getChapter = (item = {}) => async (dispatch, getState) => {
    const result = await ChapterApi.GetChapter(item);
   
    if (result.data) {
        if (result.success) {
            dispatch({ type: GET_CHAPTER, payload: result});
            return result;
        } else {
            dispatch({ type: GET_CHAPTER, payload:null});
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}