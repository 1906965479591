import {
    GET_ALL_DEMO_DATA,
    SUBMIT_DEMO_PAPER,
} from './constant';

import { TopicApi } from '../../../services';
import { JWT } from '../../../helpers';

export const getAllDemoData = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.getAllDemoData(item);

    if (result.data) {
        if (result.success) {
            dispatch({ 
                type: GET_ALL_DEMO_DATA, 
                payload: result
            });
            return result;
        } else {
            dispatch({ 
                type: GET_ALL_DEMO_DATA,
                 payload:null
                });
            return Promise.reject(result.message);
        }
    } else {
        return Promise.reject("Api error");
    }
}
export const submitDemoPaper = (item = {}) => async (dispatch, getState) => {
    const result = await TopicApi.submitDemoPaper(item);
   
    if (result) {
        if (result.success) {
            dispatch({
                 type: SUBMIT_DEMO_PAPER, 
                 payload: result.data
                });
            return result;
        } else {
            dispatch({ 
                type: SUBMIT_DEMO_PAPER, 
                payload:null
            });
            return result.message;
        }
    } else {
        return Promise.reject("Api error");
    }
}





