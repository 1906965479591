import React, { useState } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import ScrollArea from "react-scrollbar";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const Footer = (props) => {
  var show = props.show;  
  const subjectDescription = props.subjectDescription;
  const subjectTitle = props.subjectTitle;
  const showVideoStatus = props.showVideoStatus;

  const handleClose = () => {    
    props.onHandleModelClose();
  }

  const dateYear = new Date();

  const handlePageChange = (e) =>{
    let url = e.target.getAttribute("data-url");
  }

  const handleCloseVideo = () => {    
    props.onHandleModelCloseVideo();
  }
  
    return(     
        <footer id="footer">
          <Modal show={showVideoStatus} onHide={handleCloseVideo} onClose={handleCloseVideo} size="xl" className="w-100">
            <Modal.Header closeButton >
              <Modal.Title className="title-content-modal"><strong style={{color:"green"}}> LPI Tuition</strong> <br/>
              <h5 style={{color:"orange"}}>A family-run tuition centre dedicated to making learning enjoyable for your child</h5>
               </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{  height: '660px', position: 'relative', overflow: 'auto' }}>
              <video src="https://api.lpi-tuition.com/videos/banner-video.mp4"  width="100%" height="auto" 
              controls  poster="/assets/img/video-banner.png">
                <source src="https://api.lpi-tuition.com/videos/banner-video.mp4" type="video/mp4" />
                </video>
              </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseVideo} >
                Close
              </Button>
            </Modal.Footer> */}
          </Modal>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title style={{ marginLeft: "90px" }}>{subjectTitle} Description</Modal.Title>
            </Modal.Header>


            <Modal.Body style={{  position: 'relative', overflow: 'auto' }}>
            <ScrollArea
                speed={0.8}                                    
                horizontal={false}
            >
              {ReactHtmlParser(subjectDescription)}
              </ScrollArea>
              
              </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose} >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

            <div className="footer-top">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12 col-md-12 footer-links footer-msg" style={{fontSize : "40px"}}>                  
                    {/*<span>L</span>earn <span>P</span>ractise <span>I</span>mprove*/}
                    <p><b>ENQUIRE NOW - LIMITED SPACES AVAILABLE</b></p>
                  </div>
                  {/*<div className="col-lg-4 col-md-6 footer-links">
                  </div>
                  <div className="col-lg-4 col-md-6 footer-info">
                    </div> */}
                </div>
              </div>
            </div>
            
              <div className="copyright">
                <div className="container">
                  &copy; Copyright <span>LPI Tuition Ltd © {dateYear.getFullYear()}</span>. All Rights Reserved 
                  <span className="privacy">
                  <Link to={Routes.PRIVECY_POLICY} >Privacy Policy</Link>
                  </span>                  
                  <span className="term">                 
                  <Link to={Routes.TERM_CONDITION} >Terms and Conditions </Link>
                  </span>
              </div>
            </div>
          </footer>
    );
}
export default Footer;
