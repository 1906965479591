import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link, useParams, useHistory } from "react-router-dom";
import { Header, Loader, FooterInner} from "../includes";
import {injectModels} from '../../redux/injectModels';
import InputGroupAddon from 'bee-input-group-addon';
import {InputGroup, InputGroupText, Input,Form,Row,Col,Label,Button,} from "reactstrap";
import {ChapterApi, SubjectApi} from '../../services';
import { getChapter } from "../../redux/models/ChapterApi/action";

const ChapterList = (props) => {
    const [dynamicColor, setDynamicColor] = useState("");
    const [chapters, setChapters] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    
    
    const [rgb,setRgb] = useState("");
    const [image,setImage] = useState("");
    const history = useHistory();
    let { id } = useParams();
    const data = {subjectId:id};
    const dispatch = useDispatch();
    
    // useEffect(() => {
    //   setDynamicColor(localStorage.getItem(dynamicColor));
    //   dispatch(getChapter(data));
    //   document.getElementById("contentBox").classList.remove("inner-hide-box");
    //   const imageValue = props.location.state.subject.toLowerCase().replace(" ", "-");
    //   setImage(imageValue)
    // }, [dispatch]);
    // const chapters = useSelector((state) => state.chapterApi.items.data);

    


// change code 31-08-2022
    useEffect(() => {
      setDynamicColor(localStorage.getItem(dynamicColor));
      const imageValue = props.location.state.subject.toLowerCase().replace(" ", "-");
      const resultApi = props.chapterApi.getChapter(data);
      resultApi.then((result)=>{
          if(result){
              if(result.success == true ){  
                setIsLoading(true);
                 setChapters(result.data);
                 setImage(imageValue)
                 document.getElementById("contentBox").classList.remove("inner-hide-box");
              }else{
                setChapters("");
              }
          }
      });
  }, []);


    const hexToRGB = (dynamicColor) => {
      var newstring = dynamicColor.slice(1);
      var aRgbHex = newstring.match(/.{1,2}/g);
      let r = parseInt(aRgbHex[0], 16);
      let g = parseInt(aRgbHex[1], 16);
      let b = parseInt(aRgbHex[2], 16);
      let a = .7;
      return `rgba(${r}, ${g}, ${b}, ${a})`
    }

    if(dynamicColor){
     var transparentColor = hexToRGB(dynamicColor);
    }
  
    const handelBack = e => {
        history.goBack();
    }
    const handleClick = e => {
      let chapterId = e.target.getAttribute("data-chapterId")
     let chapterName= e.target.getAttribute("data-chapterName")

      e.preventDefault();
      const data = {
          subjectId:id,
      };
    
      props.history.push({
        pathname: `/topic/${ e.target.id}`,
        state: { subject: `${props.location.state.subject}`,
        chapter:`${chapterId}`,
        chapterName:`${chapterName}`
      }
      });
    }


    return (
    <div>
    <Header/>
      <div id="main" className="InnerClass inner-page-main2 ">
      <div className="container">
        <div className="row justify-content-center ">
          <div className="col-md-12">
            <div className="in-section-title py-2">
              <h2 style={{color: dynamicColor}}>{props.location.state.subject}</h2>
            </div>
          </div>
          <div className="col-md-11 col-lg-9 col-xxl-9 ks3">
            <Row className="justify-content-center inner-hide-box" id="contentBox">          
               {isLoading && !chapters  ? <Loader/> :
                  chapters && chapters.chapters.length ? chapters.chapters.map((chapter,index) => (
                
                   <div className="col-lg-4 col-md-6 d-flex align-items-stretch mb-3 box-design" key={chapter._id} id={chapter._id} >
                    <div style={{maxHeight:"75%"}} className="yyl-card p-2">
                      <div className="icon-box" style={{backgroundColor: dynamicColor,maxHeight:"100%"}}>
                        <div className="hover-box p-4 visi" style={{backgroundColor: transparentColor}}>
                          <div>
                            <h2 data-length={chapter.chapterName.length} id={chapter._id} class="chapterlistbox-title" title={chapter.chapterName}>
                              {(chapter.chapterName.length > 35) ? chapter.chapterName.substring(0, 35) +" ..."  : chapter.chapterName } 
                            </h2>
                            <p className="mb-2" id={chapter._id}>                         
                              {/* Chapter {index+1} */}
                            </p>
                          </div>
                          {chapter.showMarkStatus == true ? <span className="ex-mark"><i className="fas fa-exclamation"></i></span> : ""}
                          <a key={chapter._id} id={chapter._id}  className={chapter.blockedStatus == "Unlocked" ? "btn-get-started ms-0" : "btn-get-started btn-locked ms-0"} data-chapterId={chapter._id} data-chapterName= {chapter.chapterName} onClick={chapter.blockedStatus == "Unlocked" ? handleClick : null}>
                          <i className={chapter.blockedStatus == "Unlocked" ? "fa fa-unlock-alt white-text" : "fa fa-lock white-text"}></i>
                            <span className="ms-2 unlckd"   data-chapterName= {chapter.chapterName}  key={chapter._id} id={chapter._id}>Unlocked</span>
                            <span className="ms-2 lckd">Locked</span>
                          </a>
                        </div>
                        <div className="subject-pic">
                          { (chapters.subjectThumbnailImage && chapters.subjectThumbnailImage !== "")  ?
                          <img src={chapters.subjectThumbnailImage} alt="" className="img-fluid"/>
                          :
                          <img src={`/assets/img/${image}-pic.png`} alt="" className="img-fluid"/>
                        }  
                        </div>
                      </div>
                    </div>
                  </div>
                )) :
                <div className="oopsImage">
                  <img src="/assets/img/oops-content.svg"/>
                  <h1>Chapter not found</h1>
                </div>}
            </Row>
          </div>
          <div className="col-md-12 text-center mt-2 mt-xl-3">
            <button className="bck-btn px-2 bac-btn" type="button" onClick={handelBack}>
              <i className="fas fa-long-arrow-alt-left"></i>
              <span> Back </span>                        
            </button>
                {/*<Button
                  className="org-btn rounded-pill px-4"
                  onClick={handelBack}
                >
                  <i className="fas fa-long-arrow-alt-left"></i><span className="ms-2">Back</span>
                </Button>*/}      
          </div>
        </div>
      </div>
      </div>
      <FooterInner />
    </div>
  );
}
export default injectModels(['chapterApi', 'subjectApi'])(ChapterList);