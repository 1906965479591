import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { JWT } from '../helpers';

const getUsers = async () => {
    try {
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/chat/get-chat-users`, { "Authorization": `Bearer ${accessToken}` }, { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` } });
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

const getChat = async (data) => {
    try {
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/chat/get-all-student-chat`, { "Authorization": `Bearer ${accessToken}` }, { headers: { "Content-Type": "application/json", "Authorization": `Bearer ${accessToken}` } });
        if (response.status === 200) {
            return response.data;

        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

const sendChat = async (data) => {
    try {
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/chat`, data, { headers: { "Authorization": `Bearer ${accessToken}` } });
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

const getChatCounter = async (data) => {
    try {
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/chat/get-unread-counter`, data, { headers: { "Authorization": `Bearer ${accessToken}` } });
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch (error) {
        return Promise.reject(error);
    }
}


const sendChatWithFile = async (data) => {
    try {
        const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/chat/add-chat-withfile`, data, { headers: { "Authorization": `Bearer ${accessToken}` } });
        if (response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch (error) {
        return Promise.reject(error);
    }
}

const ChatApi = {
    getUsers,
    getChat,
    sendChat,
    getChatCounter,
    sendChatWithFile
}

export { ChatApi };