import { GET_TOPIC,GET_SUBJECT_HOMEWORK, CHAPTER_ID, GET_SINGLE_TOPIC, GET_QUESTIONS, GET_SINGLE_QUESTION, GET_REPORT, GET_COMMENTS ,GET_SUBJECT_HOMEWORK_DETAILS} from "./constants";

const intialState = {
    items:[],
    singleItem:null,
    total:0,
    id:null,
    isLoading:true,
    question:null,
    singleQuestion:null,
    report:null,
    comment:null,
}

export default (state=intialState, action) => {
    switch(action.type){
        case GET_TOPIC :
            return {
                ...state,
                items:action.payload,
                isLoading:false,
            }


            case GET_SUBJECT_HOMEWORK :
                return {
                    ...state,
                    items:action.payload,
                    isLoading:false,
                }

                case GET_SUBJECT_HOMEWORK_DETAILS :
                    return {
                        ...state,
                        items:action.payload,
                        isLoading:false,
                    }

        case CHAPTER_ID :
            return{
                ...state,
                id:action.payload,
            }
        case GET_SINGLE_TOPIC :
            return{
                ...state,
                singleItem:action.payload,
                isLoading:false,
            }
        case GET_QUESTIONS :
            return{
                ...state,
                question:action.payload,
                isLoading:false,
            }
        case GET_SINGLE_QUESTION :
            return{
                ...state,
                singleQuestion:action.payload,
                isLoading:false,
            }

        case GET_REPORT :
            return{
                ...state,
                report:action.payload,
                isLoading:false,
            }

        case GET_COMMENTS :
            return{
                ...state,
                comments:action.payload,
                isLoading:false,
            }
            
        default :
            return state;    
    }
}