import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { JWT } from '../helpers';

const Login = async(data) => {
    try{
        const response = await axios.post(`${API_ENDPOINT}/auth/user-login`, JSON.stringify(data), {headers:{"Content-Type":"application/json"}});
        if(response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error); 
    }  
}

const Register = async(data) => {
    try{
          const response = await axios.post(`${API_ENDPOINT}/user/register`, JSON.stringify(data), {headers:{"Content-Type":"application/json"}});
          if(response.status === 200) {
            return response.data;
            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const checkEmail = async(data) => {
     try{
          const response = await axios.post(`${API_ENDPOINT}/user/checkEmailAddress`, JSON.stringify(data), {headers:{"Content-Type":"application/json"}});
          if(response.status === 200) {
            return response.data;
            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const ForgotPassword = async(data) => {
    try{
        const response = await axios.post(API_ENDPOINT+`/auth/forgot-password`, JSON.stringify(data), {headers:{"Content-Type":"application/json"}});
        if(response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const ResetPassword = async(data) => {
    try{
        const response = await axios.post(API_ENDPOINT+`/auth/reset-password`, JSON.stringify(data), {headers:{"Content-Type":"application/json"}});
        if(response.status === 200) {
            return response.data;
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const Auth = {
    Login,
    Register,
    ForgotPassword,
    ResetPassword,
    checkEmail
}

export {Auth};