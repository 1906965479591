import {createStore, combineReducers, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

import auth from './models/Auth/reducer';
import subjectApi from './models/SubjectApi/reducer';
import chapterApi from './models/ChapterApi/reducer';
import topicApi from './models/TopicApi/reducer';
import userApi from './models/UserApi/reducer';
import chatApi from './models/ChatApi/reducer';
import notificationApi from './models/NotificationApi/reducer';
import demo from './models/Demo/reducer';
// import settings from "./settings/Reducer";

const rootReducers = combineReducers({
    auth,
    subjectApi,
    chapterApi,
    topicApi,
    userApi,
    chatApi,
    notificationApi,
    demo
    // settings
});

const store = createStore(rootReducers, composeWithDevTools(applyMiddleware(thunk)));
export {store};

