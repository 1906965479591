import { GET_USER, UPDATE_SUCCESS, UPDATE_FAILED, CHANGE_PASSWORD} from "./constants";

const intialState = {
   user:null,
}

export default (state=intialState, action) => {
    switch(action.type){
        case GET_USER :
            return {
                ...state,
                user:action.payload
            }

        case UPDATE_SUCCESS : 
            return {
                ...state,
                user:action.payload
            }

        case UPDATE_FAILED : 
            return {
                ...state,
                user:null
            }

        case CHANGE_PASSWORD : 
            return {
                ...state,
                user:action.payload
            }
        default :
            return state;    
    }
}