import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { Header, Loader, FooterInner } from "../includes";
import {injectModels} from '../../redux/injectModels';
import InputGroupAddon from 'bee-input-group-addon';
import {SubjectApi, Auth, UserApi} from '../../services';
import { getSubject } from "../../redux/models/SubjectApi/action";
import {InputGroup, InputGroupText, Input,Form,Row,Col,Label,Button,} from "reactstrap";

const HomeWorkSubject=(props)=> {

    const [dynamicColor, setDynamicColor] = useState("");
    const [error, setError] = useState('');
    const [image,setImage] = useState("");

    const data = {studentClass:props.auth.classId, isHomework: true};
    const dispatch = useDispatch();

    useEffect(() => {
      dispatch(getSubject(data));
      document.getElementById("contentBox").classList.remove("inner-hide-box");
    }, [dispatch]);
    
    const subjects = useSelector((state) => state.subjectApi.items.data);
    
    const handleClick = e => {
        if(localStorage.getItem(dynamicColor)){
          localStorage.removeItem(dynamicColor);
        }
        localStorage.setItem(dynamicColor, e.target.getAttribute("color")); 
        props.history.push({
           pathname: `/home-work-list/${ e.target.id}`,
           state: { subject: `${e.target.getAttribute("name")}` ,
           subjectId :e.target.id,
          }
       });
    }

    return (
    <div>
    <Header/>    
      <div id="main" className="InnerClass inner-page-main">
        <div className="container">
        <div className="row justify-content-center">
        <div className="col-md-10 col-lg-10 ks3">
          <Row className="justify-content-center inner-hide-box" id="contentBox">
            {!subjects  ? <Loader/> :
              subjects && subjects.length ?               
              subjects.map((subject) => (
                <>                
                { subject && subject.isHomework == true ?  
                <div className="col-lg-4 col-md-6 d-flex align-items-stretch mb-3"  key={subject._id}>
                  <div className="icon-box" style={{backgroundColor: subject.subjectColour}}>
                    <div className="hover-box align-items-center justify-content-center d-flex p-4"
                    style={{backgroundColor: subject.subjectColour}}>     
                      <a key={subject._id} color={subject.subjectColour}
                       name={subject.subjectName} className="btn-get-started ms-0" id={subject._id} 
                        onClick={handleClick}>
                        View More...
                      </a>
                    </div>                    
                    <div className="subject-pic mb-2 mb-xxl-3">
                      <img src={subject.thumbnailImage} alt="" className="img-fluid"/>  
                    </div> 
                    <h4 className="mb-2 mb-xxl-3">
                    <a href="javascript:void(0)" data-status={subject.showMarker}>{subject.subjectName}
                    {(subject.showMarker === true) ? 
                     <div className="ex-mark">
                     <i className="fas fa-exclamation">
                     </i>
                     </div> 
                     : ""} 
                     </a>
                     </h4>              
                  </div>
                </div>
                :""}
                </>
                ))
              : "no subjects"}
          </Row>
           </div>           
        </div>
        </div>
      </div>     
      <FooterInner/>
    </div>
  )
}
export default injectModels(['auth', 'subjectApi'])(HomeWorkSubject);