import React, { useState, useEffect } from "react";
import { Routes, WIRIS_PATH } from "../../constants";
import { Link, useHistory, useParams } from "react-router-dom";
import { Header, FooterInner } from "../includes";
import { injectModels } from "../../redux/injectModels";
import ReactHtmlParser from "react-html-parser";
import { NotificationApi } from "../../services/NotificationApi";
import moment from "moment";
import {
  InputGroup,
  InputGroupText,
  Input,
  Form,
  Row,
  Col,
  Label,
  Button,
} from "reactstrap";
import Modal from "react-bootstrap/Modal";

const ManualResult = (props) => {
  const demoResult = props.location.state.submitData;

  // console.log("demoResult",demoResult)

  const [classSubject, setClassSubject] = useState("");
  const [dynamicColor, setDynamicColor] = useState("");
  const [error, setError] = useState("");

  const [results, setResults] = useState();
  const [resultsData, setResultsData] = useState("");
  const color = localStorage.getItem(dynamicColor);
  const history = useHistory();
  let { paperId } = useParams();
  const [show, setShow] = useState(false);
  const [showDesAnswer, setShowDesAnswer] = useState("");


  const [showDes, setShowDes] = useState(false);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [showDess, setShowDess] = useState(false);
  const [showDesIndex, setShowDesIndex] = useState(false);
  const [showDesIndexAns, setShowDesIndexAns] = useState(false);

  const [showMultipleAnswer, setShowMultipleAnswer] = useState("");
  const [showImagePath, setShowImagePath] = useState();

  const handleClose = () => setShowDes(false);

  const handleClosee = () => setShowDess(false);

  const handleCloseeAnswer = () => setShowCorrectAnswer(false);

  

  const loadScript = () => {
    const script = document.createElement("script");
    script.src = WIRIS_PATH;
    script.async = true;
    document.body.appendChild(script);
  };

  const handleShow = (e) => {
    let getIndex = e.target.getAttribute("data-index");
    setShowDesIndex(parseInt(getIndex));
    setShowDes(true);
  };
  const handleShowAnswer = (e) => {    
    let getIndexx = e.target.getAttribute("data-index");

    let showAnss = e.target.getAttribute("data-answer");
    
    if (showAnss) {

      let options = e.target.getAttribute("data-options"); // find the total options

      let abc = results.questions[getIndexx].rightAnawer; // find the correct options

      let abcd = abc.split(",");

      let optionArray = options.split(",");

      let answers = [];

      for (const i of abcd) {
        answers.push(optionArray[i]);
      }
      let datas = answers.join("  or  ");

      setShowMultipleAnswer(datas);
    }
    let dats= results.questions[getIndexx].answerOptions
    let asd =dats.join(" or  ")  
    setShowDesAnswer(asd)

    setShowDesIndexAns(parseInt(getIndexx));
    //setShowDess(true);
    setShowCorrectAnswer(true);
  };


  const  handleImage = (e)=>{
    let getIndexx = e.target.getAttribute("data-index");
    let showAnss = e.target.getAttribute("data-answer");
    if(showAnss){
      setShowDesIndexAns(getIndexx)
      setShowCorrectAnswer(true);
    }

  }

  useEffect(() => {
    if (demoResult.completeTime) {
      let data = demoResult;
      let dataValue = [];

      let indexs = 0;
      for (const res of demoResult.attemptData) {
        
        if (res.type == "descriptive") {
          dataValue.push({
            questionId: res.questionId,
            type: res.type,
            marks: res.marks,
            index: res.index,
            explaination: res.explaination,
            attemptedAnswerStatus: res.attemptedAnswerStatus,
            attemptedAnswer: res.attemptedAnswer,
          });
        } else if (res.type == "optional") {
          dataValue.push({
            questionId: res.questionId,
            type: res.type,
            marks: res.marks,
            index: res.index,
            explaination: res.explaination,
            attemptedAnswerStatus: res.attemptedAnswerStatus,
            attemptedAnswer: res.attemptedAnswer,
          });
        } else if (res.type == "mcq") {
          let attemptedAnswer = res.attemptedAnswer ? res.attemptedAnswer : "";
          let newArray = [];

          for (const a of attemptedAnswer) {
            newArray.push(parseInt(a));
          
          }

          dataValue.push({
            questionId: res.questionId,
            type: res.type,
            marks: res.marks,
            index: res.index,
            explaination: res.explaination,
            attemptedAnswerStatus: res.attemptedAnswerStatus,
            attemptedAnswer: res.attemptedAnswer ? newArray : "",
          });
        } else if (res.type == "match") {
          let attemptedAnswer = res.attemptedAnswer ? res.attemptedAnswer : "";
          let newArray = [];
          for (const a of attemptedAnswer) {
            newArray.push(a);
           
          }
          dataValue.push({
            questionId: res.questionId,
            type: res.type,
            marks: res.marks,
            index: res.index,
            explaination: res.explaination,
            attemptedAnswerStatus: res.attemptedAnswerStatus,
            attemptedAnswer: res.attemptedAnswer ? newArray : "",
          });
        } else if(res.type == "image") {
          
          let attemptedAnswer = res.attemptedAnswer ? res.attemptedAnswer : "";
          let newArray = [];
          let imagePath = data.questions[indexs].imagePath;
          for (const a of attemptedAnswer) {
            newArray.push(a);
          
          }
          dataValue.push({
            questionId: res.questionId,
            type: res.type,
            marks: res.marks,
            index: res.index,
            explaination: res.explaination,
            attemptedAnswerStatus: res.attemptedAnswerStatus,
            imagePath : imagePath,
            attemptedAnswer: attemptedAnswer,
          });
        } else  {

        }

        indexs =  indexs + 1;
      }
      setResults(demoResult);
      setResultsData(dataValue);
      loadScript();
      var elem = document.getElementsByClassName("showDisable");
      if (elem) {
        for (var i = 0; i <= elem.length; i++) {
          if (elem[i]) {
            elem[i].disabled = true;
          }
        }
      }
    } else {
    }
  }, []);

  const handelBack = (e) => {
    history.goBack();
  };

  const getAllHomePageData = () => {
    NotificationApi.getAllHomePageData()
      .then((response) => {
        const data = response.data;
        const classSubjectdata = data.classWithSubjectList;
        setClassSubject(classSubjectdata);
      })
      .catch((err) => {
        setError("Network error");
      });
  };

  const handleModel = (e) => {
    let explanation = e.target.getAttribute("data-practice");
  };

  return (
    <div>
      <Header classlist={classSubject} />
      <div id="main" className="InnerClass inner-page-main">
        <div className="container">
          <div class="col-md-12">
            <div class="ls-heading nmt ">
              <h2 className="result">Result</h2>
            </div>
          </div>
          {results ? (
            <div class="ank-sec mb-3">
              <div class="p-2 text-center mb-3 ank-bg">
                <div className="dash-brdr p-2">
                  <h1 class="font-light mb-0">{results.totalMarks}</h1>
                  <h6 class="">Total Marks</h6>
                </div>
              </div>
              <div class="p-2 text-center mb-3 ank-bg">
                <div className="dash-brdr p-2">
                  <h1 class="font-light mb-0">{results.getMarks}</h1>
                  <h6 class="">Obtain Marks</h6>
                </div>
              </div>

              <div class="p-2 text-center ank-bg">
                <div className="dash-brdr p-2">
                  <h1 class="font-light mb-0">{results.parsentage}%</h1>
                  <h6 class="">Percentage</h6>
                </div>
              </div>

              {props.location.state.isDateShow ? (
                <div class="p-2 text-center ank-bg">
                  <div className="dash-brdr p-2">
                    <h1 class="font-light mb-0">
                      {moment(results.created).format("DD-MM-YYYY")}
                    </h1>
                    <h6 class="">Date</h6>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : null}

          <div class="row">
            <div class="col-xl-12">
              <div class="rr-section">
                {results && results.questions
                  ? results.questions.map((question, index) => (
                      <div>
                        {question.questionType == "descriptive" ? (
                          <div
                            class={
                              results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted"
                                ? "black-box wht-shade ques-panel q-pp mb-3"
                                : results.attemptData[index]
                                    .attemptedAnswerStatus == true
                                ? "green-box ques-panel q-pp mb-3"
                                : "red-box ques-panel q-pp mb-3"
                            }
                          >
                            <div className="q-pp-l ">
                              <div class="ques-head mb-2">
                                <h5 class="mb-2 ques-heading">
                                  <div className="me-2">{index + 1})</div>
                                  <div>
                                    {ReactHtmlParser(question.questionTitle)}
                                  </div>
                                </h5>
                              </div>
                              <div className="que-row ms-2">
                                <div class="ques-type mb-2 me-4">
                                  <span>Question Type :</span>
                                  <strong>
                                    {ReactHtmlParser(question.questionType)}
                                  </strong>
                                </div>
                              </div>

                              <div class="ques-review ms-4">
                                <input
                                  type="text"
                                  className="showDisable"
                                  data-practice={
                                    results.attemptData[index].explaination
                                  }
                                  value={
                                    results.attemptData[index]
                                      .attemptedAnswer &&
                                    results.attemptData[index].attemptedAnswer
                                      .length
                                      ? results.attemptData[index]
                                          .attemptedAnswer
                                      : ""
                                  }
                                />
                              </div>

                              {results.type == "newHomework" &&
                              results.type == "newPracticeQuestion" ? (
                                ""
                              ) : (
                                <div class="ques-review me-4">
                                  <button
                                    type="button"
                                    onClick={handleShow}
                                    data-index={index}
                                    class="btn btn-success"
                                    style={{ margin: "10px" }}
                                  >
                                    Show explanation
                                  </button>

                                  <button
                                    type="button"
                                    onClick={handleShowAnswer}
                                    data-index={index}
                                    class="btn btn-success"
                                    style={{ margin: "10px" }}
                                  >
                                    Show Answer
                                  </button>

                                  {/* {results.attemptData[index].rightAnswer} */}
                                </div>
                              )}
                            </div>

                            <div className="q-pp-r">
                              {results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted" ? (
                                <div className="round-tag black-bg p-1">
                                  <img
                                    src="/assets/img/na-icon.svg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                              ) : results.attemptData[index]
                                  .attemptedAnswerStatus == true ? (
                                <div className="round-tag green-bg">
                                  <i className="fas fa-check"></i>
                                </div>
                              ) : (
                                <div className="round-tag red-bg">
                                  <i class="fas fa-times"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {question.questionType == "optional" ? (
                          <div
                            class={
                              results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted"
                                ? "black-box ques-panel q-pp mb-3"
                                : results.attemptData[index]
                                    .attemptedAnswerStatus == true
                                ? "green-box ques-panel q-pp mb-3"
                                : "red-box ques-panel q-pp mb-3"
                            }
                          >
                            <div className="q-pp-l wada">
                              <div class="ques-head mb-2">
                                <h5 class="mb-2 ques-heading">
                                  <div className="me-2">{index + 1})</div>
                                  <div>
                                    {ReactHtmlParser(question.questionTitle)}
                                  </div>
                                </h5>
                              </div>
                              <div className="que-row ms-2">
                                <div class="ques-type mb-2 me-4">
                                  <span>Question Type :</span>
                                  <strong>{question.questionType}</strong>
                                </div>
                                {/*<div className="ques-marks mb-2 me-4">
										                	<span>Question Marks : </span> 
										                	<strong> 2 </strong>
										                </div>
										                <div className="ques-obtain mb-2 me-4">
										                	<span>Obtain Marks : </span> 
										                	<strong> 0 </strong>
										                </div>*/}
                              </div>
                              <div class="ques-review ms-4">
                                {question &&
                                question.answerOptions &&
                                question.answerOptions.length
                                  ? question.answerOptions.map(
                                      (answerOption, indexing) =>
                                        results.attemptData[index]
                                          .attemptedAnswer.length &&
                                        results.attemptData[index]
                                          .attemptedAnswer == indexing ? (
                                          <div className="radiobox-row">
                                            <input
                                              type="radio"
                                              id={
                                                "option" + index + question._id
                                              }
                                              name={question._id}
                                              value={
                                                results.attemptData[index]
                                                  .attemptedAnswer
                                              }
                                              className="css-radiobox showDisable"
                                              checked
                                            />
                                            <label
                                              htmlFor={
                                                "option" + index + question._id
                                              }
                                              name={question._id}
                                              className="css-label lite-green-check"
                                            >
                                              {ReactHtmlParser(answerOption)}
                                            </label>
                                          </div>
                                        ) : (
                                          <div className="radiobox-row">
                                            <input
                                              type="radio"
                                              id={
                                                "option" + index + question._id
                                              }
                                              name={question._id}
                                              value={
                                                results.attemptData[index]
                                                  .attemptedAnswer
                                              }
                                              className="css-radiobox showDisable"
                                            />
                                            <label
                                              htmlFor={
                                                "option" + index + question._id
                                              }
                                              name={question._id}
                                              className="css-label lite-green-check"
                                            >
                                              {ReactHtmlParser(answerOption)}
                                            </label>
                                          </div>
                                        )
                                    )
                                  : null}

                                {results.type == "newHomework" &&
                                results.type == "newPracticeQuestion" ? (
                                  ""
                                ) : (
                                  <div class="ques-review me-4">
                                    <button
                                      type="button"
                                      onClick={handleShow}
                                      data-index={index}
                                      class="btn btn-success"
                                      style={{ margin: "10px" }}
                                    >
                                      Show explanation
                                    </button>

                                    <button
                                      type="button"
                                      onClick={handleShowAnswer}
                                      data-index={index}
                                      class="btn btn-success"
                                      style={{ margin: "10px" }}
                                    >
                                      Show Answer
                                    </button>

                                    {/* {results.attemptData[index].rightAnswer} */}
                                  </div>
                                )}

                              </div>
                            </div>

                            <div className="q-pp-r">
                              {results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted" ? (
                                <div className="round-tag black-bg p-1">
                                  <img
                                    src="/assets/img/na-icon.svg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                              ) : results.attemptData[index]
                                  .attemptedAnswerStatus == true ? (
                                <div className="round-tag green-bg">
                                  <i className="fas fa-check"></i>
                                </div>
                              ) : (
                                <div className="round-tag red-bg">
                                  <i class="fas fa-times"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {question.questionType == "mcq" ? (
                          <div
                            class={
                              results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted"
                                ? "black-box ques-panel q-pp mb-3"
                                : results.attemptData[index]
                                    .attemptedAnswerStatus == true
                                ? "green-box ques-panel q-pp mb-3"
                                : "red-box ques-panel q-pp mb-3"
                            }
                          >
                            <div className="q-pp-l">
                              <div class="ques-head mb-2">
                                <h5 class="mb-2 ques-heading">
                                  <div className="me-2">{index + 1})</div>
                                  <div>
                                    {ReactHtmlParser(question.questionTitle)}
                                  </div>
                                </h5>
                              </div>
                              <div className="que-row ms-2">
                                <div class="ques-type mb-2 me-4">
                                  <span>Question Type :</span>
                                  <strong>{question.questionType}</strong>
                                </div>
                                {/*<div className="ques-marks mb-2 me-4">
										                	<span>Question Marks : </span> 
										                	<strong> 2 </strong>
										                </div>
										                <div className="ques-obtain mb-2 me-4">
										                	<span>Obtain Marks : </span> 
										                	<strong> 0 </strong>
										                </div>*/}
                              </div>

                              <div class="ques-review ms-4">
                                {question &&
                                question.answerOptions &&
                                question.answerOptions.length
                                  ? question.answerOptions.map(
                                      (answerOption, indexing) =>
                                        results.attemptData[index]
                                          .attemptedAnswer.length &&
                                        results.attemptData[
                                          index
                                        ].attemptedAnswer.includes(indexing) ? (
                                          <div className="checkbox-row">
                                            <input
                                              type="checkbox"
                                              className={
                                                "css-checkbox showDisable dynamic_value_" +
                                                index
                                              }
                                              id={"mcq" + index + question._id}
                                              name={question._id}
                                              value={index}
                                              checked
                                            />
                                            <label
                                              htmlFor={
                                                "mcq" + index + question._id
                                              }
                                              className="css-label lite-green-check"
                                            >
                                              {ReactHtmlParser(answerOption)}
                                            </label>
                                          </div>
                                        ) : (
                                          <div className="checkbox-row">
                                            <input
                                              type="checkbox"
                                              className={
                                                "css-checkbox showDisable dynamic_value_" +
                                                index
                                              }
                                              id={"mcq" + index + question._id}
                                              name={question._id}
                                              value={index}
                                            />
                                            <label
                                              htmlFor={
                                                "mcq" + index + question._id
                                              }
                                              className="css-label lite-green-check"
                                            >
                                              {ReactHtmlParser(answerOption)}
                                            </label>
                                          </div>
                                        )
                                    )
                                  : null}

                                {results.type == "newHomework" &&
                                results.type == "newPracticeQuestion" ? (
                                  ""
                                ) : (
                                  <div class="ques-review me-4">
                                    <button
                                      type="button"
                                      onClick={handleShow}
                                      data-index={index}
                                      class="btn btn-success"
                                      style={{ margin: "10px" }}
                                    >
                                      Show explanation
                                    </button>

                                    <button
                                      type="button"
                                      onClick={handleShowAnswer}
                                      data-index={index}
                                      data-options={question.answerOptions}
                                      data-answer={question.questionType}
                                      class="btn btn-success"
                                      style={{ margin: "10px" }}
                                    >
                                      Show Answer
                                    </button>
                                    {/* {results.attemptData[index].rightAnswer} */}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="q-pp-r">
                              {results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted" ? (
                                <div className="round-tag black-bg p-1">
                                  <img
                                    src="/assets/img/na-icon.svg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                              ) : results.attemptData[index]
                                  .attemptedAnswerStatus == true ? (
                                <div className="round-tag green-bg">
                                  <i className="fas fa-check"></i>
                                </div>
                              ) : (
                                <div className="round-tag red-bg">
                                  <i class="fas fa-times"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {question.questionType == "match" ? (
                          <div
                            class={
                              results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted"
                                ? "black-box ques-panel q-pp mb-3"
                                : results.attemptData[index]
                                    .attemptedAnswerStatus == true
                                ? "green-box ques-panel q-pp mb-3"
                                : "red-box ques-panel q-pp mb-3"
                            }
                          >
                            <div className="q-pp-l">
                              <div class="ques-head mb-2">
                                <h5 class="mb-2 ques-heading">
                                  <div className="me-2">{index + 1})</div>
                                  <div>
                                    {ReactHtmlParser(question.questionTitle)}
                                  </div>
                                </h5>
                              </div>
                              <div className="que-row ms-2">
                                <div class="ques-type mb-2 me-4">
                                  <span>Question Type :</span>
                                  <strong>{question.questionType}</strong>
                                </div>
                              </div>
                              <div class="ques-review ms-4">
                                {question &&
                                question.answerOptions &&
                                question.answerOptions.length ? (
                                  <div className="q-list row">
                                    <div className="col-md-4">
                                      <div className="q-list-input">
                                        <h6>Question</h6>
                                        {question &&
                                        question.answerOptions &&
                                        question.answerOptions.length
                                          ? question.answerOptions.map(
                                              (answerOption, index) => (
                                                <>
                                                  <input
                                                    className={
                                                      "css-checkbox showDisable dynamic_value_"
                                                    }
                                                    id={
                                                      "match" +
                                                      index +
                                                      question._id
                                                    }
                                                    name={question._id}
                                                    defaultValue={ReactHtmlParser(
                                                      answerOption.fields
                                                    )}
                                                    readOnly
                                                  />
                                                </>
                                              )
                                            )
                                          : null}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="q-list-input">
                                        <h6>Attempted Answer</h6>
                                        {results.attemptData[index]
                                          .attemptedAnswer &&
                                        results.attemptData[index]
                                          .attemptedAnswer.length
                                          ? results.attemptData[
                                              index
                                            ].attemptedAnswer.map(
                                              (ans, index) => (
                                                <>
                                                  <input
                                                    className={
                                                      "css-checkbox showDisable dynamic_value_"
                                                    }
                                                    id={"match" + ans}
                                                    name={ans}
                                                    defaultValue={ReactHtmlParser(
                                                      ans
                                                    )}
                                                    readOnly
                                                  />
                                                </>
                                              )
                                            )
                                          : "Not Attempted"}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="q-list-input">
                                        <h6>Correct Answer</h6>

                                        {question &&
                                        question.answerOptions &&
                                        question.answerOptions.length
                                          ? question.answerOptions.map(
                                              (answerOption, index) => (
                                                <>
                                                  <input
                                                    className={
                                                      "css-checkbox showDisable dynamic_value_"
                                                    }
                                                    id={
                                                      "match" +
                                                      index +
                                                      question._id
                                                    }
                                                    name={question._id}
                                                    defaultValue={ReactHtmlParser(
                                                      answerOption.fieldss
                                                    )}
                                                    readOnly
                                                  />
                                                </>
                                              )
                                            )
                                          : null}
                                      </div>
                                    </div>

                                    {/* {results.attemptData[index].attemptedAnswer &&
																	results.attemptData[index].attemptedAnswer.length ? results.attemptData[index].attemptedAnswer.map((ans, index) => (
																			<>
																				<div className="checkbox-col-md-12 col-md-12 mb-2 d-flex">
																					<input className={"css-checkbox showDisable dynamic_value_"} id={"match" + ans} name={ans}
																						defaultValue={ReactHtmlParser(ans)}
																						readOnly
																					/>
																				</div>
																			
																			</>
																		)) : null
																} */}
                                  </div>
                                ) : null}

                                {results.type == "newHomework" &&
                                results.type == "newPracticeQuestion" ? (
                                  ""
                                ) : (
                                  <div class="ques-review me-4">
                                    <button
                                      type="button"
                                      onClick={handleShow}
                                      data-index={index}
                                      class="btn btn-success"
                                      style={{ margin: "10px" }}
                                    >
                                      Show explanation
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="q-pp-r">
                              {results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted" ? (
                                <div className="round-tag black-bg p-1">
                                  <img
                                    src="/assets/img/na-icon.svg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                              ) : results.attemptData[index]
                                  .attemptedAnswerStatus == true ? (
                                <div className="round-tag green-bg">
                                  <i className="fas fa-check"></i>
                                </div>
                              ) : (
                                <div className="round-tag red-bg">
                                  <i class="fas fa-times"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}

                        {question.questionType == "fill" ? (
                          <div
                            class={
                              results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted"
                                ? "black-box ques-panel q-pp mb-3"
                                : results.attemptData[index]
                                    .attemptedAnswerStatus == true
                                ? "green-box ques-panel q-pp mb-3"
                                : "red-box ques-panel q-pp mb-3"
                            }
                          >
                            <div className="q-pp-l">
                              <div class="ques-head mb-2">
                                <h5 class="mb-2 ques-heading">
                                  <div className="me-2">{index + 1})</div>
                                  <div>
                                    {ReactHtmlParser(question.questionTitle)}
                                  </div>
                                </h5>
                              </div>

                              <div className="que-row ms-2">
                                <div class="ques-type mb-2 me-4">
                                  <span>Question Type :</span>
                                  <strong>{question.questionType}</strong>
                                </div>
                              </div>
                            

                              <div class="ques-review ms-4">
                                <h6>
                                  Attempted Value :{" "}
                                  {ReactHtmlParser(
                                    results.attemptData[index]
                                      .attemptedAnswerWithIndex &&
                                      results.attemptData[index]
                                        .attemptedAnswerWithIndex.length
                                      ? results.attemptData[index]
                                          .attemptedAnswerWithIndex
                                      : "Not attempted"
                                  )}
                                </h6>
                              </div>

                              {results.type == "newHomework" &&
                              results.type == "newPracticeQuestion" ? (
                                ""
                              ) : (
                                <div class="ques-review me-4">
                                  <button
                                    type="button"
                                    onClick={handleShow}
                                    data-index={index}
                                    class="btn btn-success"
                                    style={{ margin: "10px" }}
                                  >
                                    Show explanation
                                  </button>

                                  <button
                                    type="button"
                                    onClick={handleShowAnswer}
                                    data-index={index}
                                    class="btn btn-success"
                                    style={{ margin: "10px" }}
                                  >
                                    Show Answer
                                  </button>
                                  {/* {results.attemptData[index].explaination} */}
                                </div>
                              )}
                            </div>

                            <div className="q-pp-r">
                              {results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted" ? (
                                <div className="round-tag black-bg p-1">
                                  <img
                                    src="/assets/img/na-icon.svg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                              ) : results.attemptData[index]
                                  .attemptedAnswerStatus == true ? (
                                <div className="round-tag green-bg">
                                  <i className="fas fa-check"></i>
                                </div>
                              ) : (
                                <div className="round-tag red-bg">
                                  <i class="fas fa-times"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null}


                        {question.questionType == "image" ? (
                          <div
                            class={
                              results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted"
                                ? "black-box ques-panel q-pp mb-3"
                                : results.attemptData[index]
                                    .attemptedAnswerStatus == true
                                ? "green-box ques-panel q-pp mb-3"
                                : "red-box ques-panel q-pp mb-3"
                            }
                          >
                            <div className="q-pp-l">
                              <div class="ques-head mb-2">
                                <h5 class="mb-2 ques-heading">
                                  <div className="me-2">{index + 1})</div>
                                  <div>
                                    {ReactHtmlParser(question.questionTitle)}
                                  </div>                               

                                </h5>
                              </div>
                              <div className="que-row ms-2">
                                <div class="ques-type mb-2 me-4">
                                  <span>Question Type :</span>
                                  <strong>{question.questionType}</strong>
                                </div>
                              </div>
                              <div class="ques-review ms-4">
                                {question &&
                                question.answerOptions &&
                                question.answerOptions.length ? (
                                  <div className="q-list row">
                                    <div className="col-md-4">
                                      <div className="q-list-input">
                                        <h6>Attempted Answer</h6>

                                        {results.attemptData[index]
                                          .attemptedAnswer &&
                                        results.attemptData[index]
                                          .attemptedAnswer.length
                                          ? results.attemptData[
                                              index
                                            ].attemptedAnswer.map(
                                              (attemptedAns, index) =>
                                                attemptedAns.correctAnswer ==
                                                true ? (
                                                  <div className="image-manual">
                                                    <input
                                                      type="text"
                                                      className="showDisable"
                                                      value={attemptedAns.value}
                                                    />
                                                    <div className="round-tag green-bg">
                                                      <i className="fas fa-check"></i>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="image-manual"
                                                    style={{
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <input
                                                      type="text"
                                                      className="showDisable"
                                                      value={attemptedAns.value}
                                                      id={
                                                        "match" + attemptedAns
                                                      }
                                                      name={attemptedAns}
                                                      defaultValue={ReactHtmlParser(
                                                        attemptedAns
                                                      )}
                                                      readOnly
                                                    />
                                                  </div>
                                                )
                                            )
                                          :"Not Attempted"}
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <div className="q-list-input">
                                        <h6>Correct Answer</h6>

                                        {results.attemptData[index]
                                          .answerOptions &&
                                        results.attemptData[index].answerOptions
                                          .length
                                          ? results.attemptData[
                                              index
                                            ].answerOptions.map(
                                              (attemptedAns, index) =>
                                                attemptedAns.correctAnswer ==
                                                true ? (
                                                  <div className="image-manual">
                                                    <input
                                                      type="text"
                                                      className="showDisable"
                                                      value={attemptedAns}
                                                    />
                                                    <div className="round-tag green-bg">
                                                      <i className="fas fa-check"></i>
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <div
                                                    className="image-manual"
                                                    style={{
                                                      marginBottom: "10px",
                                                    }}
                                                  >
                                                    <input
                                                      type="text"
                                                      className="showDisable"
                                                      value={attemptedAns}
                                                      id={
                                                        "match" + attemptedAns
                                                      }
                                                      name={attemptedAns}
                                                      defaultValue={ReactHtmlParser(
                                                        attemptedAns
                                                      )}
                                                      readOnly
                                                    />
                                                  </div>
                                                )
                                            )
                                          : null}
                                      </div>
                                    </div>
                                  </div>
                                ) : null}
                              </div>
                              {results.type == "newHomework" &&
                              results.type == "newPracticeQuestion" ? (
                                ""
                              ) : (
                                <div class="ques-review me-4">                                

                                  <button
                                    type="button"
                                    onClick={handleImage}
                                    data-index={index}
                                    data-answer={question.questionType}
                                    class="btn btn-success"
                                    style={{ margin: "10px" }}
                                  >
                                    Show Image
                                  </button>

                                
                                </div>
                              )}
                            </div>

						            	<div className="q-pp-r">
                              {results.attemptData[index]
                                .attemptedAnswerStatus == "not attempted" ? (
                                <div className="round-tag black-bg p-1">
                                  <img
                                    src="/assets/img/na-icon.svg"
                                    className=""
                                    alt=""
                                  />
                                </div>
                              ) : results.attemptData[index]
                                  .attemptedAnswerStatus == true ? (
                                <div className="round-tag green-bg">
                                  <i className="fas fa-check"></i>
                                </div>
                              ) : (
                                <div className="round-tag red-bg">
                                  <i class="fas fa-times"></i>
                                </div>
                              )}
                            </div>
                          </div>
                        ) : null
                      }
                      </div>
                    ))
                  : null}
              </div>
            </div>
          </div>
          <div className="col-md-12 text-center mt-3 mt-xl-4 pt-3 pt-xl-4">
            <button className="bck-btn px-2" type="button" onClick={handelBack}>
              <i className="fas fa-long-arrow-alt-left"></i>
              <span> Back </span>
            </button>
          </div>
        </div>
      </div>
      <FooterInner />

      {showDes ? (
        <Modal show={showDes}>
          <Modal.Header>
            <Modal.Title>Explanation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {ReactHtmlParser(results.attemptData[showDesIndex].explaination)}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}

      {showCorrectAnswer ? (
        <Modal show={showCorrectAnswer}>
          <Modal.Header>
          {results.questions[showDesIndexAns].questionType == "image"?
          <Modal.Title>Question Image</Modal.Title>
              : <Modal.Title>Correct Answer</Modal.Title>}
          </Modal.Header>
          <Modal.Body>
            {results.questions[showDesIndexAns].questionType == "optional"
              ? ReactHtmlParser(
                  results.questions[showDesIndexAns].answerOptions[
                    results.questions[showDesIndexAns].rightAnawer
                  ]
                )
              : results.questions[showDesIndexAns].questionType == "mcq"
              ? ReactHtmlParser(showMultipleAnswer)
              : results.questions[showDesIndexAns].questionType == "fill"
              ? ReactHtmlParser(
                  results.attemptData[showDesIndexAns].rightAnswer
                )
              : results.questions[showDesIndexAns].questionType == "descriptive"
              ? ReactHtmlParser(
                showDesAnswer
                )
              : 
              results.questions[showDesIndexAns].questionType == "image"?
              <img src={results.questions[showDesIndexAns].imagePath} ></img>
              :""                     
              }
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseeAnswer}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

export default ManualResult;
