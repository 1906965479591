import React, { useState,useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import { Header, Loader, FooterInner } from "../includes";
import {injectModels} from '../../redux/injectModels';
import InputGroupAddon from 'bee-input-group-addon';
import {SubjectApi, Auth, UserApi} from '../../services';
import { getSubject } from "../../redux/models/SubjectApi/action";
import {InputGroup, InputGroupText, Input,Form,Row,Col,Label,Button,} from "reactstrap";

const Dashboard = (props) => {
    const [dynamicColor, setDynamicColor] = useState("");
    const [error, setError] = useState('');
    const [image,setImage] = useState("");
    const [topics, setTopics] = useState("");  


    const data = {studentClass:props.auth.classId};
    const dispatch = useDispatch();
    useEffect(() => {
      dispatch(getSubject(data));
      document.getElementById("contentBox").classList.remove("inner-hide-box");
    }, [dispatch]);


    useEffect(() => {      
        let data = {
          type :"dashboard"  
        }
        const resultApi = props.topicApi.getSubjectHomeWork(data);
        resultApi.then((result)=>{            
            if(result){          

                if(result.success == true){  
                  
                   setTopics(result.data);                     
                }else{
                    setTopics(result.data);
                }
            }
        });
     
     
  }, []);
    
    const subjects = useSelector((state) => state.subjectApi.items.data);

    const handleClick = e => {
        if(localStorage.getItem(dynamicColor)){
          localStorage.removeItem(dynamicColor);
        }
        localStorage.setItem(dynamicColor, e.target.getAttribute("color")); 
        props.history.push({
           pathname: `/chapterlist/${ e.target.id}`,
           state: { subject: `${e.target.getAttribute("name")}` }
       });
    }

    const handleChat = e => {
        props.history.push({ 
         pathname: Routes.CHAT,
        });
    }
    
    const handleReport = e => {
       props.history.push({ 
         pathname: `/report/${props.auth.classId}`,
        });
    }
    
    const handleHomework = (e) =>{      
      props.history.push({ 
        pathname: `/home-work-subject`,
       });      
    }

    return (
    <div>
    <Header/>    
      <div id="main" className="InnerClass inner-page-main">
      <div className="container">
        <div className="daily">      
        <button style={{backgroundColor:"black"}}
        onClick={handleHomework}>Daily Homework {( topics && topics.showMarks == true) ?  <i className="fas fa-exclamation"></i> : ""} 
        </button>
       </div>
        <div className="row justify-content-center">
        <div className="col-md-10 col-lg-10 ks3">
          <Row className="justify-content-center inner-hide-box" id="contentBox">
            {!subjects  ? <Loader/> :
              subjects && subjects.length
              ? subjects.map((subject) => (
                <div className="col-lg-4 col-md-6 d-flex align-items-stretch mb-3"  key={subject._id} >
                  <div className="icon-box" style={{backgroundColor: subject.subjectColour}}>
                    <div className="hover-box align-items-center justify-content-center d-flex p-4" style={{backgroundColor: subject.subjectColour}}>                      
                      <a key={subject._id} color={subject.subjectColour} name={subject.subjectName} className="btn-get-started ms-0" id={subject._id}  onClick={handleClick}>
                        View More...
                      </a>
                    </div>
                    {/* <div className="subject-pic mb-2 mb-xxl-3">
                      <img src={`/assets/img/${subject.subjectName.trim().toLowerCase().replace(" ", "-")}-pic.png`} alt="" className="img-fluid"/>  
                    </div>               */}
                    <div className="subject-pic mb-2 mb-xxl-3">
                      <img src={subject.thumbnailImage} alt="" className="img-fluid"/>  
                    </div> 
                    <h4 className="mb-2 mb-xxl-3"><a href="javascript:void(0)" data-status={subject.showMarker}>{subject.subjectName} {(subject.showMarker === true) ? <div className="ex-mark"><i className="fas fa-exclamation"></i></div> : ""}</a></h4>              
                  </div>
                </div>
                ))
              : "no subjects"}
          </Row>
           </div>           
        </div>

        <div onClick={handleReport}>
        <Col md="12" lg="12"  onClick={handleReport} className="mt-4 mt-xl-3 pt-4 pt-xl-5 mb-4 notes reportbtn ">
                   <a className="org-btn yfc-btn bounce-1" href="#"> 
                     <img src="assets/img/notes-icon.png" alt="" className="img-fluid"/>
                     <span className="ms-2">Report</span>
                   </a>
                  </Col>                 
        </div>
        
        <div onClick={handleChat}>
          <div className="chat-room text-center bounce-1">
                <span className="cp">
                  <img src="assets/img/chat-icon.svg" alt="" className="img-fluid"/>                    
                  <strong>Chat</strong>
                </span>
          </div>          
          </div>

        </div>
      </div>     
      <FooterInner/>
    </div>

  );
}
export default injectModels(['auth', 'subjectApi','topicApi'])(Dashboard);
