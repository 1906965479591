import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link, useLocation, useParams  } from "react-router-dom";
import { Redirect } from 'react-router';
import {injectModels} from '../../redux/injectModels';
import { getCount } from "../../redux/models/NotificationApi/action";
import { getChatCounter } from "../../redux/models/ChatApi/action";

import { JWT } from '../../helpers';

const Header = (props) => {
    const location = useLocation();
    const [count, setCount] = useState("");
    const [messageCount, setMessageCount] = useState(0);
    const accessToken = JWT.getJWT();
    let urlID = window.location.hash;

    useEffect(() => {
      props.chatApi.getChatCounter();

      if(props.auth.isLoggedIn){
        if(!props.notificationApi.count){              
          props.notificationApi.getCount();
        }else{              
          props.notificationApi.getCount();
          setCount(props.notificationApi.count);
        }
      }     
      
      setMessageCount(props.chatApi.chatCounter);

    }, [props.auth.isLoggedIn, props.notificationApi.count, props.chatApi.chatCounter]);


    useEffect(() => {
      
      const interval = setInterval(() => {

        props.chatApi.getChatCounter();
        setMessageCount(props.chatApi.chatCounter);

      }, 20000);
      return () => clearInterval(interval);
    
    }, props.chatApi.chatCounter);
    
    return(
        // <header id="header" className={(props.auth.isLoggedIn && location.pathname === "/") || props.auth.isLoggedIn == false ? '' : 'inner-page-header'}>
        <header id="header"  
          className={((props.auth.isLoggedIn && location.pathname === "/") || 
          ((props.auth.isLoggedIn == false && (location.pathname != "/sample-topic-details" && location.pathname != "/manual-result"  )))) ? 'homePageClass' : 
          'inner-page-header sticky'}
        >
          <nav className="navbar navbar-expand-lg">
            <div className="container">
              <div className={(props.auth.isLoggedIn && location.pathname === "/") || props.auth.isLoggedIn == false ? 'logo' : 'logo small-logo'}>                         
                 <a href="" className="pos-rel" id="homelogo">
                  <img src="/assets/img/lpi-logo.svg" alt="" className="img-fluid"/>          
                </a> 
              </div>
              
              <div>
                <div className="tp-bar mb-0" >
                    {props.auth.isLoggedIn ?
                     <div className="profile-sec">
                      <span className="me-2">Welcome {props.auth.firstName}</span>
                      <Link to={Routes.NOTIFICATION} className="notify-pointer">
                      <i className="fas fa-bell"></i><div id="notification-count"> {count ? count : 0} </div></Link>

                      <Link to={Routes.CHAT} className="notify-pointer">
                      <i className="fas fa-envelope" ></i><div id="notification-count" className="unreadMessageCounter">{messageCount ? messageCount : 0}</div></Link>


                      <div className="nav-item dropdown">
                        <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                          <i className="fas fa-user"></i>
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="navbarDarkDropdownMenuLink">
                          <li><Link className="dropdown-item" to={Routes.EDIT_PROFILE}>Edit Profile</Link></li>
                          <li><Link className="dropdown-item" to={Routes.CHANGE_PASSWORD}>Change Password</Link></li>
                        </ul>
                      </div>
                      {props.auth.isLoggedIn ? <Link to={Routes.REGISTER} className="register-btn me-1 ms-2">Dashboard</Link>: ""}
                      
                    </div>  :
                    ""}
                    {props.auth.isLoggedIn ? "" : <Link to={Routes.NEW_TOPIC_DETAILS} className="btn-danger-demo login-btn me-1 ms-2">Demo</Link>}   

                    {props.auth.isLoggedIn ? <a href="/" className="login-btn me-0 ms-2" onClick={(e)=>props.auth.logout()}>Logout</a> : <Link to={Routes.LOGIN} className="login-btn me-0 ms-2">Login</Link>}
                    {props.auth.isLoggedIn ? "" : <Link to={Routes.REGISTER} className="register-btn me-1 ms-2">Register</Link>}   
                                     
                    <button className="navbar-toggler ms-2" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                  </div>

                <div className="collapse navbar-collapse" id="navbarNavDropdown">                
                  <ul className="navbar-nav" id="navbar" data-pathname={location.pathname}>
                    {(props.auth.isLoggedIn && location.pathname === "/") || props.auth.isLoggedIn == false ? <li className="nav-item top-navbar"><a className={urlID === "" || urlID === "#home" ? 'scrollto active' : 'scrollto'} data-href="home" href="#home">Home</a></li> : ""}

                    {(props.auth.isLoggedIn && location.pathname === "/") || props.auth.isLoggedIn == false ? <li className="nav-item top-navbar"><a className={urlID === "#about" ? 'scrollto active' : 'scrollto'} data-href="about" href="#about">About Us</a></li> : ""}      
                         
                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item top-navbar"><a className={urlID === "#plus11" ? 'scrollto active' : 'scrollto'} data-href="plus11" href="#plus11">11+</a></li>: ""} 

                  
                    
                    {/*(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? 
                      (props.classlist.length > 0 && props.classlist[0]) ? 
                      <li className="nav-item top-navbar"><a className={urlID === "#ks3" ? 'scrollto active' : 'scrollto'} 
                      data-href="ks3" href="#ks3">{props.classlist[0].title}</a></li>                    
                      : ""
                    : ""}                                        
                    
                   (props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? 
                      (props.classlist.length > 0 && props.classlist[1]) ?
                    <li className="nav-item top-navbar"><a className={urlID === "#gcse" ? 'scrollto active' : 'scrollto'}
                     data-href="gcse" href="#gcse">{props.classlist[1].title} </a></li>
                      : ""
                    : "" */} 
                    {((props.auth.isLoggedIn && location.pathname === "/") 
                     || props.auth.isLoggedIn == false) ? 
                    <li className="nav-item top-navbar"><a className="scrollto" data-href="ks3" href="#ks3">KS3 / Pre-GCSE</a></li>
                    
                    : ""}

                    {((props.auth.isLoggedIn && location.pathname === "/") 
                     || props.auth.isLoggedIn == false) ? 
                     <li className="nav-item top-navbar"><a className="scrollto" data-href="gcse" href="#gcse">GCSE </a></li>
                    
                    : ""}


                    
                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item top-navbar"><a className={urlID === "#workshops" ? 'scrollto active' : 'scrollto'} data-href="workshops" href="#workshops">Workshops</a></li>: ""} 

                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item top-navbar"><a className={urlID === "#pricing" ? 'scrollto active' : 'scrollto'} data-href="pricing" href="#pricing">Pricing</a></li> : ""}            

                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item top-navbar"><a className={urlID === "#faq" ? 'scrollto active' : 'scrollto'} data-href="faq" href="#faq">FAQ</a></li> : ""}            

                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item top-navbar"><a className={urlID === "#testimonial" ? 'scrollto active' : 'scrollto'} data-href="testimonial" href="#testimonial">Testimonials</a></li> : ""}                                

                    {(props.auth.isLoggedIn && location.pathname === "/" ) || props.auth.isLoggedIn == false ? <li className="nav-item top-navbar"><a className={urlID === "#contact" ? 'scrollto active' : 'scrollto'} data-href="contact" href="#contact">Contact Us</a></li>: ""} 

                  </ul>
                </div>
              </div>
            </div>
          </nav>
        </header>
    );
};
export default injectModels(['auth', 'userApi', 'notificationApi', 'chatApi'])(Header);

