import React, { useState, useEffect } from "react";
import { Routes } from "../../constants";
import { Link, useParams, useHistory } from "react-router-dom";
import { Header, FooterInner } from "../includes";
import { NewLesson, NewPracticeQuestion, NewHomeWork } from '.';
import { NotificationApi } from '../../services/NotificationApi';

import InputGroupAddon from 'bee-input-group-addon';
import { injectModels } from '../../redux/injectModels';
import { InputGroup, InputGroupText, Input, Form, Row, Col, Label, Button, Modal, ModalHeader, ModalFooter } from "reactstrap";

const NewTopicDeatils = (props) => {
    
    const [change, setChange] = useState(true);
    const [title, setTitle] = useState("Lesson");
    const [dynamicColor, setDynamicColor] = useState("");
    const history = useHistory();
    const [classSubject, setClassSubject] = useState('');
    let { chapterId, topicId } = useParams();
    const [error, setError] = useState('');

    const getAllHomePageData = () => {
        NotificationApi.getAllHomePageData()
            .then((response) => {
                const data = response.data;
                setClassSubject(data.classWithSubjectList);
            }).catch((err) => {
                setError("Network error");
            })
    }

    useEffect(() => {
        setDynamicColor(localStorage.getItem(dynamicColor));
        getAllHomePageData();
    }, []);

    const handleLesson = (e) => {
        setChange(true)
        setTitle(e.target.innerText)
    }

    const handlePracticeQuestion = (e) => {
        setChange(true)
        setTitle(e.target.innerText)
    }

    const handleHomeWork = (e) => {
        setChange(true)
        setTitle(e.target.innerText)
    }

    const handelBack = e => {
        history.goBack();
    }

    return (
        <div>
            <Header classlist={classSubject} />
            <div id="main" className="InnerClass inner-page-main">
                <div className="container">
                    <Row>
                        <div className="col col-xl-12 mt-0">
                            <div className="rp-section topic-tabs mt-5">
                                <ul className="testStatus rp-1">
                                    <li className={title == "Lesson" ? "activeLi testStatusGood m1 yy-pencil" : "testStatusGood m1 yy-pencil"} id={topicId} onClick={handleLesson}>Lesson</li>
                                    <li className={title == "Practice Questions" ? "activeLi testStatusGood m2 newClass1 sg-pencil" : "testStatusGood m2 newClass1 sg-pencil"} id={topicId} onClick={handlePracticeQuestion}>Practice Questions</li>
                                    <li className={title == "Homework" ? "activeLi testStatusGood m2 newClass1 org-pencil" : "testStatusGood m2 newClass1 org-pencil"} id={topicId} onClick={handleHomeWork}>Homework</li>
                                </ul>

                                <div className="rp-2 tpd-m-h">
                                    {change && title == 'Lesson'
                                        ?
                                        <NewLesson topicID={topicId} chapterId={chapterId} topicTitle={title} color={dynamicColor}></NewLesson>
                                        :
                                        ""}

                                    {change && title == 'Practice Questions'
                                        ?
                                        <NewPracticeQuestion topicID={topicId} type="practisequestion" color={dynamicColor} />
                                        :
                                        ""}

                                    {change && title == 'Homework'
                                        ?
                                        <NewHomeWork topicID={topicId} type="homework" color={dynamicColor} />
                                        :
                                        ""}

                                </div>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            
            {/* <FooterInner /> */}
        </div>
    );
}
export default NewTopicDeatils;