import React, { useState, useEffect } from "react";
import { Header,Loader ,FooterInner } from "../includes";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory } from "react-router-dom";
import { getSubject } from "../../redux/models/SubjectApi/action";
import { getSubjectHomeWork } from "../../redux/models/TopicApi/action";
import {injectModels} from '../../redux/injectModels';
import ScrollArea from "react-scrollbar";
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment';


const HomeWorkList = (props)=> {

let isSubject = props.location.state.isSubject
let subjectIda = props.location.state.subjectIda;
let subId = props.location.state.subject;

  const subjectId =  props && props.location && props.location.state && props.location.state.subjectId;
  const [dynamicColor, setDynamicColor] = useState("");
  const [topics, setTopics] = useState("");  
  const [topicsIds ,setTopicsIds] = useState("");  
  const [dataLength ,setDataLength] = useState("");  

  let { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setDynamicColor(localStorage.getItem(dynamicColor));
    let data = {
      subjectId :subjectIda,
    }
    if(isSubject){
      const resultApi = props.topicApi.getSubjectHomeWork(data);
      resultApi.then((result)=>{            
          if(result){          
              if(result.success == true){  
                let topicId = result.data;
                topicId.map((data)=>{
                  setTopicsIds(data._id)
                  })                
                 setTopics(result.data);
              }else{
                  setTopics(result.data);
              }
          }
      });
    } else {
      let data = {
        subjectId : subjectId, 
        type : "tests"
      }
      const resultApi = props.topicApi.getSubjectHomeWork(data);
      resultApi.then((result)=>{            
          if(result){          
              if(result.success == true){  
                let topicId = result.data;
                topicId.map((data)=>{
                  setTopicsIds(data._id);
                  })                
                 setTopics(result.data);                 
                 let dataa = result.data;
                  dataa.map((topic)=>{
                    setDataLength(topic.result)
                  })                  
              }else{
                  setTopics(result.data);
              }
          }
      });
    }   
}, []);

if(topics && topics.length === 0){
  let hidebox = document.getElementsByClassName("oopsImage");
  if(hidebox.length > 0){
      document.getElementsByClassName("oopsImage")[0].style.display = "flex";
  }
}


function setTime(seconds =""){
  var hrs = ~~(seconds / 3600);
  var mins = ~~((seconds % 3600) / 60);
  var secs = ~~seconds % 60;
  var ret = "";
  if (hrs > 0) {
      ret += "" + hrs + " hour " + (mins < 10 ? "0" : "");
  }  
  if(mins > 0){
      ret += "" + mins + " min " + (secs < 10 ? "0" : "");
  }
  if(secs > 0){
      ret += "" + secs+ " sec";
  }
  return ret;  
}

  const handle =(e)=>{  
    let  testId = e.target.getAttribute("data-id");
    let type  = e.target.getAttribute("data-type");

    let resultType  = e.target.getAttribute("data-resultType");

    if(type == "result"){
      
      let resultType  = e.target.getAttribute("data-resultType");
      let passedMarks = e.target.getAttribute("data-passedMarks");
      //if(resultType = 'automated')
      props.history.push({
        pathname:`/home-work-result/${testId}`,      
        state:{
          testId:topicsIds,
          subjectId :subjectId , 
          resultType : resultType,
          passedMarks : passedMarks,
          subject :props.location.state.subject
        }
      })
    }else {
      props.history.push({
        pathname:`/home-work-questions/${testId}`,
        state: {
          testId:testId,
          subjectId :subjectId , 
          subject :props.location.state.subject
          }
      })
    }
  }
  
  const handleBack =(e)=>{
    props.history.push({
      pathname: `/home-work-subject`
    })
  }

  return (
    <div>
    <Header/>
    <div id="main" className="InnerClass inner-page-main2">
        <div className="container">
            <div className="row justify-content-center">
             <div className="col col-xl-12 mt-5">
             <div className="rp-2 rp-small"> 
                <div className="report-card p-3 p-xxl-4">
                <div className="ls-heading mb-3">
                <h2 style={{background: dynamicColor}}>Subject : {props.location.state.subject} </h2>
                </div>
                
                { !topics  ? <Loader/> :
                        topics && topics.length > 0 ? 
                        <ScrollArea
                            speed={0.8}
                            className="scroll-div"
                            contentClassName="content"
                            horizontal={false}
                        >
                        <div id="scroll-div" className="table-responsive">
                        <table className= {`table mb-0 reportTable tbl`} >
                        <thead>
                            <tr>
                                  <th scope="col" style={{textAlign:'center'}}>Completed</th>
                                  <th scope="col">Homework Test</th>
                                  <th scope="col">Date</th>
                                  <th scope="col">Result</th>
                            </tr>             
                        </thead> 
                       <tbody>                        
                        {!topics  ? <Loader/> :                            
                            topics && topics.length > 0  ? topics.map((topic,index) => (
                              <>                              
                            <tr key={topic._id} className="cursorPointer">
                              {topic.result && topic.result.length ? 
                              <>
                                <td style={{textAlign:'center'}}>
                                  <img src="../assets/img/green-tick.jpg" className="small-img" /> 
                                </td>
                                <td onClick={handle} data-type="result"
                                data-resultType={topic.result[0].reportType}
                                data-passedMarks={topic.result[0].averageScore}
                                data-id={topic.result[0].userTestId} value={topic.result[0].userTestId} 
                                title="Please click to attempt Daily homework">Mini-Test</td>
                                </>
                              :
                              <>
                                <td style={{textAlign:'center'}}>
                                  <img src="../assets/img/red-tick.jpg" className="small-img" />
                                </td>
                                <td onClick={handle} data-type="attampt"                                  
                                data-id={topic._id} value={topic._id} title="Please click to attempt Daily homework">Mini-Test</td>
                                </>
                                }
                              <td>{moment(topic.title).format(('DD-MM-YYYY'))}</td>
                                
                              
                                {topic && topic.result && topic.result.length
                                ?  (parseFloat(topic.result[0].averageScore).toFixed(2)) ? <td onClick={handle} 
                                
                                data-type="result"
                                data-resultType={topic.result[0].reportType}
                                data-passedMarks={topic.result[0].averageScore}
                                data-id={topic.result[0].userTestId} value={topic.result[0].userTestId} 
                                 style={(topic.result[0].averageScore <= 50) ? { color: "red", fontWeight: "800", cursor: "pointer" } : (topic.result[0].averageScore >= 50.01 && topic.result[0].averageScore <= 69) ? { color: "#f1d50a", fontWeight: "800", cursor: "pointer" } : { color: "green", fontWeight: "800", cursor: "pointer" }}>
                                  {(parseFloat(topic.result[0].averageScore).toFixed(2))} % </td>
                                  : '0 %'
                                : <td> {'Not Attempted'}  </td>
                                }
                            </tr>
                            </>
                        )) : ""
                        }                       
                       </tbody>
                        </table></div>
                          </ScrollArea>
                           :
                            <div className="oopsImage" style={{display:"flex"}}>
                            <img src="/assets/img/oops-content.svg" alt="Ooops Img"/>
                            <h1>No Active HomeWork exits.</h1>
                        </div>                                
                        }                 
                    </div>
                </div>
                </div>
                <div className="col-md-12 text-center mt-2 mt-xl-3">
                    <button className="bck-btn px-2 bac-btn" type="button" onClick={handleBack}>
                        <i className="fas fa-long-arrow-alt-left"></i>
                        <span> Back </span>                        
                    </button>
                </div>
            </div>
        </div>
    </div>
    <FooterInner />
</div>
  )
}
export default injectModels(['chapterApi', 'subjectApi' ,'topicApi'])(HomeWorkList);

