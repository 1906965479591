import React,  { useState, useEffect }  from "react";
import { NavItem, Nav, Form, Input } from 'reactstrap';
import PropTypes from 'prop-types';
import {injectModels} from '../../redux/injectModels';
import { ChatContent } from "./";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';

const ChatListItem = (props) => {
    const [users, setUsers] = useState([]);
    const [name, setName] = useState("");
    const [searchUser, setSearchUser] = useState("");

    useEffect(() => {

        if(!props.chatApi.users){

            props.chatApi.getUsers();
        }else{
            const {getuser} = props.chatApi.users;
            setUsers(props.chatApi.users);
        }
    }, [props.chatApi.users]);

    function handleClick(id,name) {
        const data = {
            receiver_id: id,
        }
        const ids = {
            name:name,
            id:id,
        }
        props.chatApi.saveIds(ids);
        props.chatApi.getChat(data);
    }

    const handleChange = e =>{
        e.preventDefault();
        setSearchUser(e.target.value);
    }

    return (
     <React.Fragment>
        <Nav className="list-unstyled d-block mailbox listing-over">
            <div className="p-3 border-bottom cl-search">
                <h5 className="card-title">Search Contact</h5>
                <Form>
                    <div className="position-relative has-icon-left">
                        <Input
                            className="form-control"
                            id="searchUser"
                            name="searchUser"
                            type="text"
                            placeholder="Type here to search..."
                            onChange={handleChange}
                        />
                    </div>
                </Form>
            </div>
            <div className="message-center" style={{ height: "calc(100vh - 274px)" }}>
               
                <NavItem>
                    {users && users.length > 0 ? users.filter(name => name.firstName.includes(searchUser)).map((user) => (
                        <span className="message-item" key={user._id} onClick={() => handleClick(user._id, user.firstName)}>
                            <span className="user-img">
                                <span className="rounded-circle" width="" >{user.firstName.toUpperCase().charAt(0)}</span>
                            </span>
                            <div className="mail-contnet">
                            <h5 className="message-title" title={ user.subjectToExpert.toString()}>{user.firstName}</h5>
                             <p title={ user.subjectToExpert.toString()}>({ user.subjectToExpert.toString().substring(0, 10)}...)</p>
                         </div>
                        </span>
                    )) : "No teacher found"}
                </NavItem>
            </div>
        </Nav>
    </React.Fragment>

   );
};

export default injectModels(['chatApi','auth'])(ChatListItem);