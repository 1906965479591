import axios from 'axios';
import { API_ENDPOINT } from '../constants';
import { JWT } from '../helpers';

const getUser = async() => {
    try{
    	const accessToken = JWT.getJWT();
        const response = await axios.post(`${API_ENDPOINT}/user/get-student-profile/`, {"Authorization": `Bearer ${accessToken}`}, {headers:{"Content-Type":"application/json" ,"Authorization": `Bearer ${accessToken}`}});
        if(response.status === 200) {
            return response.data;            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const updateUser = async(data) => {
    try{
          const accessToken = JWT.getJWT();
          const response = await axios.post(`${API_ENDPOINT}/user/update-student-profile`, JSON.stringify(data), {headers:{"Content-Type":"application/json","Authorization": `Bearer ${accessToken}`}});
          if(response.status === 200) {
            return response.data;            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}

const changePassword = async(data) => {
    try{
          const accessToken = JWT.getJWT();
          const response = await axios.post(`${API_ENDPOINT}/auth/change-password`, JSON.stringify(data), {headers:{"Content-Type":"application/json","Authorization": `Bearer ${accessToken}`}});
          if(response.status === 200) {
            return response.data;            
        } else {
            return Promise.reject("An api error was encountered");
        }
    } catch(error) {
        return Promise.reject(error);
    }  
}


const UserApi = {
    getUser,
    updateUser,
    changePassword,
}
export {UserApi};