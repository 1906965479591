import React, { useState, useEffect } from "react";
import { Routes } from "../../constants";
import { Link, useHistory } from "react-router-dom";
import { Header, Footer } from "../includes";
import axios from 'axios';
import { API_ENDPOINT } from '../../constants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactBootstrapCarousel from "react-bootstrap-carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import { NotificationApi } from '../../services/NotificationApi'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Accordion from 'react-bootstrap/Accordion'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';




const EmailRegx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const number = /^\d{10}$/;

const PrivecyPolicy = () => {
  const history = useHistory();

  const [classSubject, setClassSubject] = useState('');
  const [error, setError] = useState('');
  const [staticPagesData, setStaticPagesData] = useState([]);

  const [privacyPolicyContent, setPrivacyPolicyContent] = useState('');
  const [privacyPolicyHead, setPrivacyPolicyHead] = useState('');
  const [privacyPolicyTitle, setPrivacyPolicyTitle] = useState('');


  useEffect(() => {
    getAllHomePageData();
  }, []);

  const handelBack = e => {
    history.goBack();
  }

  const getAllHomePageData = () => {
    NotificationApi.getPrivacyPolicyData("privacy-policy")
    .then((response) => {
     // debugger;
      const data = response.data;

          setPrivacyPolicyContent(data.text)
          setPrivacyPolicyHead(data.subTitle)
          setPrivacyPolicyTitle(data.title)
           
    }).catch((err) => {
      setError("Network error");
    })

  }


  return (
    <>
      <Header classlist={classSubject} />
      <div id="home" className="pg-tp-m">
      </div>
      <main id="main">



      <section id="privacy" className="privacy pb-sec">
          <div className="container">
            <div className="row">
              <div className="col-md-12">

                <div className="section-title" style={{marginTop:"15px"}}>
                  <h2>{privacyPolicyTitle}</h2>

                  

                  {ReactHtmlParser(privacyPolicyHead)}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 content">

                {ReactHtmlParser(privacyPolicyContent)}

              </div>
            </div>
          </div>
        </section>
        <div style={{ marginLeft: "108px", marginBottom: "40px" }}>
          <button className="btn btn-danger" type="button" onClick={handelBack}>
            <i className="fas fa-long-arrow-alt-left"></i>
            <span> Back </span>
          </button>




        </div>
      </main>

      <Footer />
    </>
  );
}
export default PrivecyPolicy;
