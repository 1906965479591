import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Routes } from "../../constants";
import { Link, useHistory } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Header, Footer, FooterInner } from "../includes";
import InputGroupAddon from 'bee-input-group-addon';
import { Auth } from '../../services';
import { injectModels } from '../../redux/injectModels';
//import "/node_modules/font-awesome/css/font-awesome.css";
import { InputGroup, InputGroupText, Input, Form, Row, Col, Label, Button } from "reactstrap";
import axios from 'axios';
import { API_ENDPOINT } from '../../constants';
import { UserApi, StudentClassApi } from '../../services';
import { getUser } from "../../redux/models/UserApi/action";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';

const EditProfile = (props) => {
  const [userId, setUserId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [dob, setDOB] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [subscrptionStartDay, setSubscrptionStartDay] = useState("");
  const [parentEmail, setParentEmail] = useState("");
  const [schoolYear, setSchoolYear] = useState("");
  const [school, setSchool] = useState("");
  const [studentClass, setStudentClass] = useState("");
  const [studentDay, setStudentDay] = useState("");
  const [studentClassArr, setStudentClassArr] = useState([]);
  const [username, setUsername] = useState("");
  const [parentName, setParentName] = useState("");
  const [address, setAddress] = useState("");
  const [error, setError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [contactError, setContactError] = useState('');
  const [dobError, setDOBError] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [subscrptionStartDayError, setSubscrptionStartDayError] = useState('');

  const [parentEmailError, setParentEmailError] = useState('');
  const [schoolYearError, setSchoolYearError] = useState('');
  const [schoolError, setSchoolError] = useState('');
  const [studentClassError, setStudentClassError] = useState('');
  const [parentNameError, setParentNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [success, setSuccess] = useState('');
  const [studentClassName, setStudentClassName] = useState('');


  const [houseNoName, setHouseNoName] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');
  const [postCode, setPostCode] = useState('');


  const [houseNoNameError, setHouseNoNameError] = useState('');
  const [streetError, setStreetError] = useState('');
  const [cityError, setCityError] = useState('');
  const [postcodeError, setPostCodeError] = useState('');


  const [programme, setProgramme] = useState('');
  const [programmeError, setProgrammeError] = useState('');
  const [optionalEmailAddress, setOptionalEmailAddress] = useState("");
  const [optionalEmailAddressError, setOptionalEmailAddressError] = useState("");

  const EmailRegx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const history = useHistory();

  useEffect(() => {

    if (!props.userApi.user) {

      props.userApi.getUser();
    } else {
      const { user } = props.userApi;

      const dateOfBirth = moment(user.dob).format('YYYY-MM-DD');
      setFirstName(user.firstName);
      //  setLastName(user.lastName);
      setEmail(user.email);
      setUsername(user.username);
      setDOB(dateOfBirth);
      setContactNumber(user.contactNumber);
      setSubscrptionStartDay(user.subscrptionStartDay);
      setParentEmail(user.parentEmail);
      setSchoolYear(user.schoolYear);
      setSchool(user.schoolName);
      setStudentClass(user.studentClass);
      setUserId(user._id);
      //    setStudentDay(user.classDay);
      setParentName(user.parentName);
      setAddress(user.address);
      setStudentClassName(user.studentClassTitle);
      setHouseNoName(user.houseNoName);
      setStreet(user.street);
      setCity(user.city);
      setPostCode(user.postcode);
      setProgramme(user.programme);
      setOptionalEmailAddress(user.parentAddEmail);

    }

  }, [props.userApi.user]);

  useEffect(() => {
    StudentClassApi.getAllClass()
      .then((response) => {
        const data = response.data;
        if (data.success) {
          const title = data.data;
          //setStudentClassArr(title);  
          // for(const studentClassName of data.data){
          //   if(props.userApi.user.studentClass == studentClassName._id){
          //     setStudentClassArr(studentClassName.title)
          //   }
          // }        
        } else {
          setError(data.message);
        }
      }).catch((err) => {
        setError("Network error");
      })
  }, [])

  const handleDate = () => {
    var dtToday = new Date();
    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();
    if (month < 10)
      month = '0' + month.toString();
    if (day < 10)
      day = '0' + day.toString();

    var maxDate = year + '-' + month + '-' + day;
    return maxDate;
  }

  const handelBack = e => {
    props.history.push(Routes.DASHBOARD);
  }
  const handelProgramme = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setProgrammeError("Package is required");
      setProgrammeError("");
    }
    setProgramme(inputValue.trimStart());
  }

  const handelHouseNoName = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setHouseNoNameError("House name/number is required");
      setHouseNoNameError("");
    }
    setHouseNoName(inputValue.trimStart());
  }
  const handelStreet = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setStreetError("Street is required");
      setStreetError("");
    }
    setStreet(inputValue.trimStart());
  }
  const handelCity = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setCityError("City is required");
      setCityError("");
    }
    setCity(inputValue.trimStart());
  }
  const handelPostcode = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setPostCodeError("Postcode is required");
      setPostCodeError("");
    }
    setPostCode(inputValue.trimStart());
  }

  const handelFirstName = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setFirstNameError("Full Name is required");
    } else if (inputValue.length < 3) {
      setFirstNameError("Full Name not be less than 3 character");
    } else {
      setFirstNameError("");
    }
    setFirstName(inputValue.trimStart());
  }

  // const handelLastName = e =>{ 
  //   //e.preventDefault();
  //   e.preventDefault();
  //    let inputValue = e.target.value;
  //    if (inputValue==="") {
  //     setLastNameError("Last name is requried");
  //    }else if(inputValue.length<3){
  //       setLastNameError("Last Name not be less than 3 character");
  //    }else{
  //     setLastNameError("");
  //    }
  //    setLastName(inputValue.trimStart());
  //  }

  const handleEmail = e => {
    //e.preventDefault();
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setEmailError("Email is required");
    } else if (EmailRegx.test(email) === false) {
      setEmailError("Enter a valid email");
    } else {
      setEmailError("");
    }

    setEmail(inputValue.trimStart());
  }



  const handleOptionalEmailAddress = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setOptionalEmailAddressError("Parent optional Email is requried");
    } else if (EmailRegx.test(inputValue) === false) {
      setOptionalEmailAddressError("Enter a valid email");
    } else {
      setOptionalEmailAddressError("");
    }
    setOptionalEmailAddress(inputValue.trimStart());
  }

  const handlePassword = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setPasswordError("Password is required");
    } else if (inputValue.length < 6) {
      setPasswordError("Password not be less than 6 character");
    } else {
      setPasswordError("");
    }
    setPassword(inputValue.trimStart());
  }

  const handleSchoolYear = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setSchoolYearError("Year is requried");
    } else {
      setSchoolYearError("");
    }
    setSchoolYear(inputValue.trimStart());
  }

  const handleSchool = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setSchoolError("School is requried");
    } else {
      setSchoolError("");
    }
    setSchool(inputValue.trimStart());
  }

  const handleAddress = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setAddressError("Address is requried");
    } else {
      setAddressError("");
    }
    setAddress(inputValue.trimStart());
  }

  const handleContactNumber = e => {
    e.preventDefault();
    const contactRegx = /^\d{10}$/;
    let inputValue = e.target.value;
    if (inputValue === "") {
      setContactError("Contact Number is Required");
    } else if (contactRegx.test(inputValue) === false) {
      setContactError("Allows 10 digit contact number.");
    } else {
      setContactError("");
    }
    setContactNumber(inputValue.trimStart());
  }

  const handelStudentClass = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setStudentClassError("Student Class is requried");
    } else {
      setStudentClassError("");
    }
    setStudentClass(inputValue.trimStart());
  }

  const handleUsername = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setUsernameError("Username is requried");
    } else {
      setUsernameError("");
    }
    setUsername(inputValue.trimStart());
  }

  const handleDob = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setDOBError("DOB is requried");
    } else {
      setDOBError("");
    }
    setDOB(inputValue.trimStart());
  }

  // const handleSubscrptionDay = e =>{ 
  //   e.preventDefault();
  //    let inputValue = e.target.value;
  //    if (inputValue==="") {
  //     setSubscrptionStartDayError("Subscrption Day is requried");
  //    }else{
  //     setSubscrptionStartDayError("");
  //    }
  //    setSubscrptionStartDay(inputValue.trimStart());
  // }

  const handleParentEmail = e => {
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setParentEmailError("Parent Email is requried");
    } else if (EmailRegx.test(inputValue) === false) {
      setParentEmailError("Enter a valid email");
    } else {
      setParentEmailError("");
    }
    setParentEmail(inputValue.trimStart());
  }

  const handleParentName = e => {
    //e.preventDefault();
    e.preventDefault();
    let inputValue = e.target.value;
    if (inputValue === "") {
      setParentNameError("Parent Name is required");
    } else if (inputValue.length < 3) {
      setParentNameError("Parent Name not be less than 3 character");
    } else {
      setParentNameError("");
    }
    setParentName(inputValue.trimStart());
  }

  // const handleAddress = e =>{
  //   e.preventDefault();
  //   let inputValue = e.target.value;
  //   if (inputValue==="") {
  //     setAddressError("Address is requried");
  //   }else{
  //     setAddressError("");
  //   }
  //   setAddress(inputValue.trimStart());
  // }

  // const handleSchoolYear = e =>{ 
  //   e.preventDefault();
  //    let inputValue = e.target.value;
  //    if (inputValue==="") {
  //     setSchoolYearError("Year is requried");
  //    }else{
  //     setSchoolYearError("");
  //    }
  //    setSchoolYear(inputValue.trimStart());
  // }

  // const handleSchool = e =>{ 
  //   e.preventDefault();
  //    let inputValue = e.target.value;
  //    if (inputValue==="") {
  //     setSchoolError("School is requried");
  //    }else{
  //     setSchoolError("");
  //    }
  //    setSchool(inputValue.trimStart());
  // }

  const handleSubmit = e => {
    e.preventDefault();
    let error = false;
    const contactRegx = /^\d{10}$/;
    if (firstName === "") {

      error = true;
      setFirstNameError("Full Name is required");
    }

    // else if(lastName === ""){

    //     error = true;
    //     setLastNameError("Last Name is required");
    // }
    else if (email === "") {

      error = true;
      setEmailError("Email is required");
    } else if (emailError != "") {

      error = true;
      setEmailError(emailError);
    } else if (username === "") {

      error = true;
      setUsernameError("Username is required");
    } else if (usernameError != "") {

      error = true;
      setUsernameError(usernameError);
    } else if (contactNumber === "") {
      error = true;
      setContactError("Contact Number is required");
    } else if (contactRegx.test(contactNumber) === false) {
      error = true;
      setContactError("Allows only digit.");
    } else if (parentName === "") {
      error = true;
      setParentNameError("Please select parent name");
    } else if (parentEmail === "") {
      error = true;
      setParentEmailError("Please select parent email");
    } else if (schoolYear === "") {
      error = true;
      setSchoolYearError("Please select school year");
    } else if (school === "") {
      error = true;
      setSchoolError("Please select school name");
    } else if (houseNoName === "") {
      error = true;
      setHouseNoNameError("House No/Name is requried");
    } else if (street === "") {
      error = true;
      setStreetError("Street is requried");
    } else if (city === "") {
      error = true;
      setCityError("City is requried");
    } else if (postCode === "") {
      error = true;
      setPostCode("PostCode is requried");
    } else if (dob === "") {
      error = true;
      setDOBError("DOB is requried");
    }
    // else if(subscrptionStartDay === ""){
    //     error = true;
    //     setSubscrptionStartDayError("Subscrption Start Day is requried");
    // }
    else if (programme === "") {
      setProgrammeError("Package is required");
    } else if (optionalEmailAddress === "" && EmailRegx.test(optionalEmailAddress) === false) {
      setOptionalEmailAddressError("Please enter parent email");
    }
    else {
      setDOBError("");
      setSubscrptionStartDayError("");
      setFirstNameError("");
      //  setLastNameError("");
      setEmailError("");
      setPasswordError("");
      setContactError("");
      setStudentClassError("");
      setParentEmailError("");
      setSchoolYearError("");
      setSchoolError("");
      setParentNameError("");
      setAddressError("");
      setHouseNoNameError("");
      setStreetError("");
      setCity("");
      setPostCodeError("");
      setProgrammeError("");
      setOptionalEmailAddressError("");
    }
    if (error) {

      return false;
    } else {
      const data = {
        userId: userId,
        firstName: firstName,
        lastName: "",
        email: email,
        password: password,
        dob: dob,
        contactNumber: contactNumber,
        subscrptionStartDay: "",
        parentEmail: parentEmail,
        schoolYear: schoolYear,
        school: school,
        studentClass: studentClass,
        username: username,
        parentName: parentName,
        address: '',
        houseNoName: houseNoName,
        street: street,
        city: city,
        postcode: postCode,
        programme: programme,
        parentAddEmail: optionalEmailAddress

      };

      UserApi.updateUser(data)
        .then((response) => {
          if (response.success) {
            // setSuccess(response.message);
            toast.success(response.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            document.getElementById("updateForm").reset();

          } else {
            setSuccess('');
            setError(response.message);
            toast.error(response.message, {
              position: "top-center",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          }
        }).catch((error) => {
          setError("Enter Correct Data");
          setSuccess('');
        });
    }
  }
  return (
    <div>
      <Header />
      <div id="main" className="InnerClass inner-page-main">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-9 col-md-10 d-flex flex-column align-items-center justify-content-center">
              <ToastContainer />
              <div className="section-title2 mb-0 pb-0">
                <h2 className="">Update Your Account</h2>
              </div>
              <div className="card mb-4 login-form">
                <div className="card-body pt-3">
                  <div className="col-12 mb-3">
                    {/* <p className="text-center px-3">
                        Lorem ipsum dolor sit amet, consectetur, adipisicing elit. Quis reiciendis, optio dolore veniam omnis corporis harum aut eum qui sapiente mollitia eveniet.
                      </p>*/}
                  </div>
                  <form className="row g-3 needs-validation" onSubmit={handleSubmit} id="updateForm">

                    <h3 style={{ textAlign: 'center' }}> Parent Details</h3>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Parent Full Name</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                        <input className="form-control" type="text" id="parentName" pattern="^[( )a-zA-Z]+$"  name="parentName" value={parentName ? parentName : ""} autoComplete="off" onChange={handleParentName} />
                        {parentNameError && <div className="invalid-feedback">{parentNameError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Parent Email</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                        <input className="form-control" type="text" id="parentEmail" name="parentEmail" value={parentEmail ? parentEmail : ""} autoComplete="off" onChange={handleParentEmail} />
                        {parentEmailError && <div className="invalid-feedback">{parentEmailError}</div>}
                      </div>
                    </div>




                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Additional Parent Email Address</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                        <input className="form-control" type="text" id="parentEmail" name="parentEmail" value={optionalEmailAddress ? optionalEmailAddress : ""} autoComplete="off" onChange={handleOptionalEmailAddress} />

                      </div>
                    </div>
                    {/* <div className="col-12 col-md-6">
                        <label htmlFor="" className="form-label">Address</label>
                        <div className="input-group has-validation">
                          <span className="input-group-text"><i class="fas fa-map-marker"></i></span>
                          <input className="form-control" type="text" id="address" name="address" value={address ? address : ""} autoComplete="off" onChange={handleAddress}/>
                          {addressError && <div className="invalid-feedback">{addressError}</div>}
                        </div>
                      </div> */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Contact Number</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-phone-alt"></i></span>
                        <input type="Number" className="form-control" id="contactNumber" value={contactNumber ? contactNumber : ""} name="contactNumber" onChange={handleContactNumber} />
                        {contactError && <div className="invalid-feedback">{contactError}</div>}
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">house No/Name</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                        <input className="form-control" type="text" id="houseNoName" name="house No/ Name" value={houseNoName ? houseNoName : ""} autoComplete="off" onChange={handelHouseNoName} />
                        {houseNoNameError && <div className="invalid-feedback">{houseNoNameError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Street</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                        <input className="form-control" type="street" id="street" name="street" value={street ? street : ""} autoComplete="off" onChange={handelStreet} />
                        {streetError && <div className="invalid-feedback">{streetError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">City</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i class="fas fa-map-marker"></i></span>
                        <input className="form-control" type="text" id="city" pattern="^[( )a-zA-Z]+$"  name="city" value={city ? city : ""} autoComplete="off" onChange={handelCity} />
                        {cityError && <div className="invalid-feedback">{cityError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">PostCode</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-phone-alt"></i></span>
                        <input type="text" className="form-control" id="postCode" name="postCode" value={postCode ? postCode : ""} onChange={handelPostcode} />
                        {postcodeError && <div className="invalid-feedback">{postcodeError}</div>}
                      </div>
                    </div>
                    <h3 style={{ textAlign: 'center' }}>Student Details</h3>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Student Full Name</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-user"></i></span>
                        <input className="form-control" type="text" pattern="^[( )a-zA-Z]+$"  id="firstname" name="firstname" value={firstName ? firstName : ""} onChange={handelFirstName} />
                        {firstNameError && <div className="invalid-feedback">{firstNameError}</div>}
                      </div>
                    </div>

                    {/* <div className="col-12 col-md-6">
                        <label htmlFor="" className="form-label">Last Name</label>
                        <div className="input-group has-validation">
                          <span className="input-group-text"><i className="fas fa-user"></i></span>
                          <input type="text" className="form-control" id="lastname" name="lastname" value={lastName ? lastName : ""} onChange={handelLastName}/>
                          {lastNameError && <div className="invalid-feedback">{lastNameError}</div>}
                        </div>
                      </div> */}

                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Student Email Adress</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-envelope"></i></span>
                        <input type="text" className="form-control" id="email" name="email" value={email ? email : ""} onChange={handleEmail} autoComplete="off" readOnly />
                        {emailError && <div className="invalid-feedback">{emailError}</div>}
                      </div>
                    </div>
                    {/*<div className="col-12 col-md-6">
                        <label htmlFor="" className="form-label">Username</label>
                        <div className="input-group has-validation">
                          <span className="input-group-text"><i className="fas fa-user"></i></span>
                          <input type="text" className="form-control" id="userName" value={username ? username : ""} name="username" onChange={handleUsername} autoComplete="off" readOnly/>
                        </div>
                      </div>*/}
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Student Date of Birth</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-calendar"></i></span>
                        <input type="date" className="form-control" id="dob" name="dob" value={dob ? dob : ""} max={handleDate()} onChange={handleDob} />
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">School</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-school"></i></span>
                        <input type="text" className="form-control" id="school" pattern="^[( )a-zA-Z]+$"  value={school ? school : ""} name="school" onChange={handleSchool} />
                        {schoolError && <div className="invalid-feedback">{schoolError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">School Year</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-calendar-check"></i></span>
                        <input type="number" min="6" max="11" step="1" className="form-control" id="schoolYear" value={schoolYear ? schoolYear : ""} name="schoolYear" onChange={handleSchoolYear} />
                        {schoolYearError && <div className="invalid-feedback">{schoolYearError}</div>}
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Select Class</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text"><i className="fas fa-university"></i></span>
                        <input type="text" className="form-control" id="studentClass" name="studentClass" value={studentClassName ? studentClassName : ""} readOnly />
                      </div>
                    </div>
                    {/* <div className="col-12 col-md-6">
                        <label htmlFor="" className="form-label">Selected Day</label>
                        <div className="input-group has-validation">
                          <span className="input-group-text"><i className="fas fa-calendar-check"></i></span>
                          <input type="text" className="form-control" id="selectDay" name="selectDay" value={studentDay ? studentDay : ""} readOnly/>
                        </div>
                      </div> */}
                    {/* <div className="col-12 col-md-6">
                        <label htmlFor="" className="form-label">Subscription Date</label>
                        <div className="input-group has-validation">
                          <span className="input-group-text"><i className="fas fa-calendar-alt"></i></span>
                          <input className="form-control" type="date" id="subscrptionStartDay" value={subscrptionStartDay ? subscrptionStartDay : ""} max={handleDate()} name="subscrptionStartDay" onChange={handleSubscrptionDay}/>
                          {subscrptionStartDayError && <div className="invalid-feedback">{subscrptionStartDayError}</div>}
                        </div>
                      </div> */}
                    <div className="col-12 col-md-6">
                      <label htmlFor="" className="form-label">Package</label>
                      <select id="schoolYear" name="lebel" className="form-control" value={programme} onChange={handelProgramme} autoComplete="off"  >
                        <option value="">Select Package</option>
                        <option value="online system">Online System</option>
                        <option value="face to face">Face to Face</option>
                      </select>
                    </div>

                    <div className="col-12">
                      <div className="pre-next-btn-row  justify-content-center">
                        <button className="org-btn rounded-pill px-4 pr-2 pl-2 mr-2 ml-2" type="button" onClick={handelBack}>
                          <i className="fas fa-long-arrow-alt-left"></i>
                          <span> Back </span>
                        </button>
                        <button className="success-btn rounded-pill px-4" type="submit" style={{ marginLeft: "10px" }}>
                          <span> Update Profile </span>
                        </button>
                      </div>
                      {/*<div className="row justify-content-center">
                          <div className="col-12 col-md-6">
                            <button className="btn green-btn w-100" type="submit">Update Profile</button>
                          </div>
                        </div>*/}
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
      <FooterInner />
    </div>
  );
};

export default injectModels(['auth', 'userApi'])(EditProfile);
