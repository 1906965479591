import React, { useState, useEffect, Fragment ,useRef} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useSelector } from "react-redux";
import { injectModels } from '../../redux/injectModels';
import { ChatSearch, ChatListItem, ChatMsgForm } from "./";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import ScrollArea from "react-scrollbar";

const ChatContent = (props) => {
  const footerRef = useRef();

  const [isShow, setIsShow] = useState("");
  const [allChat, setAllChat] = useState([]);
  const [isReload, setIsReload] = useState(false);

  
  useEffect(() => {
    // 
    let chatObjHtml =  document.getElementsByClassName("unreadMessageCounter");
    chatObjHtml[0].innerHTML = 0;
      
    if (!props.chatApi.chat) {
      props.chatApi.getChat();
      //scrollElement();
    } else {
      setAllChat(props.chatApi.chat);
      if(!isReload){
        setTimeout(function() {        
          document.getElementById('lastKey').scrollIntoView({
            behavior: 'smooth'
          });
          setIsReload(true);
        }, 1000);
      }
      

    }
   // scrollElement();
  }, [props.chatApi.chat])   


  

  useEffect(() => {
    const interval = setInterval(() => {         
      if (!props.chatApi.chat) {
        props.chatApi.getChat();
      } else {       
        setAllChat(props.chatApi.chat);
      }

    }, 15000);

    // setTimeout(function() {        
    //   document.getElementById('lastKey').scrollIntoView({
    //     behavior: 'smooth'
    //   });
    // }, 1000);

    return () => clearInterval(interval);
  }, []);


  function checkFileExtension(imagePath) {
    return (imagePath = imagePath.substr(1 + imagePath.lastIndexOf("/")).split('?')[0]).split('#')[0].substr(imagePath.lastIndexOf("."))
  }
 



  return (
    <Fragment>
      <div className="cps-1" >
        <div className="d-flex align-items-center">
          <div>
          </div>
          {allChat && allChat.length ?
            <div className="user-title p-2 d-flex w-100">
              <img src="../assets/img/chat-icon.svg" alt="" width="25" className="me-2" />
              <strong>Chat</strong>
            </div> : ""
          }
        </div>
        <div id="chat-feed" className="chat-room-m-h" >
          <ScrollArea
            speed={0.8}
            className="rowcontentcustom5"
            contentClassName="content"
            horizontal={false}
          >
            <ul id="chat-list" className="chat-list p-3">
              {allChat && allChat.length > 0 ? allChat.map((chat, key) => (
                <li className="lsits" id={ (allChat.length == key+1) ? "lastKey" : "key_"+key }> 
                  {chat.sender_id == props.auth.id ?
                    <div className="chat-item d-flex mt-0 justify-content-end" key={chat._id}>
                      <div className="chat-panel-content">
                        <span className="box bg-light-info">{ReactHtmlParser(chat.message)}</span>
                        <br/>
                        {/* <span className="box bg-light-info"> <img src={chat.imagePath} width="150" />  </span> */}
                      </div>
                      <div className="chat-img">
                        <img src="../assets/img/profile-pic.png" alt="" width="33" className="ms-2" />
                      </div>
                    </div>
                    :
                    <div className="chat-item d-flex odd mt-0 justify-content-start" key={chat._id}>
                      <div className="chat-img">
                        <img src="../assets/img/profile-pic.png" alt="" title={chat.sender_name} width="33" className="me-2" />
                      </div>
                      <div className="chat-panel-content-teacher">                     
                        <span className="box bg-light-info">{ReactHtmlParser(chat.message)}</span>
                        <br/>
                        {/* <span className="box bg-light-info"> <img src={chat.imagePath} width="150" />  </span> */}
                      </div>
                      
                    </div>
                  }
                </li>
              )) :
                <li className="pow mt-2">
                  <h5 className="text-center"> <strong>Welcome to the LPI chat box.</strong></h5>
                  <h5 className="text-center"> <strong>What would you like to ask your teacher?</strong></h5>
                </li>
              }
            </ul>
          </ScrollArea>
          
        </div>

       </div>
      <ChatMsgForm />
    </Fragment>
  );
}

export default injectModels(['chatApi', 'auth'])(ChatContent);