import React, { useState } from "react";
import { Routes } from "../../constants";
import { Link } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import { Header,Footer } from "../includes";
import InputGroupAddon from 'bee-input-group-addon';
import {Auth} from '../../services';
import {injectModels} from '../../redux/injectModels';
//import "/node_modules/font-awesome/css/font-awesome.css";
import {InputGroup,InputGroupText,Input,Form,Row,Col,Label,Button} from "reactstrap";

const ResetPassword = (props) => {
  
  const [newPassword,setNewPassword] = useState('');
  const [confirmnewpassword,setConfirmPassword] = useState('');
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [newpasswordError,setNewPasswordError] = useState('');
  const [confirmNewpasswordError,setConfirmPasswordError] = useState('');
  const token = props.match.params.token;  
  const dateYear = new Date();
  const handleNewPassword = e =>{
    e.preventDefault();
    let inputValue = e.target.value;
    if(inputValue==="") {
      setNewPasswordError(" Password is Required");
    }else {
      setNewPasswordError("");
    }
    setNewPassword(inputValue.trimStart());
  }
  
  const handelConfirmPassword = e =>{
    e.preventDefault();
    let inputValue = e.target.value;
    if(inputValue==="") {
      setConfirmPasswordError("Confirm password is required");
    } else if(inputValue !== newPassword) {
      setConfirmPasswordError("Password not matched");
    } else {
       setConfirmPasswordError("");
    }
    setConfirmPassword(inputValue.trimStart());
  }
    

  const handelSubmit= e =>{

  e.preventDefault();

    let error = false;

    if(newPassword === ""){
         error = true;
         setNewPasswordError("New Password is Required");
    } else {
          setNewPasswordError("");
    }

    if(confirmnewpassword === ""){
      error = true;
      setConfirmPasswordError("Confirm Password is Required");
    } else if(newPassword !== confirmnewpassword) {
      error = true;
      setConfirmPasswordError("Password not matched");
    } else {
      setConfirmPasswordError("");
    }

    if(error){
      return false;
    }

    let data = {token:token,password:newPassword}
    Auth.ResetPassword(data)
     .then((response)=>{
      if(response.success) {
       props.history.push(Routes.LOGIN);

      } else {
        setSuccess("");
        setError(response.message);
      }
      }).catch((error)=>{
        setError("Please Enter Correct Password");
        setSuccess("");
      });  

    }

  return (
    <main id="" className="login-page">  
      <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-start pt-4">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-4 col-lg-5 col-md-7 d-flex flex-column align-items-center justify-content-center">

              <div className="d-flex justify-content-center py-3">
                <Link to="/" className="login-logo d-flex align-items-center w-auto">
                  <img src="/assets/img/lpi-logo.svg" alt="" className="img-fluid"/>
                </Link>
              </div>{/* End Logo */}

              <div className="section-title2 mb-0 pb-0">
                <h2 className="text-white">Reset Password</h2>              
              </div>

              <div className="card mb-4 login-form">

                <div className="card-body">              

                  <form className="row g-3 needs-validation" onSubmit ={handelSubmit} id="loginform">
                    <div className="col-12">
                      <label for="newPassword" className="form-label">New Password</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend"><i className="fas fa-lock"></i></span>
                        <input type="password" name="newPassword" className="form-control" id="newPassword" value={newPassword} onChange={handleNewPassword}/>
                      </div>
                      <div>{newpasswordError && <span className="invalid-feedback">{newpasswordError}</span>}</div>

                    </div>

                    <div className="col-12">
                      <label for="confirmnewpassword" className="form-label">Confirm Password</label>
                      <div className="input-group has-validation">
                        <span className="input-group-text" id="inputGroupPrepend"><i className="fas fa-lock"></i></span>
                        <input type="password" name="confirmnewpassword" className="form-control" id="confirmnewpassword" value={confirmnewpassword} onChange={handelConfirmPassword}/>
                      </div>
                      <div>{confirmNewpasswordError && <span className="invalid-feedback">{confirmNewpasswordError}</span>}</div>
                      <div>{error && <span className="invalid-feedback">{error}</span>}</div>
                      
                    </div>

                    <div className="col-12">
                      <button className="btn green-btn w-100" type="submit">Reset Password</button>
                    </div>
                  </form>

                </div>
              </div>

              <div className="text-center">
                <p className="text-white small">&copy; Copyright <span>LPI Tuition Ltd © {dateYear.getFullYear()}</span>. All Rights Reserved </p>
              </div>

             
            </div>
          </div>
        </div>

      </section>
    </main>
  );
};

export default ResetPassword;