export const Routes={
    DEFAULT:'/',
    REGISTER:'/auth/register',
    NOT_FOUND:'/404',
    LOGIN:"/auth/login",
    LOGOUT:"/auth/logout",
    DASHBOARD:"/dashboard",
    ABOUT:"/about",
    PRIVECY_POLICY:"/privacy-policy",
    TERM_CONDITION:"/terms-and-conditions",


    
    FAQ:"/faq",
    FORGOT_PASSWORD:"/auth/forgot-password",
    RESET_PASSWORD: "/auth/reset-password/:token",
    VERIFICATION: "/auth/verify/:token",
    CHAPTER_LIST:"/chapterlist/:id",
    REPORT:"/report/:id",
    DOWNLOAD_REPORT:"/downLoad-report/:id",
    MANUAL_REPORT:"/manual-report",
    MANUAL_RESULT:"/manual-result",
    TOPIC:"/topic/:id",
    TOPIC_DETAIL:"/topic-detail/:chapterId/:topicId",
    CHAT:"/chat",
    EDIT_PROFILE:"/edit-profile",
    CHANGE_PASSWORD:"/change-password",
    RESULT:"/result/:paperId",
    NOTIFICATION:"/notification",
    CONGRATULATION:"/Congratulation",
    //  Sample new topic
        
    NEW_TOPIC_DETAILS:'/sample-topic-details',

    // Home Work list 
    HOME_WORK_LIST :'/home-work-list/:id',
    HOME_WORK_QUESTION : '/home-work-questions/:id',
    HOME_WORK_RESULT : '/home-work-result/:paperId',
    HOME_WORK_SUBJECT : '/home-work-subject'
}

export const ACCESS_TOKEN = 'access_token';
export const CLASS_ID = 'classId';
export const USER_NAME = 'firstName';
export const USER_ID = 'id';


// export const SERVER_PATH="https://uat-lpi-tuition.projectstatus.in";
// export const API_ENDPOINT = "https://uat-lpi-tuition.projectstatus.in/api/v1";

// export const SERVER_PATH="http://192.168.7.149:3007/";
// export const API_ENDPOINT = "http://192.168.7.149:3007/api/v1";

export const SERVER_PATH="https://api.lpi-tuition.com"; 
export const API_ENDPOINT = "https://api.lpi-tuition.com/api/v1";

export const WIRIS_PATH = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
